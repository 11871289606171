import { Avatar } from "@mui/material";
import { useState } from "react";
import { Button, Form, FormFloating } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import LobbyForgotPasswordModal from "./LobbyForgotPasswordModal";

const LoginForm: React.FC<{ isMobile?: boolean; imgSrc?: string }> = ({
  isMobile,
  imgSrc,
}) => {
  const [showModal, setShowModal] = useState(false);
  const intl = useIntl();

  const [hasError, setHasError] = useState(false);
  const { handleSubmit, register, resetField } = useFormContext<{
    username: string;
    password: string;
  }>();

  const login = async (
    data: { username: string; password: string },
    e?: React.BaseSyntheticEvent
  ) => {
    const params = new URLSearchParams(data);
    const response = await fetch("/do-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    });
    if (response.redirected && response.url.includes("/login?error=true")) {
      e?.preventDefault();
      setHasError(true);
      resetField("password");
    } else {
      window.location.replace(response.url);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(login)}>
        {isMobile && (
          <Avatar
            variant="circular"
            className="m-auto mb-4 Login-Logo"
            src={imgSrc}
            sx={{
              height: "18vh",
              width: "18vh",
            }}
          />
        )}
        {hasError && (
          <span className="text-danger fst-italic">
            <FormattedMessage
              id="login.error"
              defaultMessage="The username or password were incorrect!"
            />
          </span>
        )}
        <Form.Group controlId="username" className="mb-3">
          <FormFloating>
            <Form.Control
              id="customFloatingInput"
              className="Login-Input"
              type="input"
              placeholder={intl.formatMessage({
                id: "login.username",
                defaultMessage: "Username",
              })}
              required
              {...register("username")}
            />
            <label htmlFor="customFloatingInput" className="text-center">
              <FormattedMessage
                id="login.usernameOrEmail"
                defaultMessage="Username or Email"
              />
            </label>
          </FormFloating>
        </Form.Group>
        <Form.Group controlId="password" className="mb-3">
          <FormFloating>
            <Form.Control
              className="Login-Input"
              type="password"
              placeholder={intl.formatMessage({
                id: "login.password",
                defaultMessage: "Password",
              })}
              required
              {...register("password")}
            />
            <label htmlFor="customFloatingInput" className="text-center">
              <FormattedMessage id="login.password" defaultMessage="Password" />
            </label>
          </FormFloating>
        </Form.Group>
        <div className="mt-2 mb-5 text-center">
          <div onClick={() => setShowModal(true)} className="Forgot-Password">
            <FormattedMessage
              id="Lobby.forgotPassword"
              defaultMessage="Forgot password?"
            />
          </div>
        </div>
        <Button type="submit" className="Login-Button m-auto">
          <FormattedMessage id="login.button.login" defaultMessage="Login" />
        </Button>
      </Form>
      {showModal && (
        <LobbyForgotPasswordModal
          handleCloseModal={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default LoginForm;
