import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useResourceProblemsByCriteria } from "app/api/ResourceProblemApi";
import { ReactComponent as DownArrow } from "app/icons/v2/arrow_down.svg";
import { ReactComponent as RightArrow } from "app/icons/v2/arrow_right.svg";
import ResourceProblemSearchCriteria from "app/models/ResourceProblemSearchCriteria";
import LoadingSpinner from "components/LoadingSpinner";
import { useState } from "react";
import { Container } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedDate, FormattedMessage } from "react-intl";

const ResourceProblemsPreviewTable = () => {
  const headers = [
    "date",
    "reporter",
    "category",
    "description",
    "office",
    "floor",
    "resource",
    "reportedTo",
  ];

  const { watch, setValue } = useFormContext<ResourceProblemSearchCriteria>();

  const {
    data: resourceProblems,
    isLoading,
    page,
  } = useResourceProblemsByCriteria(watch());

  const [open, setOpen] = useState<Array<number>>([]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setValue("page", newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue("size", parseInt(event.target.value, 10));
    setValue("page", 0);
  };
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : !resourceProblems || resourceProblems.length === 0 ? (
        <h6 style={{ margin: "auto" }}>
          <FormattedMessage
            id="myBookings.noRecordsToDisplay"
            defaultMessage="There are no records to display"
          />
        </h6>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="arrow" />
              {headers.map((header, index) => (
                <TableCell key={index}>
                  <FormattedMessage
                    id={`resourceProblems.tableHeaders.${header}`}
                    defaultMessage={header}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceProblems?.map((resourceProblem, index) => (
              <>
                <TableRow
                  key={index}
                  hover
                  onClick={() =>
                    setOpen(
                      open.includes(index)
                        ? open.filter((item) => item !== index)
                        : [...open, index]
                    )
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    data-id="arrow"
                    sx={{
                      padding: "0 0 0 1rem",
                    }}
                  >
                    {open.includes(index) ? <DownArrow /> : <RightArrow />}
                  </TableCell>
                  <TableCell>
                    <FormattedDate value={resourceProblem.date} />
                  </TableCell>
                  <TableCell>{resourceProblem.user.fullName}</TableCell>
                  <TableCell>{resourceProblem.category.name}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        width: "5rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {resourceProblem.description}
                    </div>
                  </TableCell>
                  <TableCell>{resourceProblem.office.name}</TableCell>
                  <TableCell>{resourceProblem.floor.name}</TableCell>
                  <TableCell>{resourceProblem.resource.name}</TableCell>
                  <TableCell>{resourceProblem.reportedTo}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={headers.length + 1}
                  >
                    <Collapse
                      in={open.includes(index)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          <FormattedMessage
                            id="resourceProblems.tableHeaders.description"
                            defaultMessage="Description"
                          />
                        </Typography>
                        <Container fluid>
                          {resourceProblem.description}
                        </Container>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ "& > * > p ": { margin: "unset" } }}
                count={page?.totalElements || 0}
                page={page?.number || 0}
                onPageChange={handleChangePage}
                rowsPerPage={page?.size || 0}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </>
  );
};

export default ResourceProblemsPreviewTable;
