import { ReactComponent as AdminDevicesIcon } from "app/icons/v2/admin_devices.svg";
import { ReactComponent as AdminGroupsIcon } from "app/icons/v2/admin_groups.svg";
import { ReactComponent as AdminNotificationsIcon } from "app/icons/v2/admin_notifications.svg";
import { ReactComponent as AdminOfficesIcon } from "app/icons/v2/admin_offices.svg";
import { ReactComponent as AdminRolesIcon } from "app/icons/v2/admin_roles.svg";
import { ReactComponent as AdminUsersIcon } from "app/icons/v2/admin_users.svg";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, matchPath, NavLink, useLocation } from "react-router-dom";
import { useBrandingInfo } from "./api/ApplicationBrandingApi";
import useCurrentUser from "./api/UserApi";
import DeskBuddyLogo from "./logo-no-slogan.svg";
import Profile from "./Profile";
import RequiresAccess, { useRequireAccess } from "./RequiresAccess";

import "./Topbar.css";
import useTeams from "./utils/useTeams";

const Topbar = () => {
  const location = useLocation();
  const { data: brandingInfo } = useBrandingInfo();

  const { data: user } = useCurrentUser();
  const hasAccessToBookings = useRequireAccess("/bookings");
  const teams = useTeams();

  if (!user?.runAs && teams.context && !hasAccessToBookings) {
    return null;
  }

  return (
    <Navbar bg="light" expand="sm" className="Topbar">
      <Navbar.Brand to="/my-bookings" as={Link} className="order-1">
        <img
          src={brandingInfo?._links.appLogo?.href || DeskBuddyLogo}
          className="Topbar-Logo"
          alt="Desk Buddy logo"
        />
      </Navbar.Brand>
      <RequiresAccess path={["/admin", "/bookings", "reports"]}>
        <Navbar.Toggle
          aria-controls="topbar-navbar-collapse"
          className="order-2"
        />
      </RequiresAccess>
      <Navbar.Collapse
        id="topbar-navbar-collapse"
        className="order-4 order-sm-3"
      >
        <Nav className="me-auto">
          <RequiresAccess path="/my-bookings">
            <Nav.Link to="/my-bookings" as={NavLink} end>
              <FormattedMessage
                id="topbar.admin.myBookings"
                defaultMessage="My Bookings"
              />
            </Nav.Link>
          </RequiresAccess>
          <RequiresAccess path="/bookings">
            <Nav.Link to="/bookings" as={NavLink}>
              <FormattedMessage
                id="topbar.admin.bookings"
                defaultMessage="Bookings"
              />
            </Nav.Link>
          </RequiresAccess>
          <RequiresAccess
            path={[
              "/admin/offices",
              "/admin/groups",
              "/admin/devices",
              "/admin/users",
              "/admin/roles",
              "/admin/notifications",
            ]}
          >
            <NavDropdown
              id="admin-menu"
              title={
                <FormattedMessage
                  id="topbar.menu.administration"
                  defaultMessage="Administration"
                />
              }
              active={!!matchPath(location.pathname, "/admin")}
            >
              <RequiresAccess path="/admin/offices">
                <NavDropdown.Item to="/admin/offices" as={Link}>
                  <AdminOfficesIcon />
                  <FormattedMessage
                    id="generic.offices"
                    defaultMessage="Offices"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              <RequiresAccess path="/admin/devices">
                <NavDropdown.Item to="/admin/devices" as={Link}>
                  <AdminDevicesIcon />
                  <FormattedMessage
                    id="Topbar.adminMenu.deviceManagement"
                    defaultMessage="Devices"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              <RequiresAccess path="/admin/groups">
                <NavDropdown.Item to="/admin/groups" as={Link}>
                  <AdminGroupsIcon />
                  <FormattedMessage
                    id="topbar.admin.groups"
                    defaultMessage="Groups"
                  />
                </NavDropdown.Item>
              </RequiresAccess>

              <RequiresAccess path="/admin/users">
                <NavDropdown.Item to="/admin/users" as={Link}>
                  <AdminUsersIcon />
                  <FormattedMessage
                    id="topbar.admin.users"
                    defaultMessage="Users"
                  />
                </NavDropdown.Item>
              </RequiresAccess>

              <RequiresAccess path="/admin/roles">
                <NavDropdown.Item to="/admin/roles" as={Link}>
                  <AdminRolesIcon />
                  <FormattedMessage
                    id="topbar.admin.roles"
                    defaultMessage="Roles"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              <RequiresAccess path="/admin/notifications">
                <NavDropdown.Item to="/admin/notifications" as={Link}>
                  <AdminNotificationsIcon />
                  <FormattedMessage
                    id="topbar.admin.notifications"
                    defaultMessage="Notifications"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              {/* <RequiresPermissions
                permissions={[
                  `${BRANDING}:${CREATE}`,
                  `${BRANDING}:${UPDATE}`,
                  `${BRANDING}:${DELETE}`,
                ]}
                logical={Logical.OR}
              >
                <NavDropdown.Item to="/admin/branding" as={Link}>
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faPalette}
                    fixedWidth={true}
                  />
                  <FormattedMessage
                    id="Topbar.adminMenu.branding"
                    defaultMessage="Branding"
                  />
                </NavDropdown.Item>
              </RequiresPermissions> */}
            </NavDropdown>
          </RequiresAccess>

          <RequiresAccess
            path={[
              "/reports/utilization-by-date",
              "/reports/utilization-by-hour",
              "/reports/utilization-by-team",
              "/reports/current-utilization",
              "/reports/audit",
              "/reports/reported-problems",
            ]}
          >
            <NavDropdown
              id="admin-menu"
              title={
                <FormattedMessage
                  id="topbar.reports"
                  defaultMessage="Reports"
                />
              }
              active={!!matchPath(location.pathname, "/reports")}
            >
              <RequiresAccess path="/reports/utilization-by-date">
                <NavDropdown.Item to="/reports/utilization-by-date" as={Link}>
                  <FormattedMessage
                    id="topbar.reports.utilizationByDate"
                    defaultMessage="Utilization by date"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              <RequiresAccess path="/reports/utilization-by-hour">
                <NavDropdown.Item to="/reports/utilization-by-hour" as={Link}>
                  <FormattedMessage
                    id="topbar.reports.utilizaitonByHour"
                    defaultMessage="Utilization by hour"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              <RequiresAccess path="/reports/utilization-by-team">
                <NavDropdown.Item to="/reports/utilization-by-team" as={Link}>
                  <FormattedMessage
                    id="topbar.reports.utilizationByTeam"
                    defaultMessage="Utilization by team"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              <RequiresAccess path="/reports/current-utilization">
                <NavDropdown.Item to="/reports/current-utilization" as={Link}>
                  <FormattedMessage
                    id="topbar.reports.currentUtilization"
                    defaultMessage="Current Utilization"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              <RequiresAccess path="/reports/audit">
                <NavDropdown.Item to="/reports/audit" as={Link}>
                  <FormattedMessage
                    id="topbar.reports.audit"
                    defaultMessage="Audit"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
              <RequiresAccess path="/reports/reported-problems">
                <NavDropdown.Item to="/reports/reported-problems" as={Link}>
                  <FormattedMessage
                    id="topbar.reports.reportedProblems"
                    defaultMessage="Reported problems"
                  />
                </NavDropdown.Item>
              </RequiresAccess>
            </NavDropdown>
          </RequiresAccess>
        </Nav>
      </Navbar.Collapse>
      <Nav className="Profile-Nav order-3 order-sm-4">
        <Profile />
      </Nav>
    </Navbar>
  );
};

export default Topbar;
