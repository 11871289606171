import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useReports } from "app/api/ReportApi";
import { convertFilters, ReportFilters } from "app/models/ReportFilters";
import ReportModel from "app/models/ReportModel";
import User from "app/models/User";
import { makeCsv } from "app/utils/makeCsv";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import "./TeamPreviewYearlyTable.css";

const TeamPreviewYearlyTable: React.FC<{
  users?: Array<User>;
  page: number;
  setPage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  rowsPerPage: number;
  setRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  total: number;
}> = ({ users, page, setPage, rowsPerPage, setRowsPerPage, total }) => {
  const NOT_AVAILABLE = "-";
  const { watch } = useFormContext<ReportFilters>();
  const csvFilter = convertFilters(watch());
  const { data } = useReports(csvFilter);
  const intl = useIntl();
  const startDateYear = watch("period.from").getFullYear();

  const headers = useMemo(
    () =>
      Array.from({ length: 12 }).map((_, index) =>
        new Intl.DateTimeFormat(intl.locale, {
          month: intl.locale !== "bg" ? "short" : "long",
        }).format(new Date(2000, index, 1))
      ),
    [intl]
  );

  const isRecordValid = (
    record: ReportModel,
    userEmail: string,
    monthIndex: number
  ) =>
    record.Email === userEmail &&
    record.Checked === "Yes" &&
    moment(record.Date).month() === monthIndex;

  const isPrevRecordDiff = useCallback(
    (currentIndex: number) => {
      if (currentIndex === 0) {
        return true;
      }

      const currentRecord = data?.[currentIndex];
      const prevRecord = data?.[currentIndex - 1];

      return (
        !moment(currentRecord.Date).isSame(prevRecord.Date) ||
        currentRecord.Email !== prevRecord.Email ||
        prevRecord.Checked === "No" ||
        prevRecord.Cancelled === "Yes"
      );
    },
    [data]
  );

  const pageBody = useMemo(
    () =>
      users
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((user) => [
          user.fullName,
          ...headers.map(
            (month, index) =>
              data &&
              (moment()
                .year(startDateYear)
                .month(month)
                .endOf("month")
                .isSameOrAfter(user.createdAt)
                ? data?.filter(
                    (record, recordIndex) =>
                      isRecordValid(record, user.email, index) &&
                      isPrevRecordDiff(recordIndex)
                  ).length
                : NOT_AVAILABLE)
          ),
        ]),
    [data, headers, isPrevRecordDiff, page, rowsPerPage, startDateYear, users]
  );

  const getTeamBody = () =>
    users?.map((user) => [
      user.fullName,
      ...headers.map(
        (month, index) =>
          data &&
          (moment()
            .year(startDateYear)
            .month(month)
            .endOf("month")
            .isSameOrAfter(user.createdAt)
            ? data?.filter(
                (record, recordIndex) =>
                  isRecordValid(record, user.email, index) &&
                  isPrevRecordDiff(recordIndex)
              ).length
            : NOT_AVAILABLE)
      ),
    ]) || [];

  return (
    <>
      {users && users.length > 0 ? (
        <TableContainer style={{ height: "100%" }}>
          <Table className="TeamYearlyTable">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pageBody?.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row?.map((column, colIndex) => (
                    <TableCell
                      key={colIndex}
                      className={
                        typeof column === "number" && column !== 0
                          ? "Values-Indicator"
                          : undefined
                      }
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {pageBody && (
                  <TableCell>
                    <DropdownButton
                      title={intl.formatMessage({
                        id: "reportFilters.button.exportCSV",
                        defaultMessage: "Export to CSV",
                      })}
                    >
                      <Dropdown.Item
                        onClick={() =>
                          makeCsv(
                            ["", ...headers],
                            pageBody,
                            `${intl.formatMessage({
                              id: "reports.utilizationByTeam.yearlyTeamReport",
                              defaultMessage: "Yearly team report",
                            })} - ${new Date().toLocaleDateString()}`
                          )
                        }
                      >
                        <FormattedMessage
                          id="reports.utilizationByTeam.thisPage"
                          defaultMessage="This page"
                        />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          makeCsv(
                            ["", ...headers],
                            getTeamBody(),
                            `${intl.formatMessage({
                              id: "reports.utilizationByTeam.yearlyTeamReport",
                              defaultMessage: "Yearly team report",
                            })} - ${new Date().toLocaleDateString()}`
                          )
                        }
                      >
                        <FormattedMessage
                          id="reports.utilizationByTeam.theWholeTeam"
                          defaultMessage="The whole team"
                        />
                      </Dropdown.Item>
                    </DropdownButton>
                  </TableCell>
                )}
                <TablePagination
                  sx={{ "& > * > p ": { margin: "unset" } }}
                  count={total}
                  page={page}
                  onPageChange={setPage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={setRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <p style={{ margin: "auto" }}>
          <FormattedMessage
            id="report.teamTable.noUsersToDisplay"
            defaultMessage="There are no users to display"
          />
        </p>
      )}
    </>
  );
};
export default TeamPreviewYearlyTable;
