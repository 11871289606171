import BaseCollecionLinks from "app/models/BaseCollectionLinks";
import CreateReservation from "app/models/CreateReservation";
import ExpectedStatus from "app/models/ExpectedStatus";
import LocationInfo from "app/models/LocationInfo";
import PagedData from "app/models/PagedData";
import Reservation from "app/models/Reservation";
import ReservationSearchCriteria from "app/models/ReservationSearchCriteria";
import fetchClient, { replacer, stringifyUrl } from "app/utils/fetch-client";
import { laggy } from "app/utils/laggy";
import { usePagedData } from "app/utils/PagedDataUtils";
import { apiFetchWithReviver } from "AppConfig";
import { CollectionModel, EntityModel } from "hateoas-hal-types";
import useSwr, { SWRConfiguration } from "swr";
import useCurrentUser, { reloadCheckins } from "./UserApi";

export const reservationsBasePath = "/api/v1/reservations";

export function reloadReservations(
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return mutate(new RegExp(reservationsBasePath));
}

export function useReservationsManagedByCurrentUser() {
  const { data: currentUser } = useCurrentUser();
  return usePagedReservationsByCriteria({
    operatorId: currentUser?.id,
    cancelled: false,
    checkedOut: false,
    onlyCurrentAndFuture: true,
    size: 5,
    sort: "startTime,asc",
  });
}

export const usePendingPersonalReservations = (
  criteria?: ReservationSearchCriteria
) => {
  const { data: currentUser } = useCurrentUser();

  return usePagedReservationsByCriteria({
    reservedForId: currentUser?.id,
    cancelled: false,
    checkedOut: false,
    size: 5,
    onlyFuture: true,
    sort: "startTime,asc",
    ...criteria,
  });
};

export const useTodayPersonalReservations = (
  criteria?: ReservationSearchCriteria
) => {
  const { data: currentUser } = useCurrentUser();

  return usePagedReservationsByCriteria({
    reservedForId: currentUser?.id,
    cancelled: false,
    size: 5,
    onlyToday: true,
    sort: "startTime,asc",
    ...criteria,
  });
};

export const useCurrentPersonalReservations = () => {
  const { data: currentUser } = useCurrentUser();

  return useReservationsByCriteria({
    onlyCurrent: true,
    reservedForId: currentUser?.id,
    cancelled: false,
    checkedOut: false,
  });
};

export function usePassedPersonalReservations(
  criteria?: ReservationSearchCriteria
) {
  const { data: currentUser } = useCurrentUser();

  return usePagedReservationsByCriteria({
    reservedForId: currentUser?.id,
    cancelled: false,
    size: 5,
    onlyPast: true,
    sort: "startTime,desc",
    ...criteria,
  });
}

export const usePagedReservationsByCriteria = (
  criteria: ReservationSearchCriteria
): PagedData<Reservation> => {
  return usePagedData(
    (c, pageIndex) =>
      buildReservationsQueryUriByCriteria({
        page: pageIndex,
        ...c,
      }),
    criteria,
    (c) => c.size,
    "reservations"
  );
};

export function fetchReservationsByCriteria(
  criteria: ReservationSearchCriteria
): Promise<CollectionModel<Reservation>> {
  return fetchClient.get(buildReservationsQueryUriByCriteria(criteria));
}

function buildReservationsQueryUriByCriteria(
  criteria: ReservationSearchCriteria
) {
  return stringifyUrl(reservationsBasePath, criteria);
}

export function useReservationsByCriteria(
  filter?: ReservationSearchCriteria,
  config?: SWRConfiguration
) {
  const { data, error } = useSwr<CollectionModel<Reservation>>(
    filter ? stringifyUrl(reservationsBasePath, filter) : null,
    apiFetchWithReviver,
    { use: [laggy], ...config }
  );
  const reservations = data?._embedded?.reservations;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return { data: reservations, links: extractedLinks, error };
}

export function useReservationById(reservationId: string | undefined) {
  return useSwr<EntityModel<Reservation>>(
    reservationId ? `${reservationsBasePath}/${reservationId}` : null,
    apiFetchWithReviver
  );
}

export function changeToValidCheckinStatus(
  reservation: EntityModel<Reservation>,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return fetchClient
    .patch(reservation._links.validStatusChange.href, {
      body: JSON.stringify(ExpectedStatus.VALID),
    })
    .then(() => reloadReservations(mutate));
}

export async function checkinReservation(
  reservation: EntityModel<Reservation>,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>,
  locationInfo?: LocationInfo
): Promise<Reservation> {
  const link = reservation._links.checkIn || reservation._links.redoCheckIn;
  const result = await fetchClient.patch(link.href, {
    body: JSON.stringify(locationInfo),
  });
  reloadReservations(mutate);
  reloadCheckins(mutate);
  return result;
}

export function checkoutReservation(
  reservation: EntityModel<Reservation>,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return fetchClient
    .patch(reservation._links.checkout.href)
    .then(() => reloadReservations(mutate));
}

export function cancelReservation(
  reservation: EntityModel<Reservation>,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return fetchClient
    .patch(reservation._links.cancel.href)
    .then(() => reloadReservations(mutate));
}

export function cancelSeriesOfReservation(
  reservation: EntityModel<Reservation>,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return fetchClient
    .patch(reservation._links.seriesCancel.href)
    .then(() => reloadReservations(mutate));
}

export const createReservation = (data: CreateReservation): Promise<any> => {
  return fetchClient.post(reservationsBasePath, {
    body: JSON.stringify(data, replacer),
  });
};
