import { createNotification } from "app/api/NotificationApi";
import CreateNotification from "app/models/CreateNotification";
import UpdateNotification from "app/models/UpdateNotification";
import useMatchMutate from "app/utils/useMatchMutate";
import AddNewCard from "components/AddNewCard";
import { useState } from "react";
import { Col } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import AddNotificationModal from "./CreateEditNotificationModal";

const AddNotificationCard = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const mutate = useMatchMutate();
  const intl = useIntl();

  const handleCreate = async (
    requestBody: CreateNotification | UpdateNotification
  ) => {
    await createNotification(requestBody, mutate)
      .then(() =>
        toast.success(
          intl.formatMessage({
            id: "notification.create.success",
            defaultMessage: "Notification created successfully",
          })
        )
      )
      .catch((e) => {
        console.log(e);
        toast.error(
          intl.formatMessage({
            id: "notification.create.error",
            defaultMessage: "Error occured during creating notification",
          })
        );
      })
      .finally(() => setShowRegisterModal(false));
  };

  return (
    <Col className="mb-4">
      <AddNewCard handleClick={() => setShowRegisterModal(true)}>
        <FormattedMessage
          id="notification.addNew"
          defaultMessage="Add new notification"
        />
      </AddNewCard>
      {showRegisterModal && (
        <AddNotificationModal
          handleSubmit={handleCreate}
          handleCloseModal={() => setShowRegisterModal(false)}
        />
      )}
    </Col>
  );
};

export default AddNotificationCard;
