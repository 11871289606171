import { IntlShape } from "react-intl";

type ValidationDetails = {
  validation: string;
  argument: number;
  message: string;
};

type ValidationResult = {
  validationPassed: boolean;
  details: ValidationDetails[];
};

const passwordValidator = require("password-validator");

function createPasswordValidator(intl: IntlShape) {
  return new passwordValidator()
    .is()
    .min(
      8,
      intl.formatMessage({
        id: "passValidator.minCharsMsg",
        defaultMessage: "Minimum 8 characters",
      })
    )
    .is()
    .max(
      20,
      intl.formatMessage({
        id: "passValidator.maxCharsMsg",
        defaultMessage: "Maximum 20 characters",
      })
    )
    .has()
    .uppercase(
      1,
      intl.formatMessage({
        id: "passValidator.upperCaseLetterMsg",
        defaultMessage: "Uppercase letter missing",
      })
    )
    .has()
    .lowercase(
      1,
      intl.formatMessage({
        id: "passValidator.lowerCaseLetterMsg",
        defaultMessage: "Lowercase letter missing",
      })
    )
    .has()
    .digits(
      1,
      intl.formatMessage({
        id: "passValidator.digitMsg",
        defaultMessage: "Digit missing",
      })
    )
    .symbols(
      1,
      intl.formatMessage({
        id: "passValidator.symbolMsg",
        defaultMessage: "Special symbol missing",
      })
    )
    .has()
    .not()
    .spaces();
}

export function validatePass(pass: string, intl: IntlShape): ValidationResult {
  const validationErrors = createPasswordValidator(intl).validate(pass, {
    details: true,
  });
  return {
    validationPassed: validationErrors.length === 0,
    details: validationErrors,
  };
}
