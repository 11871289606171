import ReservationPeriod from "app/models/ReservationPeriod";
import { CustomDatePickerInput } from "app/utils/CustomDatePickerInput";
import useMediaQuery from "app/utils/useMediaQuery";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormattedMessage, useIntl } from "react-intl";

const ReportTimeToggle: React.FC<{
  period?: { to?: Date; from?: Date };
  onChange: (period: { to?: Date; from?: Date }) => void;
  labels: Array<ReservationPeriod>;
  isSingleDay?: boolean;
  isSingleMonth?: boolean;
  isSingleYear?: boolean;
  isCurrentAndFuture?: boolean;
}> = ({
  period = {
    to: moment(new Date()).startOf("day").toDate(),
    from: moment(new Date()).startOf("day").toDate(),
  },
  onChange,
  labels,
  isSingleDay,
  isSingleMonth,
  isSingleYear,
  isCurrentAndFuture,
}) => {
  const [value, setValue] = useState<ReservationPeriod>(labels[0]);
  const [lastLabelFirstIndex, setLastLabelFirstIndex] =
    useState<ReservationPeriod>(labels[0]);
  const intl = useIntl();
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (lastLabelFirstIndex !== labels[0]) {
      const newValue = labels[0];

      if (newValue === ReservationPeriod.THIS_MONTH) {
        onChange({
          from: moment(new Date()).startOf("month").toDate(),
          to: moment(new Date()).endOf("month").toDate(),
        });
      }

      if (newValue === ReservationPeriod.THIS_YEAR) {
        onChange({
          from: moment(new Date()).startOf("year").toDate(),
          to: moment(new Date()).endOf("year").toDate(),
        });
      }

      setValue(newValue);
      setLastLabelFirstIndex(newValue);
    }
  }, [labels, lastLabelFirstIndex, onChange]);

  return (
    <div>
      <ToggleButtonGroup type="radio" name="selectDate" value={value}>
        {labels.includes(ReservationPeriod.ALL) && (
          <ToggleButton
            id="all-time"
            variant="outline-primary"
            value={ReservationPeriod.ALL}
            onChange={() => {
              setValue(ReservationPeriod.ALL);
              onChange({
                ...period,
                to: undefined,
                from: undefined,
              });
            }}
          >
            <FormattedMessage
              id="reservationForm.label.allTime"
              defaultMessage="All time"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.TODAY) && (
          <ToggleButton
            id="today"
            variant="outline-primary"
            value={ReservationPeriod.TODAY}
            onChange={() => {
              setValue(ReservationPeriod.TODAY);
              onChange({
                ...period,
                to: isCurrentAndFuture
                  ? moment(new Date()).endOf("day").toDate()
                  : moment(new Date()).startOf("day").toDate(),
                from: moment(new Date()).startOf("day").toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reservationForm.label.today"
              defaultMessage="Today"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.YESTERDAY) && (
          <ToggleButton
            id="yesterday"
            variant="outline-primary"
            value={ReservationPeriod.YESTERDAY}
            onChange={() => {
              setValue(ReservationPeriod.YESTERDAY);
              onChange({
                ...period,
                from: moment(new Date())
                  .startOf("day")
                  .subtract(1, "day")
                  .toDate(),
                to: isCurrentAndFuture
                  ? moment(new Date()).endOf("day").subtract(1, "day").toDate()
                  : moment(new Date())
                      .startOf("day")
                      .subtract(1, "day")
                      .toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reservationForm.label.yesterday"
              defaultMessage="Yesterday"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.TOMORROW) && (
          <ToggleButton
            id="tomorrow"
            variant="outline-primary"
            value={ReservationPeriod.TOMORROW}
            onChange={() => {
              setValue(ReservationPeriod.TOMORROW);
              onChange({
                ...period,
                to: isCurrentAndFuture
                  ? moment(new Date()).endOf("day").add(1, "day").toDate()
                  : moment(new Date()).startOf("day").add(1, "day").toDate(),
                from: moment(new Date()).startOf("day").add(1, "day").toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reservationForm.label.tomorrow"
              defaultMessage="Tomorrow"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.ONE_MONTH) && (
          <ToggleButton
            id="month"
            variant="outline-primary"
            value={ReservationPeriod.ONE_MONTH}
            onChange={() => {
              setValue(ReservationPeriod.ONE_MONTH);
              onChange({
                ...period,
                to: moment(new Date()).startOf("day").toDate(),
                from: isCurrentAndFuture
                  ? moment(new Date()).startOf("day").add(1, "month").toDate()
                  : moment(new Date())
                      .startOf("day")
                      .subtract(1, "month")
                      .toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reservationForm.label.one.month"
              defaultMessage="Month"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.THREE_MONTHS) && (
          <ToggleButton
            id="threeMonth"
            variant="outline-primary"
            value={ReservationPeriod.THREE_MONTHS}
            onChange={() => {
              setValue(ReservationPeriod.THREE_MONTHS);
              onChange({
                ...period,
                to: moment(new Date()).startOf("day").toDate(),
                from: isCurrentAndFuture
                  ? moment(new Date()).startOf("day").add(3, "month").toDate()
                  : moment(new Date())
                      .startOf("day")
                      .subtract(3, "month")
                      .toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reportFilters.label.three.months"
              defaultMessage="3 Months"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.THIS_MONTH) && (
          <ToggleButton
            id="thisMonth"
            variant="outline-primary"
            value={ReservationPeriod.THIS_MONTH}
            onChange={() => {
              setValue(ReservationPeriod.THIS_MONTH);
              onChange({
                from: moment(new Date()).startOf("month").toDate(),
                to: moment(new Date()).endOf("month").toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reportFilters.label.this.month"
              defaultMessage="This month"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.LAST_MONTH) && (
          <ToggleButton
            id="lastMonth"
            variant="outline-primary"
            value={ReservationPeriod.LAST_MONTH}
            onChange={() => {
              setValue(ReservationPeriod.LAST_MONTH);
              onChange({
                from: moment(new Date())
                  .subtract(1, "month")
                  .startOf("month")
                  .toDate(),
                to: moment(new Date())
                  .subtract(1, "month")
                  .endOf("month")
                  .toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reportFilters.label.last.month"
              defaultMessage="Last month"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.THIS_YEAR) && (
          <ToggleButton
            id="thisYear"
            variant={"outline-primary"}
            value={ReservationPeriod.THIS_YEAR}
            onChange={() => {
              setValue(ReservationPeriod.THIS_YEAR);
              onChange({
                from: moment(new Date()).startOf("year").toDate(),
                to: moment(new Date()).endOf("year").toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reportFilters.label.this.year"
              defaultMessage="This year"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.LAST_YEAR) && (
          <ToggleButton
            id="lastYear"
            variant="outline-primary"
            value={ReservationPeriod.LAST_YEAR}
            onChange={() => {
              setValue(ReservationPeriod.LAST_YEAR);
              onChange({
                from: moment(new Date())
                  .startOf("year")
                  .subtract(1, "year")
                  .toDate(),
                to: moment(new Date())
                  .endOf("year")
                  .subtract(1, "year")
                  .toDate(),
              });
            }}
          >
            <FormattedMessage
              id="reportFilters.label.last.year"
              defaultMessage="Last year"
            />
          </ToggleButton>
        )}
        {labels.includes(ReservationPeriod.SELECT) && (
          <ToggleButton
            id="select"
            variant="outline-primary"
            value={ReservationPeriod.SELECT}
            onChange={() => setValue(ReservationPeriod.SELECT)}
          >
            <FormattedMessage
              id="reportFilters.label.select"
              defaultMessage="Select"
            />
          </ToggleButton>
        )}
      </ToggleButtonGroup>
      {value === ReservationPeriod.SELECT && (
        <>
          {isSingleYear ? (
            <Row>
              <FormattedMessage
                id="reportFilters.label.enterYear"
                defaultMessage="Select a year"
              />
              <DatePicker
                className="form-control"
                dateFormat="yyyy"
                locale={intl.locale}
                showYearPicker
                selected={period.from}
                onChange={(input) =>
                  onChange({
                    from: input || moment(input).startOf("year").toDate(),
                    to: moment(input).endOf("year").toDate(),
                  })
                }
              />
            </Row>
          ) : isSingleMonth ? (
            <Row>
              <FormattedMessage
                id="reportFilters.label.enterMonth"
                defaultMessage="Select a month"
              />
              <DatePicker
                className="form-control"
                dateFormat="MMMM yyyy"
                locale={intl.locale}
                showMonthYearPicker
                selected={period.from}
                onChange={(input) =>
                  onChange({
                    from: input || moment(input).startOf("month").toDate(),
                    to: moment(input).endOf("month").toDate(),
                  })
                }
              />
            </Row>
          ) : (
            <Row>
              <Col>
                {isSingleDay ? (
                  <FormattedMessage
                    id="reportFilters.label.enterDate"
                    defaultMessage="Date"
                  />
                ) : (
                  <FormattedMessage
                    id="reportFilters.label.enterStartDate"
                    defaultMessage="Start date"
                  />
                )}
                <DatePicker
                  selected={period?.from || new Date()}
                  className="form-control"
                  locale={intl.locale}
                  dateFormat="P"
                  onChange={(input) => {
                    input
                      ? isSingleDay
                        ? onChange({
                            ...period,
                            from: moment(input).startOf("day").toDate(),
                            to: isCurrentAndFuture
                              ? moment(input).endOf("day").toDate()
                              : moment(input).startOf("day").toDate(),
                          })
                        : onChange({
                            to: period?.to,
                            from: moment(input).startOf("day").toDate(),
                          })
                      : onChange({
                          to: period?.to,
                          from: period?.from,
                        });
                  }}
                  withPortal={isSmallScreen}
                  customInput={<CustomDatePickerInput />}
                />
              </Col>
              {!isSingleDay && (
                <Col>
                  <FormattedMessage
                    id="reportFilters.label.enterEndDate"
                    defaultMessage="End Date"
                  />
                  <DatePicker
                    selected={period?.to || new Date()}
                    className="form-control"
                    minDate={period.from}
                    locale={intl.locale}
                    dateFormat="P"
                    onChange={(input) => {
                      input
                        ? onChange({
                            from: period?.from,
                            to: isCurrentAndFuture
                              ? moment(input).endOf("day").toDate()
                              : moment(input).startOf("day").toDate(),
                          })
                        : onChange({
                            to: period?.to,
                            from: period?.from,
                          });
                    }}
                    withPortal={isSmallScreen}
                    customInput={<CustomDatePickerInput />}
                  />
                </Col>
              )}
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default ReportTimeToggle;
