import HeaderAndContent from "app/HeaderAndContent";
import { Breadcrumb } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ResourceProblems from "./ResourceProblems";

const ResourceProblemManagement = () => {
  return (
    <HeaderAndContent>
      <HeaderAndContent.Header>
        <Breadcrumb>
          <Breadcrumb.Item>
            <FormattedMessage
              id="breadcrumb.reports"
              defaultMessage="Reports"
            />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage
              id="breadcrumb.reportedProblems"
              defaultMessage="Reported problems"
            />
          </Breadcrumb.Item>
        </Breadcrumb>
      </HeaderAndContent.Header>
      <HeaderAndContent.Content>
        <ResourceProblems />
      </HeaderAndContent.Content>
    </HeaderAndContent>
  );
};

export default ResourceProblemManagement;
