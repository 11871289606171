import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import languages from "./compiled-lang";

import useMatchMutate from "app/utils/useMatchMutate";
import useTeams from "app/utils/useTeams";
import bg from "date-fns/locale/bg";
import de from "date-fns/locale/de";
import enGB from "date-fns/locale/en-GB";
import enUS from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";
import { MessageFormatElement } from "react-intl";

registerLocale("fr", fr);
registerLocale("bg", bg);
registerLocale("en-GB", enGB);
registerLocale("en-US", enUS);
registerLocale("de", de);

export const getDateFnsLocale = (locale: string) => {
  if (locale === "fr") {
    return fr;
  }
  if (locale === "bg") {
    return bg;
  }
  if (locale === "de") {
    return de;
  }
  if (locale === "en-US") {
    return enUS;
  }
  return enGB;
};

const STORAGE_KEY_FORCED_LANG = "forcedLanguage";

const supportedLanguages = Object.keys(languages);

function getPreferedSupportedLanguage() {
  var prefferedLanguages = navigator.languages.map(
    (lang) => lang.split(/[-_]/)[0]
  );

  return (
    prefferedLanguages.find((lang) =>
      supportedLanguages.forEach((suppLang) => suppLang.includes(lang))
    ) || supportedLanguages[0]
  );
}

const prefferedSupportedLanguage = getPreferedSupportedLanguage();
const forcedLanguage = localStorage.getItem(STORAGE_KEY_FORCED_LANG);
let chosenLanguage = (forcedLanguage ||
  prefferedSupportedLanguage) as SupportedLocale;

export type SupportedLocale = "en-GB" | "en-US" | "fr" | "bg" | "de";

export type LocaleContextType = {
  locale: SupportedLocale;
  changeLocale: (value: SupportedLocale) => void;
  supportedLocales: Record<string, { label: string }>;
  messages: Record<string, string> | Record<string, MessageFormatElement[]>;
};

export function getCurrentLocale() {
  return chosenLanguage;
}

const LocaleContext = createContext<LocaleContextType>({
  locale: chosenLanguage,
  changeLocale: () => {},
  supportedLocales: {},
  messages: {},
});

const useLocale = () => useContext(LocaleContext);

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { context } = useTeams();
  const [locale, setLocale] = useState<SupportedLocale>(
    (context?.locale as SupportedLocale) || chosenLanguage
  );
  const matchMutate = useMatchMutate();
  const changeLocale = useCallback(
    (lang: SupportedLocale) => {
      if (!supportedLanguages.includes(lang)) {
        console.warn(
          `Try to update locale to ${lang}. Supported locales are ${supportedLanguages}`
        );
        return;
      }
      localStorage.setItem(STORAGE_KEY_FORCED_LANG, lang);
      chosenLanguage = lang;
      setLocale(lang);
      matchMutate(new RegExp("/"));
    },
    [matchMutate]
  );

  useEffect(() => {
    context?.locale &&
      !localStorage.getItem(STORAGE_KEY_FORCED_LANG) &&
      changeLocale(
        supportedLanguages.find((suppLang) =>
          suppLang.includes(context?.locale.split(/[-_]/)[1])
        ) as SupportedLocale
      );
  }, [changeLocale, context?.locale]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
        changeLocale,
        supportedLocales: languages,
        messages: languages[locale].messages,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export default useLocale;
