import { HalLink } from "hateoas-hal-types";
import Group from "./Group";
import Role from "./Role";

interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  createdAt: Date;
  officeId: string;
  roles: Role[];
  runAs: boolean;
  internal: boolean;
  external: boolean;
  active: boolean;
  operatedGroups: Group[];
  joinedGroups: Group[];
  _links: {
    avatar: HalLink;
    self: HalLink;
    update: HalLink;
    active: HalLink;
  };
}

export default User;

export const guestOption: User = {
  id: "guest",
  username: "",
  firstName: "",
  lastName: "",
  fullName: "",
  email: "",
  createdAt: new Date(),
  officeId: "",
  roles: [],
  runAs: false,
  internal: true,
  external: false,
  active: true,
  operatedGroups: [],
  joinedGroups: [],
  _links: {
    avatar: { href: "" },
    self: { href: "" },
    update: { href: "" },
    active: { href: "" },
  },
};
