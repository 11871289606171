const DESK_BUDDY_DEVICE_TOKEN = "desk-buddy-device-token";

export const saveDeviceRegistration = (
  deviceToken: string,
  resourceId: string
) => {
  const deviceRegistration = {
    deviceToken,
    resourceId,
  };

  localStorage.setItem(
    DESK_BUDDY_DEVICE_TOKEN,
    JSON.stringify(deviceRegistration)
  );
};

export const fetchDeviceRegistration = () => {
  const rawToken = localStorage.getItem(DESK_BUDDY_DEVICE_TOKEN);

  if (rawToken === null) {
    return null;
  }

  return JSON.parse(rawToken);
};

export const hasDeviceRegistration = () => {
  return localStorage.getItem(DESK_BUDDY_DEVICE_TOKEN) !== null;
};

export const removeDeviceRegistration = () => {
  localStorage.removeItem(DESK_BUDDY_DEVICE_TOKEN);
};
