import { useOfficesByCriteria } from "app/api/OfficeApi";
import CreateNotification from "app/models/CreateNotification";
import NotificationModel from "app/models/Notification";
import NotificationLevel from "app/models/NotificationLevel";
import UpdateNotification from "app/models/UpdateNotification";
import CustomDateTimePickerInput from "components/CustomDateTimePicker";
import SpinnerButton from "components/SpinnerButton";
import { EntityModel } from "hateoas-hal-types";
import moment from "moment";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import Select from "react-select";
import "./CreateEditNotificationModal.css";

const levels = [NotificationLevel.INFO, NotificationLevel.WARNING];

const CreateEditNotificationModal: React.FC<{
  notification?: EntityModel<NotificationModel> | CreateNotification;
  handleSubmit: (
    requestBody: CreateNotification | UpdateNotification
  ) => Promise<void>;
  handleCloseModal: () => void;
}> = ({ notification, handleSubmit, handleCloseModal }) => {
  const MAX_DESCRIPTION_LENGTH = 500;
  const intl = useIntl();

  const methods = useForm<CreateNotification>({
    defaultValues: {
      ...notification,
      level: notification?.level || levels[0],
      officeIds:
        notification && "offices" in notification
          ? notification.offices.map((office) => office.id)
          : [],
    },
  });
  const {
    control,
    watch,
    register,
    handleSubmit: formSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const { data: offices } = useOfficesByCriteria({
    active: true,
    onlyOperated: true,
  });

  const startTime = watch("startTime");
  const [notificationTextLength, setNotificationTextLength] = useState(
    notification?.text.length || 0
  );

  return (
    <FormProvider {...methods}>
      <Modal show={true} centered>
        <Form autoComplete="off" onSubmit={formSubmit(handleSubmit)}>
          <Modal.Header>
            <Modal.Title className="NotificationModal-Title">
              {notification ? (
                <FormattedMessage
                  id="notification.modal.update.title"
                  defaultMessage="Modify notification"
                />
              ) : (
                <FormattedMessage
                  id="notification.modal.create.title"
                  defaultMessage="Create notification"
                />
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="NotificationModal-Body">
            <Form.Group className="NotificationCreateEditModal-Name">
              <Form.Label>
                <FormattedMessage
                  id="notification.modal.form.name"
                  defaultMessage="Name"
                />
                <span className="required">*</span>
              </Form.Label>
              <Form.Control
                {...register("name", {
                  required: intl.formatMessage({
                    id: "notification.modal.form.error.required",
                    defaultMessage: "This field is required",
                  }),
                })}
                type="text"
              />
              <Form.Text className="text-muted NotificationForm-Error">
                {errors.name && (
                  <p className="NotificationForm-Error">
                    {errors.name.message}{" "}
                  </p>
                )}
              </Form.Text>
            </Form.Group>

            <Form.Group className="NotificationCreateEditModal-Text">
              <Form.Label>
                <FormattedMessage
                  id="notification.modal.form.text"
                  defaultMessage="Text"
                />
                <span className="required">*</span>
              </Form.Label>
              <Form.Control
                {...register("text", {
                  required: intl.formatMessage({
                    id: "notification.modal.form.error.required",
                    defaultMessage: "This field is required",
                  }),
                })}
                onChange={(element) =>
                  setNotificationTextLength(element.target.value?.length || 0)
                }
                as="textarea"
                rows={4}
                maxLength={MAX_DESCRIPTION_LENGTH}
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Form.Text className="text-muted NotificationForm-Error">
                  {errors.text && (
                    <p className="NotificationForm-Error">
                      {errors.text.message}{" "}
                    </p>
                  )}
                </Form.Text>
                <p className="text-end">{`${notificationTextLength} / ${MAX_DESCRIPTION_LENGTH}`}</p>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <FormattedMessage
                  id="notification.modal.form.level"
                  defaultMessage="Level"
                />
              </Form.Label>
              <Controller
                control={control}
                name="level"
                render={({ field: { value, onChange } }) => (
                  <Select
                    defaultValue={{
                      label: value,
                      value: value,
                    }}
                    options={levels.map((level) => {
                      return { label: level, value: level };
                    })}
                    value={{ label: value, value: value }}
                    onChange={(option) => onChange(option?.value)}
                  />
                )}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <FormattedMessage
                  id="notification.modal.form.startTime"
                  defaultMessage="Start time"
                />
                <span className="required">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="startTime"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    {...register("startTime", {
                      required: intl.formatMessage({
                        id: "notification.modal.form.error.required",
                        defaultMessage: "This field is required",
                      }),
                    })}
                    timeCaption={intl.formatMessage({
                      id: "notification.modal.form.startTime.caption",
                      defaultMessage: "Start time",
                    })}
                    placeholderText={intl.formatMessage({
                      id: "notification.modal.form.selectStartTime",
                      defaultMessage: "Select start time",
                    })}
                    locale={intl.locale}
                    selected={value}
                    onChange={onChange}
                    showTimeSelect
                    timeIntervals={30}
                    dateFormat="P p"
                    timeFormat="p"
                    customInput={<CustomDateTimePickerInput />}
                    minDate={moment().toDate()}
                    minTime={
                      moment(startTime).isSame(new Date(), "day")
                        ? moment().toDate()
                        : moment(startTime).isBefore(new Date())
                        ? moment().endOf("day").toDate()
                        : moment().startOf("day").toDate()
                    }
                    maxTime={moment().endOf("day").toDate()}
                  />
                )}
              />
              <Form.Text className="text-muted NotificationForm-Error">
                {errors.startTime && (
                  <p className="NotificationForm-Error">
                    {errors.startTime.message}{" "}
                  </p>
                )}
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <FormattedMessage
                  id="notification.modal.form.endTime"
                  defaultMessage="End time"
                />
                <span className="required">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="endTime"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    {...register("endTime", {
                      required: intl.formatMessage({
                        id: "notification.modal.form.error.required",
                        defaultMessage: "This field is required",
                      }),
                    })}
                    timeCaption={intl.formatMessage({
                      id: "notification.modal.form.endTime.caption",
                      defaultMessage: "End time",
                    })}
                    placeholderText={intl.formatMessage({
                      id: "notification.modal.form.selectEndTime",
                      defaultMessage: "Select end time",
                    })}
                    locale={intl.locale}
                    selected={value}
                    onChange={onChange}
                    showTimeSelect
                    timeIntervals={30}
                    dateFormat="P p"
                    timeFormat="p"
                    filterDate={(date) =>
                      date && moment(date).isSameOrAfter(startTime, "day")
                    }
                    filterTime={(time) => time && time >= startTime}
                    customInput={<CustomDateTimePickerInput />}
                  />
                )}
              />
              <Form.Text className="text-muted NotificationForm-Error">
                {errors.endTime && (
                  <p className="NotificationForm-Error">
                    {errors.endTime.message}{" "}
                  </p>
                )}
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>
                <FormattedMessage
                  id="notification.modal.form.offices"
                  defaultMessage="Offices"
                />
              </Form.Label>
              <Controller
                control={control}
                name="officeIds"
                render={({ field: { value, onChange } }) => (
                  <Select
                    menuPlacement="auto"
                    placeholder={
                      <FormattedMessage
                        id="notification.modal.form.allOffices"
                        defaultMessage="All offices"
                      />
                    }
                    value={
                      value
                        ? offices?.filter((office) =>
                            value.find((officeId) => office.id === officeId)
                          )
                        : []
                    }
                    options={offices || []}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id + ""}
                    onChange={(options) =>
                      onChange(options.map((option) => option.id))
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    isMulti
                    isClearable
                  />
                )}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {!notification && (
              <Form.Check
                className="float-start"
                id="active"
                type="switch"
                label={intl.formatMessage({
                  id: "notification.modal.form.active",
                  defaultMessage: "Active",
                })}
                {...register("active")}
              />
            )}

            <SpinnerButton type="submit" showSpinner={isSubmitting}>
              <FormattedMessage
                id="notification.modal.form.submit"
                defaultMessage="Submit"
              />
            </SpinnerButton>
            <Button variant="secondary" onClick={handleCloseModal}>
              <FormattedMessage
                id="notification.modal.form.close"
                defaultMessage="Close"
              />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </FormProvider>
  );
};

export default CreateEditNotificationModal;
