import ActuatorInfoResponse from "app/models/ActuatorInfoResponse";
import AuthProvider from "app/models/AuthProvider";
import UiConfiguration from "app/models/UiConfiguration";
import useSwr from "swr";

export function useGlobalConfig() {
  const path = `/api/v1/configurations/ui`;
  const url = `${path}`;
  return useSwr<UiConfiguration>(url);
}

export function useResrvablePointsLimit() {
  return useSwr<number>("/api/v1/configurations/reservable-points-limit");
}

export function usePictureSizeLimit() {
  return useSwr<number>(`/api/v1/configurations/picture/size-limit`);
}

export const useAuthProviders = () =>
  useSwr<Array<AuthProvider>>(`/api/v1/configurations/auth-providers`);

export const useFormattedActuatorInfo = () => {
  const { data: actuatorInfo } = useSwr<ActuatorInfoResponse>(`/actuator/info`);
  return (
    actuatorInfo && {
      version: `Ver.: ${actuatorInfo?.build?.version}`,
      revision: `Rev.: ${actuatorInfo?.git?.commit?.id}`,
    }
  );
};
