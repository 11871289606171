import Branding from "app/models/Branding";
import UpdateBranding from "app/models/UpdateBranding";
import fetchClient from "app/utils/fetch-client";
import { EntityModel } from "hateoas-hal-types";
import useSwr, { mutate } from "swr";

export function useBrandingInfo() {
  return useSwr<EntityModel<Branding>>(`/api/v1/branding`);
}

export function updateApplicationBranding(
  applicationInfo: UpdateBranding
): Promise<void> {
  return fetchClient.put(`/api/v1/branding/update`, {
    body: JSON.stringify(applicationInfo),
  });
}

export function refreshApplicationBranding() {
  return mutate(`/api/v1/branding`);
}
