import BaseCollecionLinks from "app/models/BaseCollectionLinks";
import RefResourceProblemCategory from "app/models/RefResourceProblemCategory";
import ReportResourceProblem from "app/models/ReportResourceProblem";
import ResourceProblem from "app/models/ResourceProblem";
import ResourceProblemSearchCriteria from "app/models/ResourceProblemSearchCriteria";
import fetchClient, { stringifyUrl } from "app/utils/fetch-client";
import { CollectionModel, PagedModel } from "hateoas-hal-types";
import useSWR from "swr";

const basePath = "/api/v1/resources/problems";

export const createResourceProblem = (
  problem: ReportResourceProblem
): Promise<void> => {
  return fetchClient.post(basePath, {
    body: JSON.stringify(problem),
  });
};

export const useResourceProblemsByCriteria = (
  criteria?: ResourceProblemSearchCriteria
) => {
  const { data, error } = useSWR<PagedModel<ResourceProblem>>(
    stringifyUrl(basePath, criteria)
  );
  const resourceProblems = data?._embedded?.resourceProblem;
  const page = data?.page;
  return {
    data: resourceProblems,
    page: page,
    error,
    isLoading: !data && !error,
  };
};

export const useResourceProblemCategories = (officeId?: string) => {
  const { data, error } = useSWR<CollectionModel<RefResourceProblemCategory>>(
    stringifyUrl(`${basePath}/categories`, { officeId: officeId } || "")
  );
  const categories = data?._embedded?.problemCategories;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return {
    data: categories,
    links: extractedLinks,
    error,
    isLoading: !data && !error,
  };
};
