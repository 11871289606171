import CreatePicture from "app/models/CreatePicture";
import UpdatePicture from "app/models/UpdatePicture";
import { fileToBase64 } from "./FileUtil";

export const acceptedImgTypes = ["jpg", "gif", "png", "svg"];

export const loadImage = (path: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.src = path;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (e) => {
      reject(e);
    };
  });
};

export const reloadImages = (url: string) =>
  fetch(url, {
    cache: "reload",
    mode: "no-cors",
  }).then(() => {
    document.body
      .querySelectorAll(`img[src='${url}']`)
      .forEach((img: any) => (img.src = url));
  });

export const getWidth = (url?: string) => {
  const floorPlanParams = new URLSearchParams(
    url?.substring(url?.indexOf("?") + 1)
  );
  const fpWidth = floorPlanParams.has("width")
    ? parseInt(floorPlanParams.get("width")!)
    : undefined;
  return fpWidth;
};

export const getHeight = (url?: string) => {
  const floorPlanParams = new URLSearchParams(
    url?.substring(url?.indexOf("?") + 1)
  );
  const fpHeight = floorPlanParams.has("height")
    ? parseInt(floorPlanParams.get("height")!)
    : undefined;
  return fpHeight;
};

export const imgFileToPictureModel = async (
  imgFile: File
): Promise<CreatePicture | UpdatePicture> => {
  let htmlImg = await loadImage(URL.createObjectURL(imgFile));
  let base64Img = await fileToBase64(imgFile);
  return {
    imageFile: base64Img,
    type: imgFile.type,
    width: htmlImg.width,
    height: htmlImg.height,
  };
};
export function appendPictureHeader(
  picture?: CreatePicture | UpdatePicture
): string {
  return `data:${picture?.type};base64,` + picture?.imageFile;
}
