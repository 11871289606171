import { useOfficesByCriteria } from "app/api/OfficeApi";
import { useResourceProblemCategories } from "app/api/ResourceProblemApi";
import ResourceProblemSearchCriteria from "app/models/ResourceProblemSearchCriteria";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import Select from "react-select";
import "./ResourceProblemFilters.css";

const ResourceProblemFilters = () => {
  const { control, watch, setValue } =
    useFormContext<ResourceProblemSearchCriteria>();
  const { data: offices } = useOfficesByCriteria({ active: true });
  const officeId = watch("officeId");
  const floors = offices?.find((office) => office.id === officeId)?.floors;
  const { data: resourceProblemCategories } = useResourceProblemCategories();
  const intl = useIntl();
  return (
    <Form className="ResourceProblemFilters-Form" autoComplete="off">
      <Controller
        control={control}
        name="officeId"
        render={({ field: { value, onChange } }) => (
          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            placeholder={intl.formatMessage({
              id: "resourceProblems.filters.selectOffice",
              defaultMessage: "Select a office",
            })}
            options={offices}
            menuPlacement="auto"
            value={offices?.find((office) => office.id === value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id + ""}
            onChange={(value) => {
              onChange(value?.id);
              setValue("floorId", undefined);
              setValue("page", 0);
            }}
            isClearable
          />
        )}
      />

      <Controller
        control={control}
        name="floorId"
        render={({ field: { value, onChange } }) => (
          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            placeholder={intl.formatMessage({
              id: "resourceProblems.filters.selectFloor",
              defaultMessage: "Select a floor",
            })}
            options={floors}
            menuPlacement="auto"
            value={floors?.find((floor) => floor.id === value)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id + ""}
            onChange={(value) => {
              onChange(value?.id);
              setValue("page", 0);
            }}
            isDisabled={!officeId}
            isClearable
          />
        )}
      />

      <Controller
        control={control}
        name="categoryId"
        render={({ field: { value, onChange } }) => (
          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            placeholder={intl.formatMessage({
              id: "resourceProblems.filters.selectCategory",
              defaultMessage: "Select a category",
            })}
            options={resourceProblemCategories}
            menuPlacement="auto"
            value={resourceProblemCategories?.find(
              (category) => category.id === value
            )}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id + ""}
            onChange={(value) => {
              onChange(value?.id);
              setValue("page", 0);
            }}
            isClearable
          />
        )}
      />
    </Form>
  );
};

export default ResourceProblemFilters;
