const passGenerator = require("generate-password-browser");

const passGenOpts = {
  length: 8,
  strict: true,
  numbers: true,
  lowercase: true,
  uppercase: true,
  symbols: true,
};

export function generatePass(): string {
  return passGenerator.generate(passGenOpts);
}
