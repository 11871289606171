import "./HeaderAndContent.css";

const Header: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="HeaderAndContent-Header">{children}</div>;
};

const Content: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="HeaderAndContent-Content">{children}</div>;
};

const HeaderAndContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <div className="HeaderAndContent-Container">{children}</div>;
};

export default Object.assign(HeaderAndContent, {
  Header,
  Content,
});
