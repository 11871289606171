import bg from "./bg.json";
import de from "./de.json";
import en from "./en.json";
import fr from "./fr.json";

const languages = {
  "en-GB": { label: "English(GB)", messages: en },
  "en-US": { label: "English(US)", messages: en },
  bg: { label: "Български", messages: bg },
  fr: { label: "Français", messages: fr },
  de: { label: "Deutsch", messages: de },
};

export default languages;
