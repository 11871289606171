import Office from "app/models/Office";
import ReservationPolicy from "app/models/ReservationPolicy";
import { useOfficeConfig } from "app/utils/useConfig";
import { EntityModel } from "hateoas-hal-types";
import moment, { MomentInput } from "moment";

export function isToday(date: Date) {
  return moment().isSame(date, "d");
}

export function calculateNonAllDayResourceMaxTime(
  policy: ReservationPolicy | undefined,
  startDate: MomentInput
) {
  if (policy?.maximumReservations) {
    let maxEndDate = calculateResourceMaxStartDate(
      policy,
      moment(),
      () => true,
      moment().endOf("day").toDate()
    );
    let maxReservationDate = policy.timeLimit
      ? moment(startDate).add(policy.timeLimit, "minutes")
      : moment(maxEndDate);

    if (maxReservationDate.isAfter(maxEndDate)) {
      maxReservationDate = moment(maxEndDate).add(1, "day");
    }
    return maxReservationDate.toDate();
  }
  return undefined;
}

export function calculateNonAllDayResourceMaxEndDate(
  policy: ReservationPolicy | undefined,
  startDate: MomentInput,
  isWeekday: (date: MomentInput) => boolean,
  workDayEnd: Date
) {
  if (!policy?.bookingWindow) return null;

  let maxReservationDate = moment(startDate);
  let maxEndDate = calculateResourceMaxStartDate(policy, moment(), isWeekday);
  for (let i = policy?.bookingWindow || 0; i > 1; ) {
    maxReservationDate = maxReservationDate.add(1, "day");
    if (isWeekday(maxReservationDate)) {
      i--;
    }
  }

  if (maxEndDate && maxEndDate < maxReservationDate.toDate()) {
    maxReservationDate = moment(maxEndDate);
  }

  if (!moment().isAfter(workDayEnd)) {
    maxReservationDate = maxReservationDate.subtract(1, "day");
  }
  return maxReservationDate.toDate();
}

export function calculateAllDayResourceMaxEndDate(
  policy: ReservationPolicy | undefined,
  startDate: MomentInput,
  isWeekday: (date: MomentInput) => boolean
) {
  if (!policy?.maximumReservations) return null;

  let maxReservationDate = moment(startDate);
  let maxEndDate = calculateResourceMaxStartDate(policy, moment(), isWeekday);
  for (let i = policy?.maximumReservations || 0; i > 1; ) {
    maxReservationDate = maxReservationDate.add(1, "day");
    if (isWeekday(maxReservationDate)) {
      i--;
    }
  }

  if (maxEndDate && maxEndDate < maxReservationDate.toDate()) {
    maxReservationDate = moment(maxEndDate);
  }
  return maxReservationDate.toDate();
}

export function calculateResourceMaxStartDate(
  policy: ReservationPolicy | undefined,
  startDate: MomentInput,
  isWeekday: (date: MomentInput) => boolean,
  workDayEnd?: Date
) {
  if (!policy?.bookingWindow) {
    return undefined;
  }
  let maxReservationDate = moment(startDate);

  for (let i = policy.bookingWindow; i > 0; ) {
    maxReservationDate = maxReservationDate.add(1, "day");
    if (isWeekday(maxReservationDate)) {
      i--;
    }
  }
  if (workDayEnd && moment().isBefore(workDayEnd)) {
    maxReservationDate = maxReservationDate.subtract(1, "day");
  }
  return maxReservationDate.toDate();
}

export const maxDate = (dates: MomentInput[]) =>
  moment.max(dates.map((d) => moment(d))).toDate();

export const minDate = (dates: MomentInput[]) =>
  moment.min(dates.filter((d) => !!d).map((d) => moment(d))).toDate();

export const useDefaultStartDate = (office?: EntityModel<Office>) => {
  const { getDefaultStartWorkDayFor } = useOfficeConfig(office);
  return getDefaultStartWorkDayFor(new Date());
};

export const useDefaultEndDate = (office?: EntityModel<Office>) => {
  const { getDefaultEndWorkDayFor } = useOfficeConfig(office);
  return getDefaultEndWorkDayFor(new Date());
};
