import { ReactComponent as FaCalendar } from "app/icons/v2/fa-calendar.svg";
import { forwardRef } from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";

const CustomDateTimePickerInput = forwardRef<
    any,
    FormControlProps & React.HTMLProps<HTMLInputElement>
  >(({ value, onClick, onChange, ...rest }, ref) => (
    <InputGroup className="mb-0">
      <Form.Control
        type="text"
        value={value}
        onClick={onClick}
        ref={ref}
        onChange={onChange}
        inputMode="none"
        {...rest}
      />
      <InputGroup.Text onClick={onClick} style={{ cursor: "pointer" }}>
        <FaCalendar width="1.2em" height="1.2em" />
      </InputGroup.Text>
    </InputGroup>
  ));

  export default CustomDateTimePickerInput;