import { useGlobalConfig } from "app/api/GlobalConfigApi";
import {
  DATE_PATTERN,
  DATE_TIME_WITHOUT_SECONDS_PATTERN,
} from "app/constants/DateTimeFormatting";
import Office from "app/models/Office";
import UiConfiguration from "app/models/UiConfiguration";
import { EntityModel } from "hateoas-hal-types";
import moment, { MomentInput } from "moment";
import { useEffect, useState } from "react";

const defaultConfig = {
  workdayStart: "09:00",
  workdayEnd: "20:00",
  reservationTimeIntervals: 30,
  workdays: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"],
};
const days = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const withFunctions = (config: UiConfiguration) => {
  const self = {
    ...config,
    getDefaultStartWorkDayFor: (day: MomentInput) => {
      const dateStringRepresentation = moment(day).format(DATE_PATTERN);
      return moment(
        `${dateStringRepresentation} ${config.workdayStart}`,
        DATE_TIME_WITHOUT_SECONDS_PATTERN
      ).toDate();
    },

    getDefaultEndWorkDayFor: (day: MomentInput) => {
      const dateStringRepresentation = moment(day).format(DATE_PATTERN);

      return moment(
        `${dateStringRepresentation} ${config.workdayEnd}`,
        DATE_TIME_WITHOUT_SECONDS_PATTERN
      ).toDate();
    },

    isWeekday: (date: MomentInput) => {
      const day = moment(date).day();
      const weekdays = config.workdays;
      return weekdays!.indexOf(days[day]) > -1;
    },

    calculateNextWeekday: () => {
      let firstWeekday = moment();
      while (
        !self.isWeekday(firstWeekday) ||
        firstWeekday.isAfter(self.getDefaultEndWorkDayFor(firstWeekday))
      ) {
        firstWeekday.add(1, "day");
        firstWeekday = moment(self.getDefaultStartWorkDayFor(firstWeekday));
      }
      return firstWeekday.toDate();
    },
  };
  return self;
};

export default function useConfig() {
  const [globalConfig, setGlobalConfig] = useState(
    withFunctions(defaultConfig)
  );
  const { data, error } = useGlobalConfig();

  useEffect(() => {
    const newConfig = { ...defaultConfig, ...data };
    data && setGlobalConfig(withFunctions(newConfig));
  }, [data, error]);

  return globalConfig;
}

export function useOfficeConfig(office?: EntityModel<Office>) {
  const [config, setConfig] = useState(withFunctions(defaultConfig));
  const globalConfig = useConfig();
  useEffect(() => {
    setConfig(withFunctions((office as UiConfiguration) || globalConfig));
  }, [globalConfig, office]);

  return config;
}
