const SecurityApi = {
  runAs: (principal) =>
    fetch(`/api/v1/security/run-as?principal=${principal}`, {
      method: "POST",
    }),

  releaseRunAs: () =>
    fetch("/api/v1/security/run-as/release", {
      method: "POST",
    }),
};

export default SecurityApi;
