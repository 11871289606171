import AddUsersToGroup from "app/models/AddUsersToGroup";
import BaseCollecionLinks from "app/models/BaseCollectionLinks";
import CreateGroup from "app/models/CreateGroup";
import Group from "app/models/Group";
import GroupSearchCriteria from "app/models/GroupSearchCriteria";
import UpdateGroup from "app/models/UpdateGroup";
import fetchClient, { stringifyUrl } from "app/utils/fetch-client";
import { CollectionModel, EntityModel } from "hateoas-hal-types";
import useSwr from "swr";
import { reloadUsers } from "./UserApi";

const basePath = "/api/v1/groups";

function reloadGroups(
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return mutate(new RegExp(basePath));
}

export function useGroup(groupId: string | number) {
  return useSwr<EntityModel<Group>>(`${basePath}/${groupId}`);
}

export function useGroupsByCriteria(criteria: GroupSearchCriteria = {}) {
  const { data, error } = useSwr<CollectionModel<Group>>(
    stringifyUrl(basePath, criteria)
  );
  const groups = data ? data?._embedded?.groups || [] : undefined;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return { data: groups, links: extractedLinks, error };
}

export const createGroup = (
  url: string,
  group: CreateGroup,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<Group> => {
  return fetchClient
    .post(url, {
      body: JSON.stringify(group),
    })
    .then(async (groupId) => {
      await reloadGroups(mutate);
      return groupId;
    });
};

export const editGroup = (
  url: string,
  group: UpdateGroup,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> =>
  fetchClient
    .patch(url, {
      body: JSON.stringify(group),
    })
    .then(() => reloadGroups(mutate));

export const deleteGroup = (
  url: string,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) => fetchClient.delete(url).then(() => reloadGroups(mutate));

export const addUserToGroup = (
  url: string,
  requestBody: AddUsersToGroup,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> =>
  fetchClient
    .post(url, {
      body: JSON.stringify(requestBody),
    })
    .then(() => reloadGroups(mutate))
    .then(() => reloadUsers(mutate));

export const removeUserFromGroup = (
  url: string,
  operatorId: string,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> =>
  fetchClient
    .delete(url, {
      body: JSON.stringify({ id: operatorId }),
    })
    .then(() => reloadGroups(mutate))
    .then(() => reloadUsers(mutate));
