import { Spinner } from "react-bootstrap";
import "./LoadingSpinner.css";

const LoadingSpinner: React.FC<{}> = () => {
  return (
    <Spinner
      className="loadingSpinner"
      animation="border"
      variant={"ligth"}
      id="loadingSpinner"
    />
  );
};

export default LoadingSpinner;
