import CreateNotification from "app/models/CreateNotification";
import Notification from "app/models/Notification";
import NotificationSearchCriteria from "app/models/NotificationSearchCriteria";
import UpdateNotification from "app/models/UpdateNotification";
import fetchClient, { replacer, stringifyUrl } from "app/utils/fetch-client";
import { apiFetchWithReviver } from "AppConfig";
import { EntityModel } from "hateoas-hal-types";
import useSwr from "swr";

const basePath = "/api/v1/notifications";

export function reloadNotifications(
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return mutate(new RegExp(basePath));
}

export const useNotifications = (criteria: NotificationSearchCriteria) => {
  const { data, error } = useSwr(
    stringifyUrl(basePath, criteria),
    apiFetchWithReviver
  );
  const notifications = data
    ? data?._embedded?.notificationModelList || []
    : undefined;
  return { data: notifications as Array<EntityModel<Notification>>, error };
};

export const createNotification = (
  notification: CreateNotification,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any> => {
  return fetchClient
    .post(basePath, {
      body: JSON.stringify(notification, replacer),
    })
    .then(() => reloadNotifications(mutate));
};

export const updateNotificationActive = (
  notification: EntityModel<Notification>,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) => {
  return fetchClient
    .put(notification._links.active.href, {
      body: JSON.stringify(!notification.active),
    })
    .then(() => reloadNotifications(mutate));
};

export const updateNotification = (
  notification: Notification,
  newNotification: UpdateNotification,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any> => {
  return fetchClient
    .put(notification?._links?.update.href || "", {
      body: JSON.stringify(newNotification, replacer),
    })
    .then(() => reloadNotifications(mutate));
};

export const deleteNotification = (
  notification: Notification,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) => {
  const url = notification?._links?.delete?.href || "";
  return fetchClient.delete(url).then(() => reloadNotifications(mutate));
};
