import React from "react";
import { FormControlProps } from "react-bootstrap";

export const CustomDatePickerInput = React.forwardRef<
  any,
  FormControlProps & React.HTMLProps<HTMLInputElement>
>(({ value, className, onClick, onChange, ...rest }, ref) => {
  return (
    <input
      type="text"
      className={`${className}`}
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      inputMode="none"
      value={value}
      {...rest}
    />
  );
});
