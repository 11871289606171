import { useBrandingInfo } from "app/api/ApplicationBrandingApi";
import { createContext, useCallback, useEffect, useState } from "react";
import Snowfall from "react-snowfall";
import useSound from "use-sound";
import sound from "./christmas-music-music-097970217_nw_prev.m4a";

export const XmasThemeContext = createContext<{
  isXmasTheme: boolean;
  toggleEffects: () => void;
}>({
  isXmasTheme: false,
  toggleEffects: () => {},
});

const XmasThemeContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data: branding } = useBrandingInfo();
  const [effectsEnabled, setEffectsEnabled] = useState(false);
  const [play, { stop }] = useSound(sound, {
    volume: 0.5,
  });

  const toggleEffects = useCallback(() => {
    if (effectsEnabled) {
      setEffectsEnabled(false);
      stop();
      return;
    }
    setEffectsEnabled(true);
    play();
  }, [effectsEnabled, play, stop]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (branding?.enableXmasMode && event.altKey && event.key === "x") {
        toggleEffects();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [toggleEffects, branding?.enableXmasMode]);

  return (
    <XmasThemeContext.Provider
      value={{
        isXmasTheme: branding?.enableXmasMode || false,
        toggleEffects,
      }}
    >
      {children}
      {branding?.enableXmasMode && effectsEnabled && <Snowfall />}
    </XmasThemeContext.Provider>
  );
};

export default XmasThemeContextProvider;
