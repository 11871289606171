import { useUsersByCriteria } from "app/api/UserApi";
import { ReportFilters } from "app/models/ReportFilters";
import LoadingSpinner from "components/LoadingSpinner";
import { useEffect, useMemo, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import TeamPreviewMonthlyTable from "./TeamPreviewMonthlyTable";
import TeamPreviewWeeklyTable from "./TeamPreviewWeeklyTable";
import TeamPreviewYearlyTable from "./TeamPreviewYearlyTable";
import { ToggleOption } from "./UtilizationByTeamReport";

const TeamPreviewManagement: React.FC<{
  selectedOption: ToggleOption;
  setSelectedOption: (newOption: ToggleOption) => void;
}> = ({ selectedOption, setSelectedOption }) => {
  const { watch, formState } = useFormContext<ReportFilters>();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  useEffect(() => setPage(0), [formState]);

  const { data: users } = useUsersByCriteria({
    active: true,
    memberOfId: watch("groupId"),
  });

  const startTime = watch("period.from");
  const endTime = watch("period.to");
  const intl = useIntl();

  const TableComponent =
    selectedOption === ToggleOption.WEEKLY
      ? TeamPreviewWeeklyTable
      : selectedOption === ToggleOption.MONTHLY
      ? TeamPreviewMonthlyTable
      : TeamPreviewYearlyTable;

  const isNewDateRangeLoaded = useMemo(
    () =>
      selectedOption === ToggleOption.MONTHLY
        ? startTime.getFullYear() === endTime.getFullYear() &&
          startTime.getMonth() === endTime.getMonth()
        : startTime.getFullYear() === endTime.getFullYear() &&
          startTime.getMonth() !== endTime.getMonth(),
    [endTime, selectedOption, startTime]
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {users ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "sticky",
              left: "0",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            <ToggleButtonGroup
              style={{
                width: "30%",
              }}
              type="radio"
              name="reportType"
              value={selectedOption}
            >
              {Object.values(ToggleOption).map((option) => (
                <ToggleButton
                  key={option}
                  style={{ display: "inline-block" }}
                  variant={
                    selectedOption === option ? "primary" : "outline-primary"
                  }
                  value={option}
                  onClick={() => setSelectedOption(option)}
                >
                  <FormattedMessage
                    id={`reports.utilizationByTeam.${option.toLowerCase()}`}
                    defaultMessage={option}
                  />
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <h3>
              {selectedOption === ToggleOption.MONTHLY &&
                `${new Intl.DateTimeFormat(intl.locale, {
                  month: "long",
                }).format(startTime)} `}
              {startTime.getFullYear()}
            </h3>
          </div>

          {isNewDateRangeLoaded && (
            <TableComponent
              users={users}
              page={page}
              setPage={handleChangePage}
              rowsPerPage={size}
              setRowsPerPage={handleChangeRowsPerPage}
              total={users.length || 0}
            />
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default TeamPreviewManagement;
