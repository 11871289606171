import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useAuditsByCriteria } from "app/api/AuditApi";
import AuditFilter, { convertFilterIntoCriteria } from "app/models/AuditFilter";
import LoadingSpinner from "components/LoadingSpinner";
import { useFormContext } from "react-hook-form";
import { FormattedDate, FormattedMessage } from "react-intl";

const AuditPreviewtable = () => {
  const { watch, setValue } = useFormContext<AuditFilter>();
  const criteria = convertFilterIntoCriteria(watch());
  const { data: audits, page, isLoading } = useAuditsByCriteria(criteria);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setValue("page", newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue("size", parseInt(event.target.value, 10));
    setValue("page", 0);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="audit.tableHeaders.date"
                  defaultMessage="Date"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="audit.tableHeaders.user"
                  defaultMessage="User"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="audit.tableHeaders.reason"
                  defaultMessage="Reason"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="audit.tableHeaders.entityId"
                  defaultMessage="Entity id"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {audits?.map((audit, index) => (
              <TableRow key={index}>
                <TableCell>
                  <FormattedDate
                    value={audit?.timestamp}
                    dateStyle="short"
                    timeStyle="short"
                  />
                </TableCell>
                <TableCell>{audit?.email}</TableCell>
                <TableCell>{audit?.reason}</TableCell>
                <TableCell>{audit?.entityId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{ "& > * > p ": { margin: "unset" } }}
                count={page?.totalElements || 0}
                page={page?.number || 0}
                onPageChange={handleChangePage}
                rowsPerPage={page?.size || 0}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </>
  );
};

export default AuditPreviewtable;
