import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";

export default function useTeams() {
  const [context, setContext] = useState<microsoftTeams.Context>();

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext((context) => {
      setContext(context);
    });
  }, []);
  return { context, instance: microsoftTeams };
}
