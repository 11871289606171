import Audit from "app/models/Audit";
import { stringifyUrl } from "app/utils/fetch-client";
import { apiFetchWithReviver } from "AppConfig";
import { PagedModel } from "hateoas-hal-types";
import useSwr from "swr";

const auditBasePath = "/api/v1/audits";

export function useAuditsByCriteria(criteria: any) {
  const { data, error, isValidating } = useSwr<PagedModel<Audit>>(
    stringifyUrl(auditBasePath, criteria),
    apiFetchWithReviver
  );
  const audits = data?._embedded ? data._embedded.auditModelList : [];
  return {
    data: audits as Array<Audit>,
    page: data?.page,
    error,
    isLoading: isValidating,
  };
}

export function useAuditReasons() {
  return useSwr<Array<string>>(auditBasePath + "/reasons");
}

export function useAuditEntityNames() {
  return useSwr<Array<string>>(auditBasePath + "/entityNames");
}