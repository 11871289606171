import { useEffect, useState } from "react";
import { Image, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useBrandingInfo } from "./api/ApplicationBrandingApi";
import HeaderAndContent from "./HeaderAndContent";
import "./Login.css";
import LoginForm from "./LoginForm";

const { Header, Content } = HeaderAndContent;

const NewLogin = () => {
  const { data: brandingInfo } = useBrandingInfo();
  const methods = useForm<{
    username: string;
    password: string;
  }>();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const updateMobile = () => setIsMobile(window.innerWidth < 768);
  const intl = useIntl();
  const appLogo = brandingInfo?._links.appLogo?.href;
  useEffect(() => {
    window.addEventListener("resize", updateMobile);

    return () => {
      window.removeEventListener("resize", updateMobile);
    };
  }, []);

  return brandingInfo ? (
    <HeaderAndContent>
      <Header>
        <Helmet
          title={
            brandingInfo.applicationName
              ? brandingInfo.applicationName
              : "Desk Buddy"
          }
        />
      </Header>
      <Content>
        <FormProvider {...methods}>
          <Stack
            direction={isMobile ? "vertical" : "horizontal"}
            style={{
              minHeight: "100%",
              margin: "auto",
            }}
          >
            {!isMobile && (
              <div className="Image-Container">
                <Image
                  src={brandingInfo._links.lobbyPicture?.href}
                  alt="Desk buddy robot"
                />
              </div>
            )}
            <div className="Form-And-Title-Wrapper">
              <div
                className={`Form-And-Title ${
                  appLogo ? "App-Logo" : "Header-Text"
                }`}
              >
                {appLogo ? (
                  <Image src={appLogo} className="mb-4" />
                ) : (
                  <h1
                    className="mb-5 Login-Text"
                    style={{ width: "fit-content", margin: "auto" }}
                  >
                    {brandingInfo.lobbyName
                      ? intl.formatMessage(
                          {
                            id: "Lobby.clientMessage",
                            defaultMessage: "Welcome to {lobbyName}",
                          },
                          { lobbyName: brandingInfo.lobbyName }
                        )
                      : intl.formatMessage({
                          id: "Lobby.welcome",
                          defaultMessage: "Welcome to Desk Buddy",
                        })}
                  </h1>
                )}
                <LoginForm
                  isMobile={isMobile}
                  imgSrc={brandingInfo._links.lobbyPicture?.href}
                />
                {/* div for customising PoweredByProxiad position */}
                <div
                  id="Login"
                  style={{ width: "fit-content", margin: "auto" }}
                  className="focus"
                />
              </div>
            </div>
          </Stack>
        </FormProvider>
      </Content>
    </HeaderAndContent>
  ) : null;
};

export default NewLogin;
