import NotificationManagement from "app/admin/notifications/NotificationManagement";
import JoinDeskBuddy from "app/JoinDeskBuddy";
import Login from "app/Login";
import AuditManagement from "app/reports/audit/AuditManagement";
import ResourceProblemManagement from "app/reports/resourceProblems/ResourceProblemManagement";
import UtilizationByTeamReport from "app/reports/team-reports/UtilizationByTeamReport";
import React, { Suspense, useEffect, useState } from "react";
import { lazily } from "react-lazily";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import { hasDeviceRegistration } from "./app/admin/devices/DeviceStorage";
import ErrorBoundary from "./app/ErrorBoundary";
import HeaderAndContent from "./app/HeaderAndContent";
import Lobby from "./app/Lobby";
import PoweredByProxiad from "./app/PoweredByProxiad";
import RequiresAccess from "./app/RequiresAccess";
import Topbar from "./app/Topbar";
import useBranding from "./app/useBranding";

const {
  ReportsManagment,
  UtilizationByDateReport,
  UtilizationByHourReport,
  CurrentUtilizationReport,
} = lazily(
  () => import(/* webpackChunkName: "reports" */ "./app/chunks/reports.chunk")
);

const { MeetingRoomReservation, DeviceCodeForm } = lazily(
  () => import(/* webpackChunkName: "device" */ "./app/chunks/device.chunk")
);

const { PasswordResetLinkHandler } = lazily(
  () =>
    import(
      /*webpackChunkName: "passwordReset" */ "./app/chunks/passwordReset.chunk"
    )
);

const {
  DeviceManagement,
  FloorPlansManagement,
  Floors,
  GroupMembersManagement,
  Groups,
  UserManagement,
  Roles,
  Offices,
  BrandingManagement,
} = lazily(
  () => import(/* webpackChunkName: "admin" */ "./app/chunks/admin.chunk")
);

const {
  BookingsManagement,
  MyBookingsManagement,
  ReservationCheckinLinkHandler,
  ReservationInvitationLinkHandler,
  PendingReservationCheckinsModal,
} = lazily(
  () => import(/* webpackChunkName: "bookings" */ "./app/chunks/bookings.chunk")
);

const LazyComponent: React.FC<{ component: React.ComponentType }> = ({
  component: Component,
}) => {
  return (
    <Suspense fallback={<></>}>
      <Component />
    </Suspense>
  );
};

const App = () => {
  useBranding();
  const isDevice = hasDeviceRegistration();

  const [renderProxiad, setRenderProxiad] = useState(
    document.getElementById("root")
  );
  // eslint-disable-next-line
  useEffect(() => {
    if (window.location.pathname === "/lobby") {
      setRenderProxiad(document.getElementById("Lobby"));
    } else if (window.location.pathname === "/login") {
      setRenderProxiad(document.getElementById("Login"));
    } else {
      setRenderProxiad(document.getElementById("root"));
    }
  });

  if (isDevice) {
    const element = document.getElementById("html");
    document.body.style.overscrollBehaviorY = "contain";
    element && (element.style.overscrollBehaviorY = "contain");
    return <LazyComponent component={MeetingRoomReservation} />;
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/lobby" element={<Lobby />} />
          <Route path="/login" element={<Login />} />
          <Route path="/join-desk-buddy" element={<JoinDeskBuddy />} />
          <Route path="/" element={<Root />}>
            <Route
              path="/devices/register"
              element={<LazyComponent component={DeviceCodeForm} />}
            />
            <Route
              path="/password-change-requests/:passwordChangeRequestId"
              element={<LazyComponent component={PasswordResetLinkHandler} />}
            />
            <Route
              path="/reservations/:reservationId/checkin"
              element={
                <LazyComponent component={ReservationCheckinLinkHandler} />
              }
            />
            <Route
              path="/reservations/invitations/:invitationId"
              element={
                <LazyComponent component={ReservationInvitationLinkHandler} />
              }
            />
            <Route
              path="/my-bookings"
              element={
                <>
                  <LazyComponent component={PendingReservationCheckinsModal} />
                  <LazyComponent component={MyBookingsManagement} />
                </>
              }
            />
            <Route
              path="/bookings"
              element={
                <RequiresAccess path={"/bookings"}>
                  <LazyComponent component={BookingsManagement} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/devices"
              element={
                <RequiresAccess path={"/admin/devices"}>
                  <LazyComponent component={DeviceManagement} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/offices/:officeId/floors/:floorId/plans"
              element={
                <RequiresAccess path={"/admin/offices"}>
                  <LazyComponent component={FloorPlansManagement} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/offices/:officeId/floors"
              element={
                <RequiresAccess path={"/admin/offices"}>
                  <LazyComponent component={Floors} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/offices"
              element={
                <RequiresAccess path={"/admin/offices"}>
                  <LazyComponent component={Offices} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/groups/:groupId"
              element={
                <RequiresAccess path={"/admin/groups"}>
                  <LazyComponent component={GroupMembersManagement} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/groups"
              element={
                <RequiresAccess path={"/admin/groups"}>
                  <LazyComponent component={Groups} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/users"
              element={
                <RequiresAccess path={"/admin/users"}>
                  <LazyComponent component={UserManagement} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/roles"
              element={
                <RequiresAccess path={"/admin/roles"}>
                  <LazyComponent component={Roles} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/notifications"
              element={
                <RequiresAccess path={"/admin/notifications"}>
                  <LazyComponent component={NotificationManagement} />
                </RequiresAccess>
              }
            />
            <Route
              path="/reports"
              element={
                <RequiresAccess path={"/reports"}>
                  <LazyComponent component={ReportsManagment} />
                </RequiresAccess>
              }
            />
            <Route
              path="/reports/utilization-by-date"
              element={
                <RequiresAccess path={"/reports/utilization-by-date"}>
                  <LazyComponent component={UtilizationByDateReport} />
                </RequiresAccess>
              }
            />
            <Route
              path="/reports/current-utilization"
              element={
                <RequiresAccess path={"/reports/current-utilization"}>
                  <LazyComponent component={CurrentUtilizationReport} />
                </RequiresAccess>
              }
            />
            <Route
              path="/reports/utilization-by-hour"
              element={
                <RequiresAccess path={"/reports/utilization-by-hour"}>
                  <LazyComponent component={UtilizationByHourReport} />
                </RequiresAccess>
              }
            />
            <Route
              path="/reports/utilization-by-team"
              element={
                <RequiresAccess path={"/reports/utilization-by-team"}>
                  <LazyComponent component={UtilizationByTeamReport} />
                </RequiresAccess>
              }
            />
            <Route
              path="/reports/audit"
              element={
                <RequiresAccess path={"/reports/audit"}>
                  <LazyComponent component={AuditManagement} />
                </RequiresAccess>
              }
            />
            <Route
              path="/reports/reported-problems"
              element={
                <RequiresAccess path={"/reports/reported-problems"}>
                  <LazyComponent component={ResourceProblemManagement} />
                </RequiresAccess>
              }
            />
            <Route
              path="/admin/branding"
              element={
                <RequiresAccess path={"/admin/branding"}>
                  <LazyComponent component={BrandingManagement} />
                </RequiresAccess>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
      <PoweredByProxiad renderIn={renderProxiad} />
    </>
  );
};

const Root = () => (
  <HeaderAndContent>
    <HeaderAndContent.Header>
      <Topbar />
    </HeaderAndContent.Header>
    <HeaderAndContent.Content>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </HeaderAndContent.Content>
  </HeaderAndContent>
);

export default App;
