import { HalLink } from "hateoas-hal-types";
import { IntlShape } from "react-intl";
import Floor from "./Floor";
import LocationInfo from "./LocationInfo";
import Resource from "./Resource";
import UserEmbeddableView from "./UserEmbeddableView";

interface Reservation {
  id: string;
  startTime: Date;
  endTime: Date;
  allDay: boolean;
  checked: boolean;
  cancelled: boolean;
  checkedOut: boolean;
  guest: boolean;
  guestName: string;
  incognito: boolean;
  instant: boolean;
  external: boolean;
  groupReservationId: string;
  reservedFor?: UserEmbeddableView;
  reservedBy?: UserEmbeddableView;
  reservedAt?: Date;
  checkedBy?: UserEmbeddableView;
  series: {
    id: string;
    reservationSeriesId: string;
    startTime: Date;
    endTime: Date;
  };
  resource: Resource;
  floor: Floor;
  canReportProblem: boolean;
  geoNeeded?: boolean;
  score: number;
  checkedInOn: Date;
  locationInformation: LocationInfo;
  ipNeeded: boolean;
  ipTrusted: boolean;
  _links: {
    checkIn: HalLink;
    redoCheckIn: HalLink;
    checkout: HalLink;
    cancel: HalLink;
    seriesCancel: HalLink;
    validStatusChange: HalLink;
    self: HalLink;
  };
}

export function getCheckInMsg(reservation: Reservation, intl: IntlShape) {
  const checkInStatus = reservation.locationInformation.checkInStatus;
  const onBehalf = reservation.checkedBy?.id !== reservation.reservedFor?.id;
  const msgComponent = checkInStatus?.includes("UNTRUSTED_IP")
    ? checkInStatus?.includes("ACCURACY_OUT_OF_RANGE") ||
      checkInStatus?.includes("OUTSIDE_OFFICE") ||
      checkInStatus?.includes("NO_CONSENT")
      ? intl.formatMessage({
          id: "myBookings.toastMessage.checkInWithIssue.geoAndIp",
          defaultMessage:
            "required office location range and trusted network IP",
        })
      : intl.formatMessage({
          id: "myBookings.toastMessage.checkInWithIssue.ip",
          defaultMessage: "trusted network IP",
        })
    : intl.formatMessage({
        id: "myBookings.toastMessage.checkInWithIssue.geo",
        defaultMessage: "required office location range",
      });
  return !onBehalf &&
    (reservation.geoNeeded || reservation.ipNeeded) &&
    reservation.score <= 0
    ? checkInStatus?.includes("AFTER_CHECK_IN_PERIOD")
      ? intl.formatMessage({
          id: "myBookings.toastMessage.checkInWithIssue.afterCheckIn",
          defaultMessage: "Your reservation is outside of the check-in period.",
        })
      : intl.formatMessage(
          {
            id: "myBookings.toastMessage.checkInWithIssue",
            defaultMessage:
              "Incomplete check-in validation. You are outside the {range}. Please try again when you are in the office.",
          },
          {
            range: msgComponent,
          }
        )
    : onBehalf
    ? intl.formatMessage(
        {
          id: "resourcePopover.toastMessage.checkIn",
          defaultMessage:
            "You have checked in a reservation for {resourceName}, reserved for {userFullName}",
        },
        {
          resourceName: `${reservation.resource.name}`,
          userFullName: reservation.guest
            ? reservation.guestName
              ? reservation.guestName
              : intl.formatMessage({
                  id: "reservation.guest",
                  defaultMessage: "Guest",
                })
            : `${reservation.reservedFor?.fullName}`,
        }
      )
    : intl.formatMessage(
        {
          id: "myBookings.toastMessage.checkIn",
          defaultMessage:
            "Your reservation for {resourceName} has been checked",
        },
        {
          resourceName: `${reservation.resource.name}`,
        }
      );
}

export const getStatusMessages = (statuses: Array<string>, intl: IntlShape) =>
  !statuses
    ? [
        intl.formatMessage({
          id: "bookings.noStatus",
          defaultMessage: "No status to provide additional information",
        }),
      ]
    : statuses.map((status) =>
        status === "EARLY_CHECKOUT"
          ? intl.formatMessage({
              id: "bookings.earlyCheckout",
              defaultMessage:
                "Check-out was before the allowed hours of the office.",
            })
          : status === "ACCURACY_OUT_OF_RANGE"
          ? intl.formatMessage({
              id: "bookings.accuracyOutOfRange",
              defaultMessage:
                "Geolocation provided was too inaccurate to be used",
            })
          : status === "NO_CONSENT"
          ? intl.formatMessage({
              id: "bookings.checkInNoConcent",
              defaultMessage: "Consent was not provided to gather geolocation",
            })
          : status === "UNTRUSTED_IP"
          ? intl.formatMessage({
              id: "bookings.checkInNoyTrusted",
              defaultMessage: "Checked in from untrusted IP",
            })
          : status === "TRUSTED_IP"
          ? intl.formatMessage({
              id: "bookings.checkInTrusted",
              defaultMessage: "Checked in from trusted IP",
            })
          : status === "CHECKED_ON_BEHALF"
          ? intl.formatMessage({
              id: "bookings.checkInOnBehalf",
              defaultMessage: "Checked in on behalf",
            })
          : status === "INSIDE_OFFICE"
          ? intl.formatMessage({
              id: "bookings.checkInInside",
              defaultMessage: "Checked in from inside the office",
            })
          : status === "OUTSIDE_OFFICE"
          ? intl.formatMessage({
              id: "bookings.checkInOutside",
              defaultMessage: "Checked in from outside the office",
            })
          : status === "AFTER_CHECK_IN_PERIOD"
          ? intl.formatMessage({
              id: "bookings.afterCheckIn",
              defaultMessage: "Checked in outside of the check-in period",
            })
          : intl.formatMessage({
              id: "bookings.noStatus",
              defaultMessage: "No status to provide additional information",
            })
      );

export default Reservation;
