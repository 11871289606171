enum ReservationPeriod {
  ALL = "ALL",
  YESTERDAY = "YESTERDAY",
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
  SELECT = "SELECT",
  ONE_MONTH = "1 MONTH",
  THREE_MONTHS = "3 MONTHS",
  THIS_MONTH = "THIS MONTH",
  LAST_MONTH = "LAST MONTH",
  THIS_YEAR = "THIS YEAR",
  LAST_YEAR = "LAST YEAR",
}
export default ReservationPeriod;
