import AuditFilter from "app/models/AuditFilter";
import PaneAndContent from "app/PaneAndContent";
import moment from "moment";
import { FormProvider, useForm } from "react-hook-form";
import AuditFilters from "./AuditFilters";
import AuditPreviewtable from "./AuditPreviewTable";

const Audits = () => {
  const methods = useForm<AuditFilter>({
    mode: "onChange",
    defaultValues: {
      page: 0,
      size: 10,
      period: {
        from: moment().startOf("day").toDate(),
        to: moment().endOf("day").toDate(),
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <PaneAndContent>
        <PaneAndContent.Pane>
          <AuditFilters />
        </PaneAndContent.Pane>
        <PaneAndContent.Content>
          <AuditPreviewtable />
        </PaneAndContent.Content>
      </PaneAndContent>
    </FormProvider>
  );
};

export default Audits;
