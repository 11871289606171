import { Button, Image, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { useBrandingInfo } from "./api/ApplicationBrandingApi";
import HeaderAndContent from "./HeaderAndContent";
import "./JoinDeskBuddy.css";
const { Header, Content } = HeaderAndContent;

const JoinDeskBuddy = () => {
  const { data: brandingInfo } = useBrandingInfo();

  return brandingInfo ? (
    <HeaderAndContent>
      <Header>
        <Helmet
          title={
            brandingInfo.applicationName
              ? brandingInfo.applicationName
              : "Desk Buddy"
          }
        />
      </Header>
      <Content>
        <Stack
          direction="vertical"
          style={{
            minHeight: "100vh",
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          className="JoinDeskBuddy-Page-Container"
        >
          <div className="Image-Container">
            <Image
              src={brandingInfo._links.lobbyPicture?.href}
              alt="Desk buddy robot"
            />
          </div>
          <FormattedMessage
            id="joinDeskBuddy.welcome"
            defaultMessage="Welcome to Desk Buddy.{br} It seems you don't have an account yet. To request a demo, please <a>contact us</a>."
            values={{
              br: <br />,
              a: (chunks) => (
                <a
                  target="_blank"
                  className="external_link"
                  rel="noreferrer"
                  href="https://see.proxiad.com/desk-buddy/"
                >
                  {chunks}
                </a>
              ),
            }}
          >
            {(txt) => <h5>{txt}</h5>}
          </FormattedMessage>
          <Button
            className="Demo-Button"
            onClick={() => window.open("https://see.proxiad.com/desk-buddy/")}
          >
            <FormattedMessage
              id="joinDeskBuddy.demoBtn"
              defaultMessage="Demo"
            />
          </Button>
        </Stack>
      </Content>
    </HeaderAndContent>
  ) : null;
};

export default JoinDeskBuddy;
