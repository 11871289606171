const Pane: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <div {...props} className="PaneAndContent-Pane">
      {children}
    </div>
  );
};

const Content: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <div {...props} className="PaneAndContent-Content">
      {children}
    </div>
  );
};

const PaneAndContent: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <div {...props} className="PaneAndContent-Container">
      {children}
    </div>
  );
};

export default Object.assign(PaneAndContent, {
  Pane,
  Content,
});
