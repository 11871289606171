import moment from "moment";
import ReportingCancelStatus from "./ReportingCancelStatus";
import ReportingCheckInStatus from "./ReportingCheckInStatus";
import ResourceType from "./ResourceType";

export interface ReportFilters {
  officeId?: string;
  floorId?: string;
  resourceType?: ResourceType;
  resourceId?: string;
  groupId?: string;
  period?: {
    to: Date;
    from: Date;
  };
  userId?: string;
  cancelledStatus?: ReportingCancelStatus;
  checkinStatus?: ReportingCheckInStatus;
  preview?: boolean;
  toJson?: boolean;
  guest?: boolean;
  size?: number;
  page?: number;
}

export const convertFilters = (filters: ReportFilters) => {
  return {
    officeId: filters.officeId,
    floorId: filters.floorId,
    groupId: filters.groupId,
    resource: filters.resourceType,
    resourceId: filters.resourceId,
    userId: filters.userId,
    cancelled:
      filters.cancelledStatus === ReportingCancelStatus.CANCELLED
        ? true
        : filters.cancelledStatus === ReportingCancelStatus.NOT_CANCELLED
        ? false
        : null,
    score:
      filters.checkinStatus === ReportingCheckInStatus.VALID_CHECKIN
        ? 1
        : filters.checkinStatus === ReportingCheckInStatus.INVALID_CHECKIN
        ? 0
        : null,
    checked:
      filters.checkinStatus === ReportingCheckInStatus.NOT_CHECKED_IN
        ? false
        : undefined,
    to: filters.period
      ? moment(filters.period.to).format("YYYY-MM-DD")
      : undefined,
    from: filters.period
      ? moment(filters.period?.from).format("YYYY-MM-DD")
      : undefined,
    toJson: filters.toJson,
    guest: filters.guest,
    page: filters.page,
    size: filters.size,
  };
};
