import ResourceProblemSearchCriteria from "app/models/ResourceProblemSearchCriteria";
import PaneAndContent from "app/PaneAndContent";
import { FormProvider, useForm } from "react-hook-form";
import ResourceProblemFilters from "./ResourceProblemFilters";
import ResourceProblemsPreviewTable from "./ResourceProblemsPreviewTable";

const ResourceProblems = () => {
  const methods = useForm<ResourceProblemSearchCriteria>({
    mode: "onChange",
    defaultValues: {
      page: 0,
      size: 10,
    },
  });
  return (
    <FormProvider {...methods}>
      <PaneAndContent>
        <PaneAndContent.Pane>
          <ResourceProblemFilters />
        </PaneAndContent.Pane>
        <PaneAndContent.Content>
          <ResourceProblemsPreviewTable />
        </PaneAndContent.Content>
      </PaneAndContent>
    </FormProvider>
  );
};

export default ResourceProblems;
