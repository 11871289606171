import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Skeleton } from "@mui/material";
import { ReactComponent as AdminNotificationIcon } from "app/icons/v2/admin_notifications.svg";
import { ReactComponent as OfficeIcon } from "app/icons/v2/admin_offices.svg";
import { ReactComponent as AdminUsersIcon } from "app/icons/v2/admin_users.svg";
import { ReactComponent as InfoIcon } from "app/icons/v2/icn-info.svg";
import { ReactComponent as WarningIcon } from "app/icons/v2/icn-warning.svg";
import { EntityModel } from "hateoas-hal-types";
import useLocale, { SupportedLocale } from "i18n/LocaleProvider";
import initials from "initials";
import { useEffect, useState } from "react";
import { Dropdown, Nav, NavItem, NavLink } from "react-bootstrap";
import toast from "react-hot-toast";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import CreateEditUserModal from "./admin/security/users/CreateEditUserModal";
import { useFormattedActuatorInfo } from "./api/GlobalConfigApi";
import { useNotifications } from "./api/NotificationApi";
import SecurityApi from "./api/SecurityApi";
import useCurrentUser, { selfUpdate } from "./api/UserApi";
import {
  getNotificationsToSee,
  showToast,
} from "./bookings/notification/NotificationToast";
import { dbLang } from "./icons/icons";
import Notification from "./models/Notification";
import NotificationLevel from "./models/NotificationLevel";
import UpdateUser from "./models/UpdateUser";
import "./Profile.css";
import { reloadImages } from "./utils/ImageUtil";
import useMatchMutate from "./utils/useMatchMutate";
import useTeams from "./utils/useTeams";

const Profile = () => {
  const { data: user, error } = useCurrentUser();
  const { data: notifications } = useNotifications({
    start: new Date(),
    end: new Date(),
    isActive: true,
  });
  const { locale, supportedLocales, changeLocale } = useLocale();
  const intl = useIntl();
  const [showEditProfile, setShowEditProfile] = useState(false);
  const matchMutate = useMatchMutate();

  const actuatorInfo = useFormattedActuatorInfo();

  const [notSeenNotificationsCount, setSeenNotificationsCount] = useState(
    getNotificationsToSee(notifications)?.length
  );

  useEffect(() => {
    const onStorage = () =>
      setSeenNotificationsCount(getNotificationsToSee(notifications)?.length);
    window.addEventListener("storage", onStorage);
    return () => window.removeEventListener("storage", onStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () =>
      setSeenNotificationsCount(getNotificationsToSee(notifications)?.length),
    [notifications]
  );

  const teams = useTeams();

  if (error) {
    return (
      <Nav.Link className="Profile" href="/signin">
        <FormattedMessage id="Profile.login" defaultMessage="Sign In" />
      </Nav.Link>
    );
  }

  const updateCurrent = async (requestBody: UpdateUser) => {
    try {
      await selfUpdate(requestBody, matchMutate).then(
        () => user?._links.avatar && reloadImages(user?._links.avatar.href)
      );
      toast.success(
        intl.formatMessage({
          id: "editProfile.successMessage",
          defaultMessage: "Profile updated successfully!",
        })
      );
      setShowEditProfile(false);
    } catch (e) {
      console.log(e);
      toast.error(
        intl.formatMessage({
          id: "profile.failureMessage",
          defaultMessage: "Error updating profile",
        })
      );
    }
  };

  const availableLangs = Object.entries(supportedLocales)
    .filter(([lang]) => lang !== locale)
    .map(([lang, { label }]) => (
      <Dropdown.Item
        key={lang}
        onClick={() => changeLocale(lang as SupportedLocale)}
      >
        {label}
      </Dropdown.Item>
    ));

  return (
    <>
      <Dropdown as={NavItem}>
        <Dropdown.Toggle as={NavLink}>
          <FontAwesomeIcon icon={dbLang as any} />
        </Dropdown.Toggle>
        <Dropdown.Menu align={{ lg: "start" }}>{availableLangs}</Dropdown.Menu>
      </Dropdown>
      <Dropdown as={NavItem}>
        <Dropdown.Toggle as={NavLink}>
          <AdminNotificationIcon style={{ width: "17px" }} />
          {notSeenNotificationsCount > 0 && (
            <span className="red-dot NotificationsCount">
              {notSeenNotificationsCount}
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu align={{ rg: "start" }}>
          <OngoingNotifications notifications={notifications} />
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={NavItem} className="ProfileDropdown">
        <Dropdown.Toggle as={NavLink}>
          <div className="Profile">
            <div className="Profile-UserInfo d-none d-lg-block">
              <div className="Profile-UserName">
                {user?.fullName || <Skeleton width="10em" />}
              </div>
            </div>
            <div className="Profile-Picture">
              <Avatar
                src={user?._links.avatar?.href}
                variant="circular"
                sx={{
                  height: "2rem",
                  width: "2rem",
                }}
              >
                {user?.fullName ? (
                  initials(`${user?.fullName}`)
                ) : (
                  <AdminUsersIcon />
                )}
              </Avatar>
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu align={{ rg: "start" }}>
          {user?.runAs ? (
            <Dropdown.Item onClick={() => releaseRunAs(user.email, intl)}>
              <FormattedMessage
                id="avatar.releaseRunAsDropdown"
                defaultMessage="Release run as"
              />
            </Dropdown.Item>
          ) : (
            <>
              <Dropdown.Item onClick={() => setShowEditProfile(true)}>
                <FormattedMessage
                  id="avatar.editProfile"
                  defaultMessage="Edit profile"
                />
              </Dropdown.Item>
              {!teams.context && (
                <Dropdown.Item
                  onClick={async () => {
                    if (user?.external) {
                      window.location.replace("/logout");
                    } else {
                      const response = await fetch("/logout", {
                        method: "POST",
                      });
                      window.location.replace(response.url);
                    }
                  }}
                >
                  <FormattedMessage
                    id="avatar.signOutDropdown"
                    defaultMessage="Sign Out"
                  />
                </Dropdown.Item>
              )}
              {actuatorInfo && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Header className="ActuatorInfo">
                    {actuatorInfo.version}
                  </Dropdown.Header>
                  <Dropdown.Header className="ActuatorInfo">
                    {actuatorInfo.revision}
                  </Dropdown.Header>
                </>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {showEditProfile && (
        <CreateEditUserModal
          user={user}
          isSelfUpdate={true}
          handleCloseModal={() => setShowEditProfile(false)}
          handleSubmit={updateCurrent}
        />
      )}
    </>
  );
};

const releaseRunAs = async (email: string, intl: IntlShape) => {
  try {
    await SecurityApi.releaseRunAs();
    const successMessage = intl.formatMessage(
      {
        id: "avatar.releaseRunAsSuccess",
        defaultMessage: "Successfully released run as of {email}!",
      },
      { email }
    );
    toast.success(successMessage);
    window.location.href = "/my-bookings";
  } catch (e) {
    const failureMessage = intl.formatMessage({
      id: "avatar.releaseRunAsFailure",
      defaultMessage: "Could not release run as!",
    });
    toast.error(failureMessage);
  }
};

const OngoingNotifications: React.FC<{
  notifications: Array<EntityModel<Notification>>;
}> = ({ notifications }) => {
  const unseenNotifications = getNotificationsToSee(notifications);
  return (
    <>
      {notifications && notifications.length > 0 ? (
        notifications.map((notification) => (
          <Dropdown.Item
            key={notification.id}
            onClick={() => showToast([notification])}
          >
            <div className="NotificationRow">
              <div>
                {notification.level === NotificationLevel.INFO ? (
                  <InfoIcon />
                ) : (
                  <WarningIcon style={{ color: "var(--bs-warning)" }} />
                )}
              </div>
              <div>
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <strong className="NotificationTextHolder">
                    {notification.name}
                  </strong>
                  {unseenNotifications.includes(notification) && (
                    <span
                      className="red-dot"
                      style={{
                        top: "10%",
                        left: `${notification.name.length > 40 ? 95 : 100}%`,
                        marginLeft: "3px",
                        height: "0.6rem",
                        width: "0.6rem",
                      }}
                    ></span>
                  )}
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <OfficeIcon style={{ marginRight: "0" }} />
                  {notification?.offices && notification.offices.length > 0 ? (
                    <>
                      {notification.offices
                        .map((office) => office.name)
                        .join(", ")}
                    </>
                  ) : (
                    <FormattedMessage
                      id="notification.card.all.message"
                      defaultMessage="All"
                    />
                  )}
                </div>
              </div>
            </div>
          </Dropdown.Item>
        ))
      ) : (
        <div className="NotificationMessage">
          <FormattedMessage
            id="notification.noNotifications.message"
            defaultMessage="No notifications to show."
          />
        </div>
      )}
    </>
  );
};

export default Profile;
