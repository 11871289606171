import {
  hasDeviceRegistration,
  removeDeviceRegistration,
} from "app/admin/devices/DeviceStorage";
import BaseCollecionLinks from "app/models/BaseCollectionLinks";
import PagedData from "app/models/PagedData";
import Reservation from "app/models/Reservation";
import User from "app/models/User";
import UserSearchCriteria from "app/models/UserSearchCriteria";
import UsersStatistics from "app/models/UsersStatistics";
import { apiFetch, apiFetchWithReviver } from "AppConfig";
import { CollectionModel, EntityModel, PagedModel } from "hateoas-hal-types";
import useSwr from "swr";
import useSWRInfinite from "swr/infinite";

import CreateUser from "app/models/CreateUser";
import PasswordChangeModificationRequest from "app/models/PasswordChangeModificationRequest";
import PasswordChangeRequest from "app/models/PasswordChangeRequest";
import { default as PasswordChangeRequestRequest } from "app/models/PasswordChangeRequestRequest";
import UpdateUser from "app/models/UpdateUser";
import UserBatchImport from "app/models/UserBatchImport";
import fetchClient, { stringifyUrl } from "app/utils/fetch-client";
import { laggy } from "app/utils/laggy";
import { usePagedData } from "app/utils/PagedDataUtils";

const basePath = "/api/v1/users";
const basePathPasswordReset = "/api/v1/password-change-requests";

export function reloadCheckins(
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return mutate(new RegExp(basePath + "/\\d+/checkins"));
}

export function useUsersByCriteria(filter: UserSearchCriteria = {}) {
  // const query = new URLSearchParams(filter as any).toString();
  const { data, error } = useSwr<CollectionModel<User>>(
    stringifyUrl(basePath, filter)
  );
  const users = data?._embedded?.users;
  const exportedLinks = data?._links as BaseCollecionLinks;
  return { data: users, links: exportedLinks, error };
}

export const fetchUsers = (
  filter: UserSearchCriteria = {}
): Promise<CollectionModel<User>> => {
  return fetchClient.get(stringifyUrl(basePath, filter));
};

export function usePagedUsersForInfiniteScrolling(
  filter: UserSearchCriteria = {}
) {
  return useSWRInfinite<PagedModel<User>>(
    (page, previousPageData) => {
      if (previousPageData && !previousPageData._embedded) {
        return null;
      }
      return stringifyUrl(basePath, { ...filter, page });
    },
    apiFetch,
    { use: [laggy] }
  );
}

export function usePagedUsers(
  filter: UserSearchCriteria = {}
): PagedData<User> {
  return usePagedData(
    (c, page) => stringifyUrl(basePath, { ...c, page }),
    filter,
    (c) => c.size,
    "users"
  );
}

export function useUserStatistics() {
  return useSwr<UsersStatistics>(`${basePath}/statistics`);
}

export default function useCurrentUser() {
  const response = useSwr<EntityModel<User>>(`${basePath}/current`);
  const goToLobby = () => {
    if (window.location.href.indexOf("/lobby") === -1) {
      window.location.href = "/lobby";
    }
  };

  if (
    response.error?.status === 401 &&
    window.location.href.indexOf("/devices/register") === -1 &&
    window.location.href.indexOf("/password-change-requests") === -1
  ) {
    if (hasDeviceRegistration()) {
      removeDeviceRegistration();
    }
    goToLobby();
  }
  return response;
}

export function generateAllUsersByOfficeEndpoint(officeId: string) {
  return `${basePath}?officeId=${officeId}`;
}

export function logout() {
  window.location.href = "/logout";
}
export const updateUser = (
  url: string,
  user: UpdateUser,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> =>
  fetchClient
    .patch(url, {
      body: JSON.stringify(user),
    })
    .then(() => reloadUsers(mutate));

export const batchImportUsers = (user: UserBatchImport): Promise<any[]> =>
  fetchClient.patch(`${basePath}`, {
    body: JSON.stringify(user),
  });

export function useUserCheckins(userId?: string) {
  const { data, error } = useSwr<CollectionModel<Reservation>>(
    userId ? `${basePath}/${userId}/checkins` : null,
    apiFetchWithReviver,
    {
      refreshInterval: 5 * 60 * 1000,
    }
  );
  const reservations = data?._embedded?.reservations;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return { data: reservations, links: extractedLinks, error };
}

export function usePasswordChangeRequestId(passwordChangeRequestId?: string) {
  const { data, error } = useSwr<EntityModel<PasswordChangeRequest>>(
    passwordChangeRequestId
      ? `${basePathPasswordReset}/${passwordChangeRequestId}`
      : null,
    { refreshInterval: 5000 }
  );
  const id = data?.id;
  return { data: id, error };
}

export function reloadUsers(
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return mutate(new RegExp(basePath));
}

export function createUser(user: CreateUser): Promise<User> {
  return fetchClient.post(basePath, {
    body: JSON.stringify(user),
  });
}

export const selfUpdate = (
  user: UpdateUser,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> =>
  fetchClient
    .put(`${basePath}/current`, {
      body: JSON.stringify(user),
    })
    .then(() => reloadUsers(mutate))
    .then(() => mutate(new RegExp(`${basePath}/current`)));

export const updateUserActive = (
  user: EntityModel<User>,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) => {
  return fetchClient
    .put(user._links.active.href, {
      body: JSON.stringify(!user.active),
    })
    .then(() => reloadUsers(mutate));
};

export const passwordChangeRequest = (
  userDetails: PasswordChangeRequestRequest
): Promise<void> => {
  return fetchClient.put(`${basePathPasswordReset}`, {
    body: JSON.stringify(userDetails),
  });
};

export const updatePasswordReset = (
  userDetails: PasswordChangeModificationRequest,
  passwordChangeRequestId?: string
): Promise<any[]> =>
  fetchClient.patch(`${basePathPasswordReset}/${passwordChangeRequestId}`, {
    body: JSON.stringify(userDetails),
  });
