interface AuditFilter {
  email?: string;
  entityName?: string;
  reason?: string;
  period?: { from?: Date; to?: Date };
  page: number;
  size: number;
}

export function convertFilterIntoCriteria(filter: AuditFilter) {
  return {
    email: filter?.email,
    entityName: filter?.entityName,
    reason: filter?.reason,
    start: filter?.period?.from,
    end: filter?.period?.to,
    page: filter.page,
    size: filter.size,
  };
}

export default AuditFilter;
