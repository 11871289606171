import React from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

const SpinnerButton: React.FC<
  {
    showSpinner?: boolean;
    variant?: string;
    spinnerVariant?: string;
    children: React.ReactNode;
  } & ButtonProps
> = ({ disabled, children, ...rest }) => {
  const defaultProps = {
    showSpinner: false,
    variant: "primary",
    spinnerVariant: "light",
  };
  const { showSpinner, spinnerVariant, ...restProps } = Object.assign(
    defaultProps,
    rest
  );

  return (
    <Button {...restProps} disabled={showSpinner || disabled}>
      {showSpinner ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner
            className="me-1"
            animation="border"
            variant={spinnerVariant}
            size="sm"
          />
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </Button>
  );
};

export default SpinnerButton;
