/**
 * @desc make csv from given data
 * @param rows
 * @param filename
 */
export const makeCsv = (headers: any[], rows: any[][], filename: string) => {
  const separator: string = ",";

  const csvContent = `${headers.join(separator)}\n${rows
    .map((row) =>
      row
        .map((cell) => {
          cell = cell === null || cell === undefined ? "" : cell;

          cell =
            cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');

          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator)
    )
    .join("\n")}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.log("Your browser does not support the HTML5 download attribute.");
  }
};
