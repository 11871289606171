import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SpinnerButton from "./SpinnerButton";

const ConfirmationDialog: React.FC<{
  show: boolean;
  message: JSX.Element;
  onConfirm?: () => Promise<any[]> | Promise<void>;
  voidOnConfirm?: () => void;
  confirmMessage?: JSX.Element;
  onCancel: () => void;
  cancelMessage?: JSX.Element;
}> = ({
  show,
  message,
  onConfirm,
  voidOnConfirm,
  confirmMessage,
  onCancel,
  cancelMessage,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = () => {
    setSubmitting(true);

    if (onConfirm) {
    onConfirm().finally(() => setSubmitting(false));
    }

    if (voidOnConfirm) {
      voidOnConfirm();
      setSubmitting(false);
    }
  };

  return (
    <Modal show={show} centered>
      {message && (
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
      )}
      <Modal.Footer>
        <SpinnerButton
          showSpinner={submitting}
          variant="danger"
          onClick={() => onSubmit()}
        >
          {confirmMessage && <span>{confirmMessage}</span>}
          {!confirmMessage && (
            <FormattedMessage
              id="generic.confirmationDialog.default.confirm"
              defaultMessage="Yes"
            />
          )}
        </SpinnerButton>
        <SpinnerButton variant="secondary" onClick={() => onCancel()}>
          {cancelMessage && <span>{cancelMessage}</span>}
          {!confirmMessage && (
            <FormattedMessage
              id="generic.confirmationDialog.default.notConfirm"
              defaultMessage="No"
            />
          )}
        </SpinnerButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
