interface NotificationFilter {
  name?: string;
  period?: {
    from: Date;
    to: Date;
  };
  officeId?: string;
}

export default NotificationFilter;

export const convertToSearchCriteria = (filter: NotificationFilter) => {
  return {
    name: filter?.name,
    start: filter?.period?.from,
    end: filter?.period?.to,
    officeId: filter?.officeId,
  };
};
