import { Divider } from "@mui/material";
import { useBrandingInfo } from "app/api/ApplicationBrandingApi";
import { XmasThemeContext } from "components/XmasThemeContext";
import { useContext, useMemo } from "react";
import { Button, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuthProviders } from "./api/GlobalConfigApi";
import AzureLogo from "./assets/images/azure_logo.png";
import DeskBuddyLogo from "./assets/images/desk_buddy_logo.png";
import GoogleLogo from "./assets/images/google_logo.jpeg";
import OktaLogo from "./assets/images/okta_logo.png";
import SlackLogo from "./assets/images/slack_logo.png";
import HeaderAndContent from "./HeaderAndContent";
import "./Lobby.css";
import AuthProvider from "./models/AuthProvider";
import SupportedManagements from "./models/SupportedManagement";
import {
  getStoredLoginOption,
  setStoredLoginOption,
} from "./utils/loginStorageUtil";
import useTeams from "./utils/useTeams";

const Lobby = () => {
  const { data: brandingInfo } = useBrandingInfo();
  const { data: authProviders } = useAuthProviders();

  const preselectedSignin = useMemo(
    () =>
      authProviders && authProviders.length > 1
        ? getStoredLoginOption(authProviders)
        : authProviders?.[0],
    [authProviders]
  );

  const intl = useIntl();
  const teams = useTeams();

  const handleSignin = (authProvider: AuthProvider) =>
    authProvider.url.includes("/oauth2/authorization/") && teams.context
      ? teams.instance.initialize(() =>
          teams.instance.authentication.authenticate({
            url: authProvider.url,
            width: 600,
            height: 600,
            successCallback: () => (window.location.href = "/my-bookings"),
            failureCallback: (reason) => console.log(reason),
          })
        )
      : (window.location.href = authProvider.url);

  const { isXmasTheme, toggleEffects } = useContext(XmasThemeContext);

  return brandingInfo ? (
    <HeaderAndContent>
      <HeaderAndContent.Header>
        <Helmet
          title={
            brandingInfo.applicationName
              ? brandingInfo.applicationName
              : "Desk Buddy"
          }
        />
      </HeaderAndContent.Header>
      <HeaderAndContent.Content>
        <div
          className="Lobby d-flex flex-column align-items-center justify-content-center text-center"
          id="Lobby"
        >
          <div className="Lobby-Text mb-5 mt-5">
            {brandingInfo?._links.lobbyNamePicture?.href ? (
              <Image
                src={brandingInfo._links.lobbyNamePicture.href}
                alt="Lobby name picture"
                width="95%"
              />
            ) : (
              <h1>
                <span className="focus">
                  {brandingInfo.lobbyName
                    ? intl.formatMessage(
                        {
                          id: "Lobby.clientMessage",
                          defaultMessage: "Welcome to {lobbyName}",
                        },
                        { lobbyName: brandingInfo.lobbyName }
                      )
                    : intl.formatMessage({
                        id: "Lobby.welcome",
                        defaultMessage: "Welcome to Desk Buddy",
                      })}
                </span>
              </h1>
            )}
          </div>
          <div className="Lobby-Logo mb-5">
            <Image
              src={brandingInfo._links.lobbyPicture?.href}
              alt="Desk buddy robot"
              className="Lobby-Logo-Picture"
              width="50%"
              // style={{ cursor: isXmasTheme ? "pointer" : "" }}
              onClick={() => isXmasTheme && toggleEffects()}
            />
          </div>
          <div className="Lobby-SignIn mb-5">
            <Row>
              {preselectedSignin && (
                <Button
                  onClick={() => handleSignin(preselectedSignin)}
                  className="Lobby-SignIn-Button"
                >
                  <FormattedMessage
                    id="Lobby-SignIn-Button"
                    defaultMessage="Sign in"
                  />
                </Button>
              )}
            </Row>
            {authProviders && authProviders.length > 1 && (
              <>
                {preselectedSignin && (
                  <Divider style={{ margin: "1rem 0 1rem 0" }}>
                    <FormattedMessage
                      id="Lobby.signin.or"
                      defaultMessage="or"
                    />
                  </Divider>
                )}
                {authProviders
                  .filter((provider) => provider.id !== preselectedSignin?.id)
                  .map((provider, i) => (
                    <Row key={i}>
                      <Button
                        className="LoginSelect"
                        onClick={() => {
                          setStoredLoginOption(provider);
                          handleSignin(provider);
                        }}
                      >
                        <Image
                          src={
                            provider.type === SupportedManagements.LOCAL
                              ? DeskBuddyLogo
                              : provider.type === SupportedManagements.AZURE
                              ? AzureLogo
                              : provider.type === SupportedManagements.GOOGLE
                              ? GoogleLogo
                              : provider.type === SupportedManagements.OKTA
                              ? OktaLogo
                              : provider.type === SupportedManagements.SLACK
                              ? SlackLogo
                              : undefined
                          }
                          alt="Logo"
                        />
                        <span>
                          <FormattedMessage
                            id="Lobby-SignIn-Options-SignWith"
                            defaultMessage="Sign in with "
                          />
                          {provider.name}
                        </span>
                      </Button>
                    </Row>
                  ))}
              </>
            )}
          </div>
        </div>
      </HeaderAndContent.Content>
    </HeaderAndContent>
  ) : null;
};

export default Lobby;
