import { Skeleton } from "@mui/material";
import {
  deleteNotification,
  updateNotification,
  updateNotificationActive,
} from "app/api/NotificationApi";
import { ReactComponent as AdminNotificationIcon } from "app/icons/v2/admin_notifications.svg";
import { ReactComponent as OfficeIcon } from "app/icons/v2/admin_offices.svg";
import { ReactComponent as FaCalendar } from "app/icons/v2/fa-calendar.svg";
import { ReactComponent as FaPenToSquare } from "app/icons/v2/fa-pen-to-square.svg";
import { ReactComponent as FaTrashCan } from "app/icons/v2/fa-trash-can.svg";
import { ReactComponent as InfoIcon } from "app/icons/v2/icn-info.svg";
import { ReactComponent as WarningIcon } from "app/icons/v2/icn-warning.svg";
import CreateNotification from "app/models/CreateNotification";
import Notification from "app/models/Notification";
import NotificationLevel from "app/models/NotificationLevel";
import UpdateNotification from "app/models/UpdateNotification";
import useMatchMutate from "app/utils/useMatchMutate";
import ConfirmationDialog from "components/ConfirmationDialog";
import IconButtonV2 from "components/IconButtonV2";
import { EntityModel } from "hateoas-hal-types";
import { useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FormattedDateTimeRange, FormattedMessage, useIntl } from "react-intl";
import CreateEditNotificationModal from "./CreateEditNotificationModal";
import "./NotificationInfoCard.css";

const NotificationInfoCard: React.FC<{
  notification: EntityModel<Notification>;
}> = ({ notification }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>();
  const [showEditModal, setShowEditModal] = useState<boolean>();
  const mutate = useMatchMutate();
  const intl = useIntl();

  const handleUpdate = async (
    requestBody: CreateNotification | UpdateNotification
  ) => {
    await updateNotification(notification, requestBody, mutate)
      .then(() =>
        toast.success(
          intl.formatMessage({
            id: "notification.card.update.success",
            defaultMessage: "Notification updated successfully",
          })
        )
      )
      .catch((e) => {
        console.log(e);
        toast.error(
          intl.formatMessage({
            id: "notification.card.update.error",
            defaultMessage: "Error occurred during updating notification",
          })
        );
      })
      .finally(() => setShowEditModal(false));
  };

  const handleUpdateActive = async (
    notification: EntityModel<Notification>
  ) => {
    await updateNotificationActive(notification, mutate)
      .then(() =>
        toast.success(
          intl.formatMessage({
            id: "notification.card.update.success",
            defaultMessage: "Notification updated successfully",
          })
        )
      )
      .catch((e) => {
        console.log(e);
        toast.error(
          intl.formatMessage({
            id: "notification.card.update.error",
            defaultMessage: "Error occurred during updating notification",
          })
        );
      });
  };

  return (
    <Col className="mb-4">
      <Card className="h-100">
        <div className="NotificationInfoCard-ImageSkeleton">
          {notification.active ? (
            notification.level === NotificationLevel.INFO ? (
              <InfoIcon />
            ) : (
              <WarningIcon />
            )
          ) : (
            <AdminNotificationIcon />
          )}
        </div>
        <Card.Body style={{ paddingBottom: "5px" }}>
          <Card.Title>
            {notification.name || <Skeleton width="5rem" />}
          </Card.Title>
          <Card.Text as="div">
            <div className="d-flex align-items-top">
              <OfficeIcon
                className="me-1 NotificationInfoCard-Icons"
                width="1.2rem"
                height="1.2rem"
              />
              {notification?.offices && notification.offices.length > 0 ? (
                <>
                  {notification.offices.map((office) => office.name).join(", ")}
                </>
              ) : (
                intl.formatMessage({
                  id: "notification.card.all.message",
                  defaultMessage: "All",
                })
              )}
            </div>
            <div className="d-flex align-items-top">
              <FaCalendar
                className="me-1 NotificationInfoCard-Icons"
                width="1.2rem"
                height="1.2rem"
              />
              <FormattedDateTimeRange
                from={notification.startTime}
                to={notification.endTime}
                timeStyle="short"
                dateStyle="medium"
              />
            </div>
          </Card.Text>
        </Card.Body>

        <Card.Footer className="NotificationInfoCard-Buttons">
          <div className="d-flex justify-content-end">
            {notification?._links?.update && (
              <>
                <Form.Check
                  type="switch"
                  checked={notification.active}
                  label={intl.formatMessage({
                    id: "notification.modal.form.active",
                    defaultMessage: "Active",
                  })}
                  onChange={() => handleUpdateActive(notification)}
                />
                <IconButtonV2
                  tooltipId={`notification-tooltip-edit-${notification.id}`}
                  tooltip={intl.formatMessage({
                    id: "notification.card.update",
                    defaultMessage: "Update notification",
                  })}
                  icon={FaPenToSquare}
                  onClick={() => {
                    setShowEditModal(true);
                  }}
                />
              </>
            )}

            {notification?._links?.delete && (
              <IconButtonV2
                tooltipId={`notification-tooltip-delete-${notification.id}`}
                tooltip={intl.formatMessage({
                  id: "notification.card.delete",
                  defaultMessage: "Delete notification",
                })}
                variant="outline-danger"
                icon={FaTrashCan}
                onClick={() => {
                  setShowDeleteModal(true);
                }}
              />
            )}
          </div>
        </Card.Footer>
      </Card>
      {showEditModal && (
        <CreateEditNotificationModal
          notification={notification}
          handleSubmit={handleUpdate}
          handleCloseModal={() => setShowEditModal(false)}
        />
      )}
      {showDeleteModal && (
        <DeleteNotificationModal
          notification={notification}
          handleCloseModal={() => setShowDeleteModal(false)}
        />
      )}
    </Col>
  );
};

const DeleteNotificationModal: React.FC<{
  notification: EntityModel<Notification>;
  handleCloseModal: () => void;
}> = ({ notification, handleCloseModal }) => {
  const matchMutate = useMatchMutate();
  const intl = useIntl();

  const handleDeleteNotification = async () => {
    await deleteNotification(notification, matchMutate)
      .then(() =>
        toast.success(
          intl.formatMessage({
            id: "notification.card.delete.success",
            defaultMessage: "Notification deleted successfully",
          })
        )
      )
      .catch((e) => {
        console.log(e);
        toast.error(
          intl.formatMessage({
            id: "notification.card.delete.error",
            defaultMessage: "An error occurred during deleting notification",
          })
        );
      })
      .finally(() => handleCloseModal());
  };

  return (
    <ConfirmationDialog
      show={true}
      message={
        <FormattedMessage
          id="notification.card.delete.approval"
          defaultMessage="Are you sure you want to delete the notification <b>{notificationName}</b>"
          values={{
            notificationName: notification.name,
            b: (chunks: any) => <b>{chunks}</b>,
          }}
        />
      }
      onConfirm={handleDeleteNotification}
      onCancel={handleCloseModal}
    />
  );
};

export default NotificationInfoCard;
