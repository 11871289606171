import { useGroupsByCriteria } from "app/api/GroupApi";
import { useOfficesByCriteria } from "app/api/OfficeApi";
import { getFloorResources } from "app/api/ResourceApi";
import useCurrentUser, { useUsersByCriteria } from "app/api/UserApi";
import { convertFilters, ReportFilters } from "app/models/ReportFilters";
import ReportingCancelStatus from "app/models/ReportingCancelStatus";
import ReportingCheckInStatus from "app/models/ReportingCheckInStatus";
import ReservationPeriod from "app/models/ReservationPeriod";
import Resource from "app/models/Resource";
import ResourceType, { formatResourceType } from "app/models/ResourceType";
import { guestOption } from "app/models/User";
import { useRequireAccess } from "app/RequiresAccess";
import { stringifyUrl } from "app/utils/fetch-client";
import SpinnerButton from "components/SpinnerButton";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import "./ReportFilters.css";
import ReportTimeToggle from "./ReportTimeToggle";

const ReportFiltersPanel: React.FC<{
  hasOffice?: boolean;
  clearOffice?: boolean;
  hasFloor?: boolean;
  hasResourceId?: boolean;
  hasResourceType?: boolean;
  hasGroup?: boolean;
  clearGroup?: boolean;
  clearResource?: boolean;
  hasPeriod?: boolean;
  isCurrentAndFuture?: boolean;
  hasUser?: boolean;
  hasGuest?: boolean;
  hasCancelled?: boolean;
  cancelledStatus?: Array<ReportingCancelStatus>;
  hasCheckinStatus?: boolean;
  checkinStatus?: Array<ReportingCheckInStatus>;
  timePeriod?: Array<ReservationPeriod>;
  isSingleDay?: boolean;
  isSingleMonth?: boolean;
  isSingleYear?: boolean;
  hasToCSV?: boolean;
  hasToGraph?: boolean;
  showDeleted?: boolean;
  onlyOperatedOffices?: boolean;
}> = ({
  hasOffice = true,
  clearOffice = false,
  hasFloor = true,
  hasResourceId = false,
  hasResourceType = true,
  clearResource = false,
  hasGroup = true,
  clearGroup = true,
  hasPeriod = true,
  isCurrentAndFuture = false,
  hasUser = false,
  hasGuest = false,
  hasCancelled = true,
  cancelledStatus = Object.values(ReportingCancelStatus),
  hasCheckinStatus = true,
  checkinStatus = Object.values(ReportingCheckInStatus),
  timePeriod = [
    ReservationPeriod.ONE_MONTH,
    ReservationPeriod.THREE_MONTHS,
    ReservationPeriod.SELECT,
  ],
  isSingleDay = false,
  isSingleMonth = false,
  isSingleYear = false,
  hasToCSV = true,
  hasToGraph = true,
  showDeleted = false,
  onlyOperatedOffices = false,
}) => {
  const intl = useIntl();
  const isOfficeOperator = useRequireAccess("/admin/notifications");

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
    setValue,
  } = useFormContext<ReportFilters>();

  const { data: offices } = useOfficesByCriteria({
    active: true,
    ...(isOfficeOperator && onlyOperatedOffices && { onlyOperated: true }),
    showDeleted,
  });
  const officeId = watch("officeId");
  const floorId = watch("floorId");
  const resourceType = watch("resourceType");
  const groupId = watch("groupId");
  const isGuest = watch("guest");

  const floors = offices
    ?.find((office) => office.id === officeId)
    ?.floors.filter((floor) => floor.active);
  const [resourceNames, setResourceNames] = useState<Array<Resource>>([]);

  useEffect(() => {
    if (floorId) {
      const fetchResources = async () => {
        await getFloorResources({ floorId, resourceType, showDeleted })
          .then((response) =>
            setResourceNames(response?._embedded?.resources || [])
          )
          .catch((e) => console.log(e));
      };
      fetchResources();
    }
  }, [floorId, resourceType, showDeleted]);

  const resources = Object.values(ResourceType);
  const resourceOptions = resources.map((resource) => {
    return {
      value: resource,
      label: formatResourceType(resource, intl),
    };
  });
  const cancelledStatusOptions = cancelledStatus.map((status) => ({
    value: status,
    label: intl.formatMessage({
      id: `reports.filters.panel.label.${status
        ?.toLowerCase()
        ?.replaceAll("_", ".")}`,
      defaultMessage: status,
    }),
  }));
  const checkinStatusOptions = checkinStatus.map((status) => ({
    value: status,
    label: intl.formatMessage({
      id: `reports.filters.panel.label.${status
        ?.toLowerCase()
        ?.replaceAll("_", ".")}`,
      defaultMessage: status,
    }),
  }));

  const { data: currentUser } = useCurrentUser();
  const { data: groups } = useGroupsByCriteria();
  const { data: users } = useUsersByCriteria({
    ...(!isOfficeOperator && { managedById: currentUser?.id }),
    officeId: officeId,
    memberOfId: groupId,
  });

  const extractCSV = (filters: ReportFilters) => {
    const csvFilters = convertFilters(filters);
    window.location.href = stringifyUrl("/api/v1/reports?", {
      ...csvFilters,
      toJson: false,
    });
  };

  return (
    <Form
      className="ReportForm"
      autoComplete="off"
      onSubmit={handleSubmit(extractCSV)}
    >
      {hasResourceType && (
        <div className="ReportFilters-Resource">
          <Controller
            control={control}
            name="resourceType"
            render={({ field: { value, onChange } }) => (
              <Select
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder={intl.formatMessage({
                  id: "reportFilters.label.resources.type.placeholder",
                  defaultMessage: "Select a resource type",
                })}
                onChange={(option) => {
                  onChange(option?.value);
                }}
                value={
                  value
                    ? { value: value, label: formatResourceType(value, intl) }
                    : null
                }
                name="resource"
                options={resourceOptions}
                isClearable={clearResource}
              />
            )}
          />
        </div>
      )}
      {hasOffice && (
        <div className="ReportFilters-Office">
          <Controller
            control={control}
            name="officeId"
            render={({ field: { value, onChange } }) => (
              <Select
                menuPlacement="auto"
                placeholder={
                  <FormattedMessage
                    id="officeDropdown.allOffices"
                    defaultMessage="All Offices"
                  />
                }
                options={offices || []}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id + ""}
                onChange={(value) => {
                  onChange(value?.id || undefined);
                  setValue("floorId", undefined);
                }}
                value={
                  offices?.length === 1
                    ? offices[0]
                    : offices?.find((office) => office.id === value) || null
                }
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  option: (base, { data }) => ({
                    ...base,
                    opacity: data.deleted ? 0.5 : 1,
                  }),
                }}
                isClearable={clearOffice}
              />
            )}
          />
        </div>
      )}
      {hasFloor && (
        <div className="ReportFilters-Floor">
          <Controller
            control={control}
            name="floorId"
            render={({ field: { value, onChange } }) => (
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  option: (base, { data }) => ({
                    ...base,
                    opacity: data.deleted ? 0.5 : 1,
                  }),
                }}
                placeholder={intl.formatMessage({
                  id: "reportFilters.label.floors.placeholder",
                  defaultMessage: "Select a floor",
                })}
                options={floors?.sort((f1, f2) =>
                  f1.name.localeCompare(f2.name)
                )}
                value={floors?.find((floor) => floor.id === value) || null}
                menuPlacement="auto"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id + ""}
                onChange={(floor) => {
                  onChange(floor?.id || null);
                  setValue("resourceId", undefined);
                }}
                isDisabled={!officeId}
                isClearable
              />
            )}
          />
        </div>
      )}
      {hasResourceId && (
        <div className="ReportFilters-ResourceId">
          <Controller
            control={control}
            name="resourceId"
            render={({ field: { value, onChange } }) => (
              <Select
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  option: (base, { data }) => ({
                    ...base,
                    opacity: data.deleted ? 0.5 : 1,
                  }),
                }}
                placeholder={intl.formatMessage({
                  id: "reportFilters.label.resources.placeholder",
                  defaultMessage: "Select a resource",
                })}
                options={resourceNames?.sort((r1, r2) =>
                  r1.name.localeCompare(r2.name)
                )}
                value={
                  resourceNames?.find((resource) => resource.id === value) ||
                  null
                }
                menuPlacement="auto"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id + ""}
                onChange={(resource) => onChange(resource?.id || null)}
                isDisabled={!floorId}
                isClearable
              />
            )}
          />
        </div>
      )}
      {hasGroup && (
        <div className="ReportFilters-Group">
          <Controller
            control={control}
            name="groupId"
            render={({ field: { value, onChange } }) => (
              <Select
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder={intl.formatMessage({
                  id: "reportFilters.label.group.placeholder",
                  defaultMessage: "Select a group",
                })}
                options={[...(groups || [])].sort((f1, f2) =>
                  f1.name.localeCompare(f2.name)
                )}
                value={
                  groups?.length === 1
                    ? groups[0]
                    : groups?.find((group) => group.id === value) || null
                }
                menuPlacement="auto"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id + ""}
                onChange={(value) => {
                  onChange(value?.id || null);
                  setValue("userId", undefined);
                }}
                isClearable={clearGroup}
              />
            )}
          />
        </div>
      )}
      {hasUser && (
        <div className="ReportFilters-Group">
          <Controller
            control={control}
            name="userId"
            render={({ field: { value, onChange } }) => (
              <Select
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder={intl.formatMessage({
                  id: "reportFilters.label.user.placeholder",
                  defaultMessage: "Select a user",
                })}
                options={hasGuest ? users && [guestOption, ...users] : users}
                menuPlacement="auto"
                value={
                  isGuest
                    ? guestOption
                    : users?.find((user) => user.id === value) || null
                }
                getOptionLabel={(option) =>
                  option.id !== guestOption.id
                    ? option.fullName
                    : intl.formatMessage({
                        id: "reservation.guest",
                        defaultMessage: "Guest",
                      })
                }
                getOptionValue={(option) => option.id + ""}
                onChange={(value) => {
                  if (value?.id === guestOption.id) {
                    setValue("guest", true);
                    return;
                  }
                  onChange(value?.id || null);
                  setValue("guest", undefined);
                }}
                isClearable
              />
            )}
          />
        </div>
      )}
      {hasCancelled && (
        <div className="ReportFilters-Cancelation">
          <Controller
            control={control}
            name="cancelledStatus"
            render={({ field: { onChange, value } }) => (
              <Select
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                defaultValue={cancelledStatusOptions[0]}
                options={cancelledStatusOptions}
                value={
                  value && {
                    value: value,
                    label: intl.formatMessage({
                      id: `reports.filters.panel.label.${value
                        ?.toLowerCase()
                        ?.replaceAll("_", ".")}`,
                      defaultMessage: value,
                    }),
                  }
                }
                onChange={(option) => option && onChange(option.value)}
              />
            )}
          />
        </div>
      )}
      {hasCheckinStatus && (
        <div className="ReportFilters-Checkin">
          <Controller
            control={control}
            name="checkinStatus"
            render={({ field: { onChange, value } }) => (
              <Select
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                defaultValue={checkinStatusOptions[0]}
                options={checkinStatusOptions}
                value={
                  value && {
                    value: value,
                    label: intl.formatMessage({
                      id: `reports.filters.panel.label.${value
                        ?.toLowerCase()
                        ?.replaceAll("_", ".")}`,
                      defaultMessage: value,
                    }),
                  }
                }
                onChange={(option) => option && onChange(option.value)}
              />
            )}
          />
        </div>
      )}
      {hasPeriod && (
        <div className="ReportFilters-Period">
          <Controller
            control={control}
            name="period"
            render={({ field: { onChange, value } }) => (
              <ReportTimeToggle
                onChange={onChange}
                period={value}
                labels={timePeriod}
                isSingleDay={isSingleDay}
                isSingleMonth={isSingleMonth}
                isSingleYear={isSingleYear}
                isCurrentAndFuture={isCurrentAndFuture}
              />
            )}
          />
        </div>
      )}
      {hasToCSV && (
        <div className="d-flex">
          <SpinnerButton
            type="submit"
            className="ReportFilters-ExportCSVButton"
            showSpinner={isSubmitting}
          >
            {isSubmitting ? (
              <FormattedMessage
                id="reports.spinner.extracting"
                defaultMessage="Extracting to CSV file..."
              />
            ) : (
              <FormattedMessage
                id="reportFilters.button.exportCSV"
                defaultMessage="Export to CSV"
              />
            )}
          </SpinnerButton>
          {hasToGraph && (
            <SpinnerButton
              type="button"
              className="ReportFilters-PreviewCSVButton"
              showSpinner={isSubmitting}
              onClick={(e) => {
                setValue("preview", !watch("preview"));
              }}
            >
              {watch("preview") ? (
                <FormattedMessage
                  id="reports.spinner.graph"
                  defaultMessage="View graph"
                />
              ) : isSubmitting ? (
                <FormattedMessage
                  id="reports.spinner.previewing"
                  defaultMessage="Loading data..."
                />
              ) : (
                <FormattedMessage
                  id="reportFilters.button.preview"
                  defaultMessage="Preview CSV"
                />
              )}
            </SpinnerButton>
          )}
        </div>
      )}
    </Form>
  );
};

export default ReportFiltersPanel;
