import { useEffect } from "react";
import { useBrandingInfo } from "./api/ApplicationBrandingApi";

function loadLink(elementId: string) {
  return document.getElementById(elementId) as HTMLLinkElement;
}

const useBranding = () => {
  const { data: brandingInfo } = useBrandingInfo();

  useEffect(() => {
    if (brandingInfo?._links.manifest?.href) {
      let manifest = loadLink("manifestTag")!;
      manifest.href = brandingInfo._links.manifest?.href;
    }
    if (brandingInfo) {
      let favicon = brandingInfo._links.favicon?.href;
      let appleFavicon = brandingInfo._links.touchIcon?.href;
      const handleFavicon = (
        url: string | undefined,
        elementId: string,
        fallbackLink: string
      ) => {
        const favicon = loadLink(elementId);
        if (url) {
          favicon.href = url;
        } else favicon.href = fallbackLink;
      };
      handleFavicon(favicon, "defaultFavicon", "/static/favicon.svg");
      handleFavicon(appleFavicon, "touchIcon", "/static/favicon.png");
    }
    if (brandingInfo && brandingInfo.applicationName) {
      document.title = brandingInfo.applicationName;
    } else if (brandingInfo && !brandingInfo.applicationName) {
      document.title = "Proxiad Desk Buddy";
    }
  }, [brandingInfo]);
};

export default useBranding;
