import BaseCollecionLinks from "app/models/BaseCollectionLinks";
import CreateOffice from "app/models/CreateOffice";
import Floor from "app/models/Floor";
import FloorSearchCriteria from "app/models/FloorSearchCriteria";
import Office from "app/models/Office";
import OfficeSearchCriteria from "app/models/OfficeSearchCriteria";
import UpdateOffice from "app/models/UpdateOffice";
import fetchClient, { stringifyUrl } from "app/utils/fetch-client";
import { apiFetch } from "AppConfig";
import { CollectionModel, EntityModel } from "hateoas-hal-types";
import useSWR, { mutate } from "swr";

const basePath = "/api/v1/offices";

export function useOffice(officeId?: string) {
  return useSWR<EntityModel<Office>>(
    officeId ? `${basePath}/${officeId}` : null
  );
}

export function useOfficesByCriteria(criteria: OfficeSearchCriteria = {}) {
  criteria = {
    ...criteria,
    accessible: criteria.accessible !== undefined ? criteria.accessible : true,
  };
  const { data, error } = useSWR<CollectionModel<Office>>(
    stringifyUrl(basePath, criteria)
  );
  const offices = data?._embedded?.offices;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return { data: offices, links: extractedLinks, error };
}

const buildGetAvailableFloorsUrl = (
  filter: FloorSearchCriteria,
  officeId?: string
) => {
  return officeId
    ? stringifyUrl(`${basePath}/${officeId}/floors`, filter)
    : null;
};

export function useOfficeFloors(
  filter: FloorSearchCriteria,
  officeId?: string
) {
  const { data, error } = useSWR<CollectionModel<Floor>>(
    buildGetAvailableFloorsUrl(filter, officeId),
    apiFetch
  );
  const floors = data?._embedded?.floors;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return {
    data: floors,
    links: extractedLinks,
    isLoading: !data && !error,
    error,
  };
}

export async function getAvailableFloors(
  filter: FloorSearchCriteria,
  officeId?: string
) {
  let response: CollectionModel<Floor> | undefined = undefined;
  if (officeId) {
    const url = buildGetAvailableFloorsUrl(filter, officeId)!;
    response = await mutate(
      url,
      (cached: any) => {
        return cached || fetchClient.get(url);
      },
      false
    );
  }
  return {
    data: response?._embedded?.floors,
    links: response?._links as BaseCollecionLinks,
  };
}

export function reloadOffices(
  matchMutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return matchMutate(new RegExp(basePath)).then(() =>
    mutate(
      stringifyUrl(basePath, { active: true, accessible: true }),

      fetchClient.get(
        stringifyUrl(basePath, { active: true, accessible: true })
      )
    )
  );
}

export function createOffice(
  url: string,
  office: CreateOffice,
  matchMutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> {
  return fetchClient
    .post(url, {
      body: JSON.stringify({ ...office }),
    })
    .then(() => reloadOffices(matchMutate));
}

export function updateOfficeActive(
  office: EntityModel<Office>,
  matchMutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> {
  return fetchClient
    .put(office._links.active.href, {
      body: JSON.stringify(!office.active),
    })
    .then(() => reloadOffices(matchMutate));
}

export function updateOffice(
  url: string,
  office: UpdateOffice | CreateOffice,
  matchMutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> {
  return fetchClient
    .put(url, {
      body: JSON.stringify({ ...office }),
    })
    .then(() => reloadOffices(matchMutate));
}

export function deleteOffice(
  url: string,
  matchMutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> {
  return fetchClient.delete(url).then(() => reloadOffices(matchMutate));
}
