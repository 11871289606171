import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useReports } from "app/api/ReportApi";
import { ReactComponent as ReservationCheckinIcon } from "app/icons/v2/res_checkin.svg";
import { convertFilters, ReportFilters } from "app/models/ReportFilters";
import { getStatusMessages } from "app/models/Reservation";
import User from "app/models/User";
import moment from "moment";
import { useMemo } from "react";
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

import "app/bookings/BookingsList.css";
import { makeCsv } from "app/utils/makeCsv";
import "./TeamPreviewMonthlyTable.css";

const TeamPreviewMonthlyTable: React.FC<{
  users?: Array<User>;
  page: number;
  setPage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  rowsPerPage: number;
  setRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  total: number;
}> = ({ users, page, setPage, rowsPerPage, setRowsPerPage, total }) => {
  const { watch } = useFormContext<ReportFilters>();
  const intl = useIntl();

  const csvFilter = convertFilters(watch());
  const { data } = useReports(csvFilter);

  const start = watch("period.from");
  const end = watch("period.to");

  const pageUsers = useMemo(
    () => users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, users]
  );

  const monthMaxDay = useMemo(
    () =>
      Array.from({ length: moment(end).diff(start, "days") + 1 }).map(
        (_, index) => index + 1
      ),
    [end, start]
  );

  const tableReservations = useMemo(() => {
    let tableReservations: Array<Array<Array<any> | undefined>> = [];

    pageUsers?.forEach((user, rowIndex) => {
      tableReservations[rowIndex] = [];

      monthMaxDay.forEach((day, index) => {
        const reservations = data?.filter(
          (record) =>
            record.Email === user.email &&
            record.Checked === "Yes" &&
            parseInt(record.Date.toString().split("/")[2], 10) === day
        );

        tableReservations[rowIndex][index] =
          reservations && reservations?.length > 0 ? reservations : undefined;
      });
    });

    return tableReservations;
  }, [data, monthMaxDay, pageUsers]);

  const totals = useMemo(() => {
    let totals: Array<Array<number>> = [];

    pageUsers?.forEach((_, rowIndex) => {
      totals[rowIndex] = [];
      monthMaxDay.forEach(
        (_, colIndex) =>
          (totals[rowIndex][colIndex] = tableReservations[rowIndex][colIndex]
            ? 1
            : 0)
      );
    });

    return totals;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableReservations]);

  const getPageBody = () => [
    ...(pageUsers?.map((user, rowIndex) => [
      user.fullName,
      totals[rowIndex]?.reduce((prev, curr) => prev + curr, 0),
      ...monthMaxDay.map((_, colIndex) =>
        tableReservations[rowIndex][colIndex] ? "X" : ""
      ),
    ]) || []),
    [
      "",
      "Totals",
      ...monthMaxDay.map((_, colIndex) =>
        totals?.reduce((prev, row) => prev + row[colIndex], 0)
      ),
    ],
  ];

  const getTeamBody = () => {
    const preBody = users?.map((user) =>
      monthMaxDay.map((day) =>
        data &&
        data?.filter(
          (record) =>
            record.Email === user.email &&
            record.Checked === "Yes" &&
            parseInt(record.Date.toString().split("/")[2], 10) === day
        ).length > 0
          ? (1 as number)
          : (0 as number)
      )
    );

    return [
      ...(users?.map((user, rowIndex) => [
        user.fullName,
        preBody?.[rowIndex]?.reduce((prev, curr) => prev + curr, 0) || 0,
        ...monthMaxDay.map((_, colIndex) =>
          preBody?.[rowIndex][colIndex] ? "X" : ""
        ),
      ]) || []),
      [
        "",
        intl.formatMessage({
          id: "reports.utilizationByTeam.total",
          defaultMessage: "Totals",
        }),
        ...monthMaxDay.map((_, colIndex) =>
          preBody?.reduce((prev, row) => prev + row[colIndex], 0)
        ),
      ],
    ];
  };

  return (
    <>
      {users && users.length > 0 ? (
        <TableContainer style={{ height: "100%" }}>
          <Table className="TeamMonthlyTable">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {monthMaxDay.map((day) => (
                  <TableCell key={day}>{day}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pageUsers?.map((user, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>{user.fullName}</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    {totals[rowIndex]?.reduce((prev, curr) => prev + curr, 0)}
                  </TableCell>
                  {monthMaxDay.map((day, index) => {
                    const colDate = new Date(
                      start?.getFullYear() || 0,
                      start?.getMonth() || 0,
                      day
                    );
                    const isWeekend =
                      colDate.getDay() === 0 || colDate.getDay() === 6;
                    return (
                      <TableCell
                        id={isWeekend ? "weekend" : undefined}
                        key={day}
                      >
                        <AdditionalInfo
                          records={tableReservations[rowIndex][index]}
                        >
                          <FormattedDate value={colDate} weekday="narrow" />
                        </AdditionalInfo>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
              <TableRow>
                <TableCell component="th"></TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  <FormattedMessage
                    id="reports.utilizationByTeam.total"
                    defaultMessage="Totals"
                  />
                </TableCell>
                {monthMaxDay.map((_, colIndex) => (
                  <TableCell key={colIndex} style={{ fontWeight: "bold" }}>
                    {totals?.reduce((prev, row) => prev + row[colIndex], 0)}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>
                  <DropdownButton
                    title={intl.formatMessage({
                      id: "reportFilters.button.exportCSV",
                      defaultMessage: "Export to CSV",
                    })}
                  >
                    <Dropdown.Item
                      onClick={() =>
                        makeCsv(
                          ["", "", ...monthMaxDay],
                          getPageBody(),
                          `${intl.formatMessage({
                            id: "reports.utilizationByTeam.monthlyTeamReport",
                            defaultMessage: "Monthly team report",
                          })} - ${new Date().toLocaleDateString()}`
                        )
                      }
                    >
                      <FormattedMessage
                        id="reports.utilizationByTeam.thisPage"
                        defaultMessage="This page"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        makeCsv(
                          ["", "", ...monthMaxDay],
                          getTeamBody(),
                          `${intl.formatMessage({
                            id: "reports.utilizationByTeam.monthlyTeamReport",
                            defaultMessage: "Monthly team report",
                          })} - ${new Date().toLocaleDateString()}`
                        )
                      }
                    >
                      <FormattedMessage
                        id="reports.utilizationByTeam.theWholeTeam"
                        defaultMessage="The whole team"
                      />
                    </Dropdown.Item>
                  </DropdownButton>
                </TableCell>
                <TablePagination
                  sx={{ "& > * > p ": { margin: "unset" } }}
                  count={total}
                  page={page}
                  onPageChange={setPage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={setRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <p style={{ margin: "auto" }}>
          <FormattedMessage
            id="report.teamTable.noUsersToDisplay"
            defaultMessage="There are no users to display"
          />
        </p>
      )}
    </>
  );
};

const AdditionalInfo: React.FC<{
  records?: Array<any>;
  children: React.ReactNode;
}> = ({ records, children }) => {
  const intl = useIntl();

  return (
    <>
      {records && records.length > 0 ? (
        <OverlayTrigger
          placement="auto"
          overlay={
            <Popover>
              <Popover.Header>
                <FormattedMessage
                  id="reports.utilizationByTeam.details"
                  defaultMessage="Details"
                />
              </Popover.Header>
              <Popover.Body>
                {records?.map((record) => (
                  <div className="AdditionalInfo-Container">
                    <ReservationCheckinIcon
                      className={`CheckedIcon ${
                        record.Score > 0 ? "Correct" : "Incorrect"
                      }`}
                    />
                    <div>
                      <ul>
                        {getStatusMessages(
                          record["Check-in Status"]?.split("; "),
                          intl
                        )?.map((message, index) => (
                          <li key={index}>{message}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </Popover.Body>
            </Popover>
          }
          trigger={["hover", "focus"]}
          rootClose
        >
          <div
            id={
              records.some((record) => record.Score > 0) ? "valid" : "invalid"
            }
          >
            {children}
          </div>
        </OverlayTrigger>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export default TeamPreviewMonthlyTable;
