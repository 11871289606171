import { Avatar } from "@mui/material";
import { useOfficesByCriteria } from "app/api/OfficeApi";
import useRoles from "app/api/RoleApi";
import { ReactComponent as IcnRandom } from "app/icons/v2/icn-random.svg";
import { ReactComponent as IcnReveal } from "app/icons/v2/icn-reveal.svg";
import CreateUser from "app/models/CreateUser";
import Role from "app/models/Role";
import UpdateUser from "app/models/UpdateUser";
import User from "app/models/User";
import ImageUploader from "app/utils/ImageUploader";
import { generatePass } from "app/utils/PassGeneratorUtil";
import { validatePass } from "app/utils/PassValidatorUtil";
import IconButtonV2 from "components/IconButtonV2";
import SpinnerButton from "components/SpinnerButton";
import { EntityModel } from "hateoas-hal-types";
import initials from "initials";
import { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormCheck,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {
  Controller,
  FormProvider,
  useForm,
  useFormState,
} from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import "./CreateEditUserModal.css";

const CreateEditUserModal: React.FC<{
  user?: EntityModel<User>;
  isSelfUpdate?: boolean;
  handleCloseModal: () => void;
  handleSubmit: (requestBody: CreateUser | UpdateUser) => Promise<void>;
}> = ({ user, isSelfUpdate, handleSubmit, handleCloseModal }) => {
  const isCreateMode = !user && !isSelfUpdate;
  const { data: roles } = useRoles();
  const internalRoles: EntityModel<Role>[] = roles?.filter(
    (role) => !role.internal
  )!;
  const { data: offices } = useOfficesByCriteria({ active: true });
  roles?.filter((role) => user?.roles.includes(role));
  const intl = useIntl();

  const form = useForm<CreateUser | UpdateUser>({
    defaultValues: user
      ? { ...user, roleIds: user.roles.map((role) => role.id) }
      : {
          roleIds: [],
        },
  });
  const {
    control,
    handleSubmit: formSubmit,
    register,
    setValue: setFormData,
    setError: setFormError,
    formState: { errors },
  } = form;
  const { isSubmitting } = useFormState({ control });
  const [editPassDisabled, setEditPassDisabled] = useState(!isCreateMode);
  const [showPass, setShowPass] = useState(false);

  const validatePassAndSubmit = (requestData: CreateUser | UpdateUser) => {
    if (
      !editPassDisabled &&
      requestData.password !== requestData.confirmPassword
    ) {
      setFormError("password", {
        type: "custom",
        message: intl.formatMessage({
          id: "addUserModal.passwordMissMatch",
          defaultMessage: "Passwords must match!",
        }),
      });
      setFormError("confirmPassword", {
        type: "custom",
        message: intl.formatMessage({
          id: "addUserModal.passwordMissMatch",
          defaultMessage: "Passwords must match!",
        }),
      });
      return;
    }
    const validationRes = validatePass(requestData.password, intl);
    if (!editPassDisabled && !validationRes.validationPassed) {
      setFormError("password", {
        type: "custom",
        message: validationRes.details[0].message,
      });
      return;
    }
    return handleSubmit(requestData);
  };

  return (
    <FormProvider {...form}>
      <Modal show={true} onHide={handleCloseModal} size="lg" centered>
        <Form autoComplete="off" onSubmit={formSubmit(validatePassAndSubmit)}>
          <Modal.Header>
            <Modal.Title>
              {isCreateMode ? (
                <FormattedMessage
                  id="AddUserForm.new.title"
                  defaultMessage="Create a new user"
                />
              ) : isSelfUpdate ? (
                <FormattedMessage
                  id="editProfile.title"
                  defaultMessage="Edit Profile"
                />
              ) : (
                <FormattedMessage
                  id="editUserModal.title"
                  defaultMessage="Edit User"
                />
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                {isCreateMode || !user?.external ? (
                  <Controller
                    control={control}
                    name="picture"
                    render={({ field: { onChange, value: picture } }) => (
                      <ImageUploader
                        picture={picture}
                        onChange={onChange}
                        imageUrl={user?._links?.avatar?.href}
                        isImgRemovable={true}
                      />
                    )}
                  />
                ) : (
                  <Avatar
                    sx={{ width: "160px", height: "160px", fontSize: "70px" }}
                    src={user?._links?.avatar.href}
                    variant="circular"
                  >
                    {initials(`${user.fullName}`)}
                  </Avatar>
                )}
              </Col>
              <Col md={8}>
                <Row xs={1} md={2}>
                  <Form.Group as={Col} controlId="formFirstName">
                    <Form.Label>
                      <FormattedMessage
                        id="AddUserForm.firstName"
                        defaultMessage="First Name"
                      />
                      &nbsp;
                      {!isSelfUpdate && <b className="text-danger">*</b>}
                    </Form.Label>
                    <Form.Control
                      {...register("firstName", {
                        required: intl.formatMessage({
                          id: "form.errors.required",
                          defaultMessage: "The field is required",
                        }),
                        pattern: {
                          value: /[A-Z][a-z]*/,
                          message: intl.formatMessage({
                            id: "AddUserForm.errors.nameCapitalFirst",
                            defaultMessage:
                              "Name must start with a capital letter",
                          }),
                        },
                      })}
                      readOnly={user?.external}
                      className={errors.firstName && "is-invalid"}
                    />
                    <span className="text-danger">
                      {errors.firstName?.message}
                    </span>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formLastName">
                    <Form.Label>
                      <FormattedMessage
                        id="AddUserForm.lastName"
                        defaultMessage="Last Name"
                      />
                      &nbsp;
                      {!isSelfUpdate && <b className="text-danger">*</b>}
                    </Form.Label>
                    <Form.Control
                      {...register("lastName", {
                        required: intl.formatMessage({
                          id: "form.errors.required",
                          defaultMessage: "The field is required",
                        }),
                        pattern: {
                          value: /[A-Z][a-z]*/,
                          message: intl.formatMessage({
                            id: "AddUserForm.errors.nameCapitalFirst",
                            defaultMessage:
                              "Name must start with a capital letter",
                          }),
                        },
                      })}
                      readOnly={user?.external}
                      className={errors.lastName && "is-invalid"}
                    />
                    <span className="text-danger">
                      {errors.lastName?.message}
                    </span>
                  </Form.Group>
                </Row>
                <Form.Group as={Row} controlId="formEmail" xs={1} md={2}>
                  {!isCreateMode && !isSelfUpdate && (
                    <Col>
                      <Form.Label>
                        <FormattedMessage
                          id="editUserModal.usernameLabel"
                          defaultMessage="Username"
                        />
                      </Form.Label>
                      <Form.Control
                        {...register("username")}
                        type="text"
                        readOnly
                      />
                    </Col>
                  )}
                  <Col>
                    <Form.Label>
                      <FormattedMessage
                        id="AddUserForm.email"
                        defaultMessage="Email"
                      />
                      &nbsp;
                      {!isSelfUpdate && <b className="text-danger">*</b>}
                    </Form.Label>
                    <Form.Control
                      {...register("email", {
                        required: intl.formatMessage({
                          id: "form.errors.required",
                          defaultMessage: "The field is required",
                        }),
                      })}
                      disabled={isSelfUpdate}
                      type={"email"}
                      readOnly={user?.external}
                      className={errors.email && "is-invalid"}
                    />
                  </Col>
                  <span className="text-danger">{errors.email?.message}</span>
                </Form.Group>
                {(isCreateMode || !user?.external) && (
                  <>
                    {!isCreateMode && (
                      <Row className="mt-2">
                        <Col>
                          <FormCheck
                            id="active"
                            type="switch"
                            label={intl.formatMessage({
                              id: "CreatEditUserModal.check.setPass",
                              defaultMessage: "Set password",
                            })}
                            onChange={({ target }) => {
                              setEditPassDisabled(!target.checked);
                            }}
                            style={{ width: "fit-content" }}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row xs={1} md={2}>
                      <Form.Group as={Col} controlId="formPassword">
                        <Form.Label>
                          <FormattedMessage
                            id="AddUserForm.password"
                            defaultMessage="Password"
                          />
                          &nbsp;
                          {!isSelfUpdate && <b className="text-danger">*</b>}
                        </Form.Label>
                        <Form.Control
                          {...register("password", {
                            required:
                              !editPassDisabled &&
                              intl.formatMessage({
                                id: "form.errors.required",
                                defaultMessage: "The field is required",
                              }),
                          })}
                          type={showPass ? "text" : "password"}
                          className={errors.password && "is-invalid"}
                          disabled={editPassDisabled}
                        />
                        <span className="text-danger">
                          {errors.password?.message}
                        </span>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formConfirmPassword">
                        <Form.Label>
                          <FormattedMessage
                            id="AddUserForm.confirmPassword"
                            defaultMessage="Confirm Password"
                          />
                          &nbsp;
                          {!isSelfUpdate && <b className="text-danger">*</b>}
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            {...register("confirmPassword", {
                              required:
                                !editPassDisabled &&
                                intl.formatMessage({
                                  id: "form.errors.required",
                                  defaultMessage: "The field is required",
                                }),
                            })}
                            type={showPass ? "text" : "password"}
                            className={errors.confirmPassword && "is-invalid"}
                            disabled={editPassDisabled}
                          />
                          {!editPassDisabled && (
                            <>
                              <IconButtonV2
                                tooltipId="generate-pass"
                                tooltip={
                                  <FormattedMessage
                                    id="CreateEditUserModal.generatePass"
                                    defaultMessage="Auto generate a password"
                                  />
                                }
                                icon={IcnRandom}
                                onClick={() => {
                                  const pass = generatePass();
                                  setFormData("password", pass);
                                  setFormData("confirmPassword", pass);
                                }}
                              />
                              <IconButtonV2
                                tooltipId="generate-pass"
                                tooltip={
                                  <FormattedMessage
                                    id="CreateEditUserModal.showPass"
                                    defaultMessage="Show / Hide password"
                                  />
                                }
                                icon={IcnReveal}
                                onClick={() => {
                                  setShowPass(!showPass);
                                }}
                              />
                            </>
                          )}
                        </InputGroup>
                        <span className="text-danger">
                          {errors.confirmPassword?.message}
                        </span>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Text className="text-muted">
                        <FormattedMessage
                          id="CreateEditUserModal.labels.pass.hint"
                          defaultMessage="Credentials will be automatically sent to user's email"
                        />
                      </Form.Text>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row className="mt-4" xs={1} md={2}>
              <Form.Group as={Col} controlId="formOfficeSelect">
                <Form.Label>
                  <FormattedMessage
                    id="AddUserForm.office"
                    defaultMessage="Office"
                  />
                  &nbsp;
                  {!isSelfUpdate && <b className="text-danger">*</b>}
                </Form.Label>
                <Controller
                  control={control}
                  name="officeId"
                  rules={{
                    required: intl.formatMessage({
                      id: "form.errors.required",
                      defaultMessage: "The field is required",
                    }),
                  }}
                  render={({ fieldState: { error } }) => (
                    <>
                      <Controller
                        control={control}
                        name="officeId"
                        render={({ field: { onChange } }) => (
                          <Select
                            defaultValue={offices?.filter(
                              (office) => office.id === user?.officeId
                            )}
                            menuPlacement="auto"
                            placeholder={
                              <FormattedMessage
                                id="deviceFilters.chooseOfficePlaceholder"
                                defaultMessage="Choose office"
                              />
                            }
                            options={offices || []}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id + ""}
                            onChange={(value) => {
                              onChange(value?.id || null);
                            }}
                            styles={{
                              control: (provided) =>
                                error
                                  ? {
                                      ...provided,
                                      borderColor: "red",
                                    }
                                  : provided,
                            }}
                          />
                        )}
                      />
                      <span className="text-danger">{error?.message}</span>
                    </>
                  )}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formRoleSelect">
                <Form.Label>
                  <FormattedMessage
                    id="AddUserForm.roles"
                    defaultMessage="Roles"
                  />
                  {!isSelfUpdate && (
                    <>
                      &nbsp;<b className="text-danger">*</b>
                    </>
                  )}
                </Form.Label>
                {!isSelfUpdate ? (
                  <>
                    <Controller
                      control={control}
                      name="roleIds"
                      rules={{
                        required: intl.formatMessage({
                          id: "form.errors.required",
                          defaultMessage: "The field is required",
                        }),
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Form.Group
                          as={Row}
                          className={`Roles ${error && "border-danger"}`}
                        >
                          {internalRoles &&
                            internalRoles.map((role) => {
                              return (
                                <Col>
                                  <FormCheck
                                    defaultChecked={user?.roles
                                      .map((subRole) => subRole.id)
                                      .includes(role.id)}
                                    type="switch"
                                    id={role.id}
                                    key={role.id}
                                    label={role.name}
                                    onChange={(e) => {
                                      e.target.checked
                                        ? onChange([...value, role.id])
                                        : onChange(
                                            value.filter((v) => v !== role.id)
                                          );
                                    }}
                                  />
                                </Col>
                              );
                            })}
                        </Form.Group>
                      )}
                    />
                    <span className="text-danger">
                      {errors.roleIds?.message}
                    </span>
                  </>
                ) : (
                  <Form.Group>
                    <h5>
                      {user?.roles.map((role) => (
                        <Badge className="me-1" pill>
                          {role.name}
                        </Badge>
                      ))}
                    </h5>
                  </Form.Group>
                )}
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {!isSelfUpdate && !user && (
              <FormCheck
                className="float-start"
                id="active"
                type="switch"
                label={intl.formatMessage({
                  id: "form.labels.active",
                  defaultMessage: "Active",
                })}
                {...register("active")}
              />
            )}

            <SpinnerButton
              type="submit"
              className="AddUserForm-ReserveButton"
              showSpinner={isSubmitting}
            >
              {isSubmitting ? (
                isCreateMode ? (
                  <FormattedMessage
                    id="AddUserForm.spinner.createUser"
                    defaultMessage="Adding user..."
                  />
                ) : (
                  <FormattedMessage
                    id="AddUserForm.spinner.updateUser"
                    defaultMessage="Updating user..."
                  />
                )
              ) : (
                <FormattedMessage
                  id="form.submitButton"
                  defaultMessage="Submit"
                />
              )}
            </SpinnerButton>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="form.cancelButton"
                defaultMessage="Cancel"
              />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </FormProvider>
  );
};

export default CreateEditUserModal;
