import { useGroupsByCriteria } from "app/api/GroupApi";
import { ReportFilters } from "app/models/ReportFilters";
import ReportingCancelStatus from "app/models/ReportingCancelStatus";
import ReportingCheckInStatus from "app/models/ReportingCheckInStatus";
import ReservationPeriod from "app/models/ReservationPeriod";
import ResourceType from "app/models/ResourceType";
import PaneAndContent from "app/PaneAndContent";
import ReportFiltersPanel from "app/reports/ReportFiltersPanel";
import { useRequireAccess } from "app/RequiresAccess";
import moment from "moment";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import TeamPreviewManagement from "./TeamPreviewManagement";

export enum ToggleOption {
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

const UtilizationByTeamReport = () => {
  const isOfficeOperator = useRequireAccess("/admin/notifications");
  const { data: groups } = useGroupsByCriteria();
  const [selectedOption, setSelectedOption] = useState<ToggleOption>(
    ToggleOption.WEEKLY
  );

  const methods = useForm<ReportFilters>({
    defaultValues: {
      resourceType: ResourceType.DESK,
      groupId: isOfficeOperator ? undefined : groups?.[0].id,
      cancelledStatus: ReportingCancelStatus.NOT_CANCELLED,
      checkinStatus: ReportingCheckInStatus.VALID_CHECKIN,
      period: {
        from: moment(new Date()).startOf("year").toDate(),
        to: moment(new Date()).endOf("year").toDate(),
      },
      toJson: true,
    },
  });

  const setValue = methods.setValue;
  useEffect(
    () => setValue("groupId", isOfficeOperator ? undefined : groups?.[0].id),
    [groups, setValue, isOfficeOperator]
  );
  return (
    <FormProvider {...methods}>
      <PaneAndContent>
        <PaneAndContent.Pane>
          <ReportFiltersPanel
            hasUser={false}
            clearOffice={true}
            clearGroup={!!isOfficeOperator}
            hasToCSV={false}
            showDeleted
            checkinStatus={[
              ReportingCheckInStatus.ALL,
              ReportingCheckInStatus.VALID_CHECKIN,
              ReportingCheckInStatus.INVALID_CHECKIN,
            ]}
            isSingleMonth={selectedOption === ToggleOption.MONTHLY}
            isSingleYear={
              selectedOption === ToggleOption.YEARLY ||
              selectedOption === ToggleOption.WEEKLY
            }
            timePeriod={
              selectedOption === ToggleOption.MONTHLY
                ? [
                    ReservationPeriod.THIS_MONTH,
                    ReservationPeriod.LAST_MONTH,
                    ReservationPeriod.SELECT,
                  ]
                : [
                    ReservationPeriod.THIS_YEAR,
                    ReservationPeriod.LAST_YEAR,
                    ReservationPeriod.SELECT,
                  ]
            }
          />
        </PaneAndContent.Pane>
        <PaneAndContent.Content>
          <TeamPreviewManagement
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </PaneAndContent.Content>
      </PaneAndContent>
    </FormProvider>
  );
};

export default UtilizationByTeamReport;
