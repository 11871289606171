import BaseCollecionLinks from "app/models/BaseCollectionLinks";
import ReportModel from "app/models/ReportModel";
import ResourceType from "app/models/ResourceType";
import { stringifyUrl } from "app/utils/fetch-client";
import { laggy } from "app/utils/laggy";
import { CollectionModel } from "hateoas-hal-types";
import { default as useSWR, default as useSwr } from "swr";

export interface ReportSearchCriteria {
  office?: string;
  floor?: string;
  group?: string;
  resource?: ResourceType;
  from?: string;
  to?: string;
  userId?: string;
  toJson?: boolean;
}

export interface ReportStatistics {
  hour: number;
  date: Date;
  type: ResourceType;
  allReservations: number;
  activeReservations: number;
  checked: number;
  unchecked: number;
  cancelled: number;
  allResources: number;
  utilizationPercent: number;
  personalCancellations: number;
  onBehalfCancellations: number;
  expired: number;
}

export function useUtilizationByCriteria(
  reportName: string,
  filter: ReportSearchCriteria = {}
) {
  const { data, error, isValidating } = useSwr<
    CollectionModel<ReportStatistics>
  >(stringifyUrl(`/api/v1/reports/${reportName}?`, filter), { use: [laggy] });
  const statistics = data?._embedded?.utilizationStatisticList;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return {
    data: statistics,
    links: extractedLinks,
    error,
    isLoading: isValidating,
  };
}

export const useReports = (filter: ReportSearchCriteria) => {
  const { data, isValidating } = useSWR(
    stringifyUrl("/api/v1/reports?", { ...filter, toJson: true })
  );
  return {
    data: data?.content as ReportModel[],
    page: {
      number: data?.number,
      size: data?.numberOfElements,
      total: data?.totalElements,
    },
    isLoading: isValidating,
  };
};
