import { useOfficesByCriteria } from "app/api/OfficeApi";
import NotificationFilter from "app/models/NotificationFilter";
import ReservationPeriod from "app/models/ReservationPeriod";
import ReportTimeToggle from "app/reports/ReportTimeToggle";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";
import "./NotificationFilters.css";

const NotificationFilters = () => {
  const intl = useIntl();
  const { data: offices } = useOfficesByCriteria({ active: true });
  const { control } = useFormContext<NotificationFilter>();

  return (
    <Form className="NotificationFilters-Form" autoComplete="off">
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange } }) => (
          <Form.Control
            type="text"
            placeholder={intl.formatMessage({
              id: "notification.filters.name",
              defaultMessage: "Name",
            })}
            onChange={(e) =>
              e.target.value === ""
                ? onChange(undefined)
                : onChange(e.target.value)
            }
          />
        )}
      />

      <Controller
        control={control}
        name="officeId"
        render={({ field: { value, onChange } }) => (
          <Select
            menuPlacement="auto"
            placeholder={
              <FormattedMessage
                id="notification.filters.office"
                defaultMessage="Office"
              />
            }
            options={offices || []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id + ""}
            onChange={(value) => onChange(value?.id || undefined)}
            value={offices?.find((office) => office.id === value)}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            isClearable
          />
        )}
      />

      <div className="NotificationFilters-Period">
        <Controller
          control={control}
          name="period"
          render={({ field: { onChange, value } }) => (
            <ReportTimeToggle
              onChange={onChange}
              period={value}
              labels={[
                ReservationPeriod.ALL,
                ReservationPeriod.TODAY,
                ReservationPeriod.SELECT,
              ]}
            />
          )}
        />
      </div>
    </Form>
  );
};

export default NotificationFilters;
