import { DATE_TIME_PATTERN } from "app/constants/DateTimeFormatting";
import ApiError from "app/models/error/ApiError";
import ApiException from "app/models/error/ApiException";
import { getCurrentLocale } from "i18n/LocaleProvider";
import moment from "moment";
import queryString from "query-string";
import { adjustDST } from "./DateUtil";

const fetchClientInternal = async (url: string, options: RequestInit = {}) => {
  let { headers, ...rest } = options;

  headers = { ...headers, "Accept-Language": getCurrentLocale() };
  const init = {
    ...rest,
    headers,
  };
  const response = await fetch(url, init);
  if (response.ok) {
    const contentType = response.headers.get("content-type");
    if (!contentType) {
      return;
    }
    if (contentType.includes("text")) {
      return response.text();
    } else {
      return response.json();
    }
  }

  if (response.headers.get("content-type") === "application/problem+json") {
    const error: ApiError = await response.json();
    throw new ApiException(error);
  }

  throw new Error(await response.text());
};

const fetchClient = {
  get: fetchClientInternal,
  post: (url: string, options: RequestInit = {}) =>
    fetchClientInternal(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        ...(options?.headers || {}),
      },
      ...options,
    }),
  patch: (url: string, options: RequestInit = {}) =>
    fetchClientInternal(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        ...(options?.headers || {}),
      },
      ...options,
    }),
  delete: (url: string, options: RequestInit = {}) =>
    fetchClientInternal(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;",
        ...(options?.headers || {}),
      },
      ...options,
    }),
  put: (url: string, options: RequestInit = {}) =>
    fetchClientInternal(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json;",
        ...(options?.headers || {}),
      },
      ...options,
    }),
};

export function replacer(this: any, key: string, value: any) {
  if (this[key] instanceof Date) {
    return moment(adjustDST(this[key])).utc().format(DATE_TIME_PATTERN);
  }

  return value;
}

export const stringifyUrl = (url: string, query: any) => {
  return queryString.stringifyUrl({
    url,
    query: Object.fromEntries(
      Object.entries(query).map((entry) => [
        entry[0],
        entry[1] instanceof Date
          ? moment(entry[1]).utc().format(DATE_TIME_PATTERN)
          : entry[1],
      ])
    ) as any,
  });
};

export default fetchClient;
