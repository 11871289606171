import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useBrandingInfo } from "./api/ApplicationBrandingApi";
import "./PoweredByProxiad.css";
import ProxiadLogo from "./proxiad-logo-no-slogan.svg";

const PoweredByProxiad: React.FC<{
  renderIn?: Element | null;
}> = ({ renderIn }) => {
  const { data: brandingInfo } = useBrandingInfo();

  useEffect(() => {
    window.addEventListener("resize", handleOverlap);

    return () => {
      window.removeEventListener("resize", handleOverlap);
      handleOverlap();
    };
  }, []);

  return brandingInfo?.showCorporateLogo && renderIn
    ? ReactDOM.createPortal(
        <div
          className={"PoweredByProxiad d-none d-md-block"}
          id="PoweredByProxiad"
        >
          <span className="PoweredByProxiad-Text">Powered by </span>
          <img
            src={ProxiadLogo}
            className="PoweredByProxiad-Logo"
            alt="Proxiad logo"
          />
        </div>,
        renderIn
      )
    : null;
};

export const handleOverlap = () => {
  const poweredByProxiad = document.getElementById("PoweredByProxiad");
  const mustNotOverlap = document
    .getElementsByClassName("NoOverlapProxiad")
    .item(0);

  const detectOverlapWithProxi = (elem: Element | null) => {
    if (!poweredByProxiad || !elem) return false;
    const domRectToCheck = elem.getBoundingClientRect();
    const poweredByProxiadRect = poweredByProxiad.getBoundingClientRect();
    return !(
      poweredByProxiadRect.top > domRectToCheck.bottom ||
      poweredByProxiadRect.right < domRectToCheck.left ||
      poweredByProxiadRect.bottom < domRectToCheck.top ||
      poweredByProxiadRect.left > domRectToCheck.right
    );
  };

  detectOverlapWithProxi(mustNotOverlap)
    ? poweredByProxiad?.classList.add("PoweredByProxiad-Hidden")
    : poweredByProxiad?.classList.remove("PoweredByProxiad-Hidden");
};

export default PoweredByProxiad;
