import { useAuditEntityNames, useAuditReasons } from "app/api/AuditApi";
import { useUsersByCriteria } from "app/api/UserApi";
import AuditFilter from "app/models/AuditFilter";
import ReservationPeriod from "app/models/ReservationPeriod";
import ReportTimeToggle from "app/reports/ReportTimeToggle";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import Select from "react-select";
import "./AuditFilters.css";

const AuditFilters = () => {
  const { control, setValue } = useFormContext<AuditFilter>();
  const { data: users } = useUsersByCriteria();
  const { data: reasons } = useAuditReasons();
  const { data: entityNames } = useAuditEntityNames();
  const intl = useIntl();

  return (
    <Form className="AuditFilters-Form" autoComplete="off">
      <Controller
        control={control}
        name="email"
        render={({ field: { value, onChange } }) => (
          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            placeholder={intl.formatMessage({
              id: "audit.filters.selectUser",
              defaultMessage: "Select user",
            })}
            options={users}
            menuPlacement="auto"
            value={users?.find((user) => user.email === value) || null}
            getOptionLabel={(option) => option.fullName}
            getOptionValue={(option) => option.email + ""}
            onChange={(value) => {
              onChange(value?.email || null);
              setValue("page", 0);
            }}
            isClearable
          />
        )}
      />

      <Controller
        control={control}
        name="reason"
        render={({ field: { onChange } }) => (
          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            placeholder={intl.formatMessage({
              id: "audit.filters.selectReasonOperation",
              defaultMessage: "Select reason's operation",
            })}
            options={
              reasons?.map((reason) => ({
                value: reason,
                label: intl.formatMessage({
                  id: "audit.reason." + reason.replaceAll("-", "."),
                  defaultMessage: reason,
                }),
              })) || []
            }
            menuPlacement="auto"
            onChange={(option) => {
              onChange(option?.value || undefined);
              setValue("page", 0);
            }}
            isClearable
          />
        )}
      />

      <Controller
        name="entityName"
        control={control}
        render={({ field: { onChange } }) => (
          <Select
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            placeholder={intl.formatMessage({
              id: "audit.filters.selectEntity",
              defaultMessage: "Select entity",
            })}
            options={
              entityNames?.map((entityName) => ({
                value: entityName,
                label: intl.formatMessage({
                  id: "audit.entityName." + entityName,
                  defaultMessage: entityName,
                }),
              })) || []
            }
            menuPlacement="auto"
            onChange={(option) => {
              onChange(option?.value || undefined);
              setValue("page", 0);
            }}
            isClearable
          />
        )}
      />

      <div className="AuditFilters-Period">
        <Controller
          control={control}
          name="period"
          render={({ field: { onChange, value } }) => (
            <ReportTimeToggle
              onChange={(value) => {
                onChange(value);
                setValue("page", 0);
              }}
              isCurrentAndFuture
              period={value}
              labels={[
                ReservationPeriod.TODAY,
                ReservationPeriod.YESTERDAY,
                ReservationPeriod.SELECT,
              ]}
            />
          )}
        />
      </div>
    </Form>
  );
};
export default AuditFilters;
