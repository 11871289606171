import { passwordChangeRequest } from "app/api/UserApi";
import ApiException from "app/models/error/ApiException";
import PasswordChangeRequestRequest from "app/models/PasswordChangeRequestRequest";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { FormattedMessage, useIntl } from "react-intl";

const LobbyForgotPasswordModal: React.FC<{
  handleCloseModal: () => void;
}> = ({ handleCloseModal }) => {
  const intl = useIntl();
  const form = useForm<PasswordChangeRequestRequest>({});
  const [showFormOrSuccessModal, setShowFormOrSuccessModal] = useState(true);
  const [message, setMessage] = useState<string>();
  const [title, setTitle] = useState<string>();

  const {
    handleSubmit: formSubmit,
    register,
    formState: { errors },
  } = form;

  const createNewChangePasswordRequest = async (
    requestBody: PasswordChangeRequestRequest
  ) => {
    try {
      await passwordChangeRequest(requestBody);
      setShowFormOrSuccessModal(false);
      setTitle(
        intl.formatMessage({
          id: "LobbyForgotPasswordModal.SentRequest.Success.Title",
          defaultMessage: "Instructions sent",
        })
      );
      setMessage(
        intl.formatMessage({
          id: "LobbyForgotPasswordModal.SentRequest.Success.Message",
          defaultMessage:
            "We sent instructions to your email on how to reset your login password.\nPlease check your spam or junk mail folder.",
        })
      );
    } catch (exc) {
      setTitle(
        intl.formatMessage({
          id: "LobbyForgotPasswordModal.SentRequest.Error.Title",
          defaultMessage: "Error Occured",
        })
      );
      exc instanceof ApiException
        ? setMessage(exc.error.title)
        : setMessage(
            intl.formatMessage({
              id: "LobbyForgotPasswordModal.SentRequest.Error.Message",
              defaultMessage: "Something happened! ",
            })
          );
    }
  };

  useHotkeys("esc", () => {
    handleCloseModal();
  });

  return (
    <div>
      <Modal show={true} centered>
        {showFormOrSuccessModal ? (
          <FormProvider {...form}>
            <Form
              autoComplete="off"
              onSubmit={formSubmit(createNewChangePasswordRequest)}
            >
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage
                    id="LobbyForgotPasswordModal.modal.title"
                    defaultMessage="Reset your password"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="formEmail">
                  <Form.Label>
                    <FormattedMessage
                      id="LobbyForgotPasswordModal.form.email"
                      defaultMessage="Please fill in your email to receive a password reset email."
                    />
                  </Form.Label>
                  <Form.Control
                    {...register("email", {
                      required: intl.formatMessage({
                        id: "form.errors.required",
                        defaultMessage: "The field is required",
                      }),
                    })}
                    placeholder={intl.formatMessage({
                      id: "LobbyForgotPasswordModal.form.email.placeholder",
                      defaultMessage: "Enter your email",
                    })}
                    type={"email"}
                    className={errors.email && "is-invalid"}
                  />
                  <span className="text-danger">{errors.email?.message}</span>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center align-items-center">
                <Button
                  variant="primary"
                  type="submit"
                  className="ForgotPassword-ModalSubmitBtn"
                >
                  <FormattedMessage
                    id="LobbyForgotPasswordModal.modal.submitButton"
                    defaultMessage="Submit"
                  />
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  <FormattedMessage
                    id="LobbyForgotPasswordModal.modal.cancelButton"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Modal.Footer>
            </Form>
          </FormProvider>
        ) : (
          <div>
            <Modal.Header>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => handleCloseModal()}>
                <FormattedMessage
                  id="LobbyForgotPasswordModal.SuccessModal.okButton"
                  defaultMessage="Ok"
                />
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LobbyForgotPasswordModal;
