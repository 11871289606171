import { getAvailableFloors, useOfficesByCriteria } from "app/api/OfficeApi";
import { useCurrentPersonalReservations } from "app/api/ReservationApi";
import useCurrentUser from "app/api/UserApi";
import Office from "app/models/Office";
import Reservation from "app/models/Reservation";
import ReservationInvitation from "app/models/ReservationInvitation";
import ReservationPolicy from "app/models/ReservationPolicy";
import Resource from "app/models/Resource";
import ResourceType from "app/models/ResourceType";
import SelectDateMode from "app/models/SelectDateMode";
import User from "app/models/User";
import { withFunctions } from "app/utils/useConfig";
import { EntityModel } from "hateoas-hal-types";
import moment from "moment";
import React, { createContext, useEffect, useReducer } from "react";
import toast from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  isToday,
  maxDate,
  minDate,
  useDefaultEndDate,
  useDefaultStartDate,
} from "./utils";

type ReservationForm = {
  predefinedReservationPeriod: SelectDateMode;
  resourceType: ResourceType;
  reservationPolicy?: ReservationPolicy;
  currentOffice?: EntityModel<Office>;
  currentFloor?: string;
  startDate: Date;
  endDate: Date;
  selectedResource: Resource[];
  markedResource?: Resource;
  selectedReservation?: EntityModel<Reservation>;
  reservedFor: EntityModel<User & { guest?: boolean; guestName?: string }>[];
  swapReservation?: boolean;
  invitation?: EntityModel<ReservationInvitation>;
};

type Action = {
  type:
    | "setCurrentOffice"
    | "setCurrentFloor"
    | "setResourceType"
    | "setPredefinedReservationPeriod"
    | "setStartDate"
    | "setEndDate"
    | "setSelectedReservation"
    | "setSelectedResource"
    | "setMarkedResource"
    | "setReservedFor"
    | "submit";
  state?: (state: ReservationForm) => Partial<ReservationForm>;
};

const createReducer =
  (onBehalf: boolean) =>
  (state: ReservationForm, action: Action): ReservationForm => {
    let actionRd = noOp;
    /* 
      If state reducer is provided but there is no new data to be set: skip the actions.
      If state reducer is not provided - treat as an action that is not submitting new data but should be executed
  */
    if (action.state) {
      const actionRdResult = action.state(state);
      if (Object.keys(actionRdResult).length === 0) {
        return state;
      }
      actionRd = (oldState: ReservationForm) => ({
        ...oldState,
        ...actionRdResult,
      });
    }

    const reducers: ((state: ReservationForm) => ReservationForm)[] = [
      actionRd,
    ];
    const resetReservedForIfNeeded = onBehalf ? resetReservedFor : noOp;
    switch (action.type) {
      case "setCurrentFloor":
        reducers.push(resetMarkedResource);
        break;
      case "setCurrentOffice":
      case "setResourceType":
        reducers.push(
          calcPredefinedPeriod,
          calcDatesForResourceTypeChange,
          resetSelectedResource,
          resetReservedForIfNeeded,
          resetMarkedResource
        );
        break;
      case "setPredefinedReservationPeriod":
        reducers.push(
          calcDates,
          resetSelectedResource,
          resetReservedForIfNeeded,
          resetMarkedResource
        );
        break;
      case "setStartDate":
      case "setEndDate":
        reducers.push(
          resetSelectedResource,
          resetReservedForIfNeeded,
          resetMarkedResource
        );
        break;
      case "setSelectedReservation":
        reducers.push((state) => {
          if (state.selectedReservation) {
            // adjust the start/end to the selected desk reservation when doing a parking reservation
            return {
              ...state,
              startDate: state.selectedReservation.startTime,
              endDate: state.selectedReservation.endTime,
            };
          }
          return state;
        });
        break;
      case "setMarkedResource":
        break;
      case "setSelectedResource":
        reducers.push((state) =>
          validateIsNoParkingWithoutDesk(state, onBehalf)
        );
        break;
      case "setReservedFor":
        break;
      case "submit":
        reducers.push(
          resetSelectedResource,
          resetReservedForIfNeeded,
          resetMarkedResource,
          resetInvitation,
          resetLocationState
        );
        break;
      default:
        throw new Error(`Unknown action type ${action.type}`);
    }
    const newState = reducers.reduce((state, fn) => fn(state), state);
    return { ...state, ...newState };
  };

const resetMarkedResource = (state: ReservationForm) => ({
  ...state,
  markedResource: undefined,
});

const resetSelectedResource = (state: ReservationForm) => ({
  ...state,
  selectedResource: [],
});

const resetReservedFor = (state: ReservationForm) => ({
  ...state,
  reservedFor: [],
});

const resetInvitation = (state: ReservationForm) => ({
  ...state,
  invitation: undefined,
});

const resetLocationState = (state: ReservationForm) => {
  window.history.replaceState({}, document.title);
  return state;
};

const noOp = (state: ReservationForm) => state;

const validateIsNoParkingWithoutDesk = (
  state: ReservationForm,
  onBehalf: boolean
) => {
  const isInvalid =
    state.currentOffice?.noParkWithoutDesk &&
    state.selectedResource.find(
      (resource) => resource?.type === ResourceType.PARK_PLACE
    ) &&
    !state.selectedReservation;

  if (isInvalid && !onBehalf) {
    toast.error(
      <FormattedMessage
        id="reservationForm.toastMessage.selectDeskReservation"
        defaultMessage="Please select a desk reservation first"
      />,
      {
        id: "reservation-error",
      }
    );
  }

  return isInvalid && !onBehalf ? resetSelectedResource(state) : state;
};

const calcPredefinedPeriod = (state: ReservationForm): ReservationForm => {
  const { currentOffice, predefinedReservationPeriod } = state;
  if (!currentOffice || predefinedReservationPeriod !== SelectDateMode.NA) {
    return state;
  }
  const today = moment().seconds(0).milliseconds(0);
  const tomorrow = moment().seconds(0).milliseconds(0).add(1, "day");

  const { isWeekday } = withFunctions(currentOffice);
  // start/end time calculation done in the useEffect below
  const canBookToday = isWeekday(today);
  // && !today.isAfter(getDefaultEndWorkDayFor(today)); when enabled today is not default selection if after office work hours
  const canBookTomorrow = isWeekday(tomorrow);
  /*
    Logic: We move the selection to first option that is available. An option is unavailable if
    the corresponding day is a not a weekday or the working hour have already passed.
    Example:
      - if weeikdays are MON-FRI and today is SAT: TODAY/TOMORROW are disabled
        and date in SELECT is set to MON
      - if weeikdays are MON-FRI and today is SUN: TODAY are disabled
        and date in SELECT is set to MON
      - if weeikdays are MON-FRI(09-19) and today is MON(20:00): TODAY are disabled
        and date in SELECT is set to TUE
  */
  return {
    ...state,
    predefinedReservationPeriod: canBookToday
      ? SelectDateMode.TODAY
      : canBookTomorrow
      ? SelectDateMode.TOMORROW
      : SelectDateMode.SELECT,
  };
};

const calcDates = (state: ReservationForm): ReservationForm => {
  const {
    predefinedReservationPeriod,
    currentOffice,
    resourceType,
    reservationPolicy,
  } = state;
  if (predefinedReservationPeriod === SelectDateMode.NA || !currentOffice) {
    return state;
  }
  if (currentOffice.is247Mode) {
    const now = moment().seconds(0).milliseconds(0);
    const startDate = now.toDate();
    const endDate = moment(now)
      .add(
        reservationPolicy?.timeLimit
          ? reservationPolicy.timeLimit
          : resourceType === ResourceType.CONF_ROOM
          ? 1
          : 8,
        reservationPolicy?.timeLimit ? "minutes" : "hour"
      )
      .toDate();
    return {
      ...state,
      startDate,
      endDate,
    };
  }

  const {
    getDefaultStartWorkDayFor,
    getDefaultEndWorkDayFor,
    calculateNextWeekday,
  } = withFunctions(currentOffice);

  const policy = state.reservationPolicy;

  const today = new Date();
  const baseDate =
    predefinedReservationPeriod === SelectDateMode.TODAY
      ? today
      : predefinedReservationPeriod === SelectDateMode.TOMORROW
      ? moment().seconds(0).milliseconds(0).add(1, "day").toDate()
      : calculateNextWeekday();

  if (policy?.allDay) {
    const startDate = getDefaultStartWorkDayFor(baseDate);
    const endDate = getDefaultEndWorkDayFor(baseDate);

    return {
      ...state,
      startDate,
      endDate,
    };
  } else {
    const start = maxDate([baseDate, getDefaultStartWorkDayFor(baseDate)]);
    const adjustedStart = isToday(start)
      ? start
      : getDefaultStartWorkDayFor(start);
    const endDate = minDate([
      moment(adjustedStart)
        .add(
          reservationPolicy?.timeLimit
            ? reservationPolicy.timeLimit
            : resourceType === ResourceType.CONF_ROOM
            ? 1
            : 8,
          reservationPolicy?.timeLimit ? "minutes" : "hour"
        )
        .toDate(),
      getDefaultEndWorkDayFor(baseDate),
    ]);

    return {
      ...state,
      startDate: adjustedStart,
      endDate,
    };
  }
};

const calcDatesForResourceTypeChange = (state: ReservationForm) => {
  const { startDate: stateStart, endDate: stateEnd, currentOffice } = state;

  if (!currentOffice) {
    return state;
  }

  const { isWeekday } = withFunctions(currentOffice);
  const { startDate: calcStart, endDate: calcEnd } = calcDates(state);
  const momentCalcStart = moment(calcStart);
  const momentCalcEnd = moment(calcEnd);

  const adjustedStart = isWeekday(stateStart)
    ? moment(stateStart)
        .set({
          hour: momentCalcStart.hour(),
          minute: momentCalcStart.minute(),
          second: 0,
          millisecond: 0,
        })
        .toDate()
    : calcStart;

  const adjustedEnd = isWeekday(stateEnd)
    ? moment(stateEnd)
        .set({
          hour: momentCalcEnd.hour(),
          minute: momentCalcEnd.minute(),
          second: 0,
          millisecond: 0,
        })
        .toDate()
    : calcEnd;

  return {
    ...state,
    startDate: adjustedStart,
    endDate: adjustedEnd,
  };
};

export const ReservationFormContextProvider: React.FC<{
  onBehalf: boolean;
  children?: React.ReactNode;
}> = ({ onBehalf, children }) => {
  const [formState, dispatch] = useReducer(createReducer(onBehalf), {
    predefinedReservationPeriod: SelectDateMode.NA,
    resourceType: ResourceType.DESK,
    startDate: useDefaultStartDate(),
    endDate: useDefaultEndDate(),
    selectedResource: [],
    reservedFor: [],
  });

  const { data: currentUser } = useCurrentUser();
  const { data: offices } = useOfficesByCriteria({ active: true });

  const { data: personalReservations } = useCurrentPersonalReservations();

  // initialization of current office, floor, resource policy and reserved for
  useEffect(() => {
    const init = async () => {
      const resourceType = ResourceType.DESK;
      const currentReservation = personalReservations!.find(
        (res) => res.resource.type === resourceType
      );

      const activeOffices = offices?.filter(
        (office) => office.active && office
      );
      const office =
        activeOffices?.find(
          (office) => office.id === currentReservation?.floor.officeId
        ) ||
        activeOffices?.find((office) => office.id === currentUser?.officeId) ||
        offices![0];

      const availableFloors = await getAvailableFloors(
        {
          reserveForId: currentUser?.id,
          resourceType: resourceType,
          onBehalf,
        },
        office?.id
      );
      const floorId =
        currentReservation?.floor.id || availableFloors.data?.[0].id;
      dispatch({
        type: "setCurrentOffice",
        state: (state) => {
          return state.currentOffice
            ? {}
            : {
                currentOffice: office?.active
                  ? office
                  : offices?.find(
                      (office) => office.id === currentUser?.officeId
                    ),
                currentFloor: floorId,
                reservationPolicy:
                  office &&
                  Object.entries(office?.reservationPolicies).find(
                    (entry) => entry[0] === resourceType
                  )?.[1],
                reservedFor: onBehalf || !currentUser ? [] : [currentUser],
              };
        },
      });
    };
    offices && personalReservations && init();
  }, [offices, currentUser, personalReservations, onBehalf]);

  const { state } = useLocation();

  const locationState = state as {
    markedResource: Resource;
    startDate: Date;
    endDate: Date;
    predefinedReservationPeriod: SelectDateMode;
    message?: string;
    isSuccess: boolean;
    invitation?: EntityModel<ReservationInvitation>;
  };

  useEffect(() => {
    if (locationState?.message) {
      locationState?.isSuccess
        ? toast.success(locationState?.message, { position: "top-center" })
        : toast.error(locationState?.message, { position: "top-center" });
      locationState.message = undefined;
    }
    if (locationState?.markedResource && offices) {
      const resource = locationState?.markedResource;
      const searchedOffice = offices.find(
        (office) => office.id === resource.officeId
      );
      dispatch({
        type: "setMarkedResource",
        state: (state) => {
          return {
            ...state,
            reservedFor: onBehalf || !currentUser ? [] : [currentUser],
            markedResource: locationState.markedResource,
            currentOffice: searchedOffice,
            currentFloor: resource.floorId,
            resourceType: resource.type,
            reservationPolicy:
              searchedOffice &&
              Object.entries(searchedOffice?.reservationPolicies).find(
                (entry) => entry[0] === resource.type
              )?.[1],
            startDate: locationState.startDate,
            endDate: locationState.endDate,
            predefinedReservationPeriod:
              locationState.predefinedReservationPeriod,
            invitation: locationState.invitation,
          };
        },
      });
      setTimeout(
        () =>
          dispatch({
            type: "setMarkedResource",
            state: (state) => ({
              ...state,
              markedResource: undefined,
            }),
          }),
        10000
      );
    }
    return () => window.history.replaceState({}, document.title);
  }, [offices, dispatch, locationState, onBehalf, currentUser]);

  return (
    <ReservationFormContext.Provider
      value={{
        state: formState,
        dispatch,
        onBehalf,
      }}
    >
      {children}
    </ReservationFormContext.Provider>
  );
};

export type ReservationFormContextType = {
  dispatch: (value: Action) => void;
  state: ReservationForm;
  onBehalf: boolean;
  // reservationsForTypeAndPeriod: NonPagedData<Reservation>;
};

export const ReservationFormContext = createContext<ReservationFormContextType>(
  {
    dispatch: () => {},
    state: {
      startDate: new Date(),
      endDate: new Date(),
      resourceType: ResourceType.DESK,
      reservedFor: [],
      selectedResource: [],
      predefinedReservationPeriod: SelectDateMode.TODAY,
    },
    onBehalf: false,
  }
);
