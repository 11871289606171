export const dbLang = {
  prefix: "far",
  iconName: "lang",
  icon: [
    485,
    512,
    [],
    "f274",
    "M261.63999,323.54 205.76,268.31999 206.41999,267.66 C 244.69998,224.98001 271.98,175.92001 288.04,124 H 352.5 V 80.000017 H 198.50001 V 36 h -44 V 80.000017 H 0.500005 V 123.78001 H 246.24002 c -14.74001,42.46 -38.06001,82.72 -69.74002,117.91998 C 156.04,219.04 139.1,194.18001 125.68001,168.00002 H 81.680003 C 97.740005,203.86 119.74,237.74001 147.24001,268.31999 L 35.260005,378.76 66.500004,410.00001 176.5,300.00002 244.92,368.42001 Z M 385.5,212.00001 h -44 L 242.5,476 h 44 l 24.64,-65.99999 h 104.5 L 440.5,476 h 44 z M 327.86,366 363.5,270.74001 399.13999,366z",
  ],
};
