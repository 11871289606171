import BaseCollecionLinks from "app/models/BaseCollectionLinks";
import CreateRole from "app/models/CreateRole";
import Role from "app/models/Role";
import UpdateRole from "app/models/UpdateRole";
import fetchClient from "app/utils/fetch-client";
import { CollectionModel } from "hateoas-hal-types";
import useSwr from "swr";

function reloadRoles(
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return mutate(/\/api\/v1\/roles/);
}

export default function useRoles() {
  const path = `/api/v1/roles`;
  const { data, error } = useSwr<CollectionModel<Role>>(path);
  const roles = data?._embedded.roles;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return {
    data: roles,
    isLoading: !data && !error,
    links: extractedLinks,
    error,
  };
}

export const createRole = (
  url: string,
  role: CreateRole,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> =>
  fetchClient
    .post(url, {
      body: JSON.stringify(role),
    })
    .then(() => reloadRoles(mutate));

export const updateRole = (
  url: string,
  role: UpdateRole,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> =>
  fetchClient
    .patch(url, {
      body: JSON.stringify(role),
    })
    .then(() => reloadRoles(mutate));

export const deactivateRole = (id: string): Promise<void> =>
  fetchClient.delete(`/api/v1/roles/${id}`);
