import { useNotifications } from "app/api/NotificationApi";
import { ReservationFormContext } from "app/bookings/ReservationFormContext";
import Notification from "app/models/Notification";
import { EntityModel } from "hateoas-hal-types";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FormattedMessage } from "react-intl";
import DeskBuddyRobotLeftHand from "./DESKBUDDY_Character_Robot_1_left_hand.png";

import "./NotificationToast.css";

const lsLastSeen = "lastTimeNotifSeen";
const lsNotificationSeen = "notificationsSeen";

const NotificationToast = () => {
  const {
    state: { currentOffice, startDate, endDate },
  } = useContext(ReservationFormContext);

  const { data: notifications } = useNotifications({
    officeId: currentOffice?.id,
    start: moment(startDate).isSame(new Date(), "day") ? new Date() : startDate,
    end: endDate,
    isActive: true,
  });

  useEffect(() => {
    currentOffice && showToast(getNotificationsToSee(notifications));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  return <></>;
};

export const showToast = (notifications: Array<EntityModel<Notification>>) => {
  if (!notifications || notifications?.length === 0) return;
  toast(
    <ToastContent
      notifications={notifications}
      closeToast={() => toast.remove("notification")}
    />,
    {
      id: "notification",
      position: "top-center",
      duration: Infinity,
    }
  );
};

const ToastContent: React.FC<{
  notifications: Array<EntityModel<Notification>>;
  closeToast: () => void;
}> = ({ notifications, closeToast }) => {
  const [index, setIndex] = useState(0);

  return (
    <div className="ToastNotification-Container">
      <p>{notifications?.[index].name}</p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Image
          src={DeskBuddyRobotLeftHand}
          alt="Desk buddy robot"
          className="Toast-Lobby-Logo-Picture"
        />
        <div>{notifications?.[index].text}</div>
      </div>

      <Button
        variant="primary"
        onClick={() => {
          addSeenNotification(notifications[index].id);
          if (notifications[index + 1]) {
            setIndex(index + 1);
            return;
          }
          window.dispatchEvent(new Event("storage"));
          closeToast();
        }}
      >
        <FormattedMessage id="form.okButton" defaultMessage="Ok" />
      </Button>
    </div>
  );
};

export const getNotificationsToSee = (
  notifications: Array<EntityModel<Notification>>
) => {
  return notifications?.filter(
    (notification) => !isNotificationSeen(notification.id)
  );
};

const isNotificationSeen = (notificationId: string) => {
  seenTodayCheck();
  return localStorage.getItem(lsNotificationSeen)?.includes(notificationId);
};

const seenTodayCheck = () => {
  if (!(localStorage.getItem(lsLastSeen) === new Date().toDateString())) {
    localStorage.setItem(lsLastSeen, new Date().toDateString());
    localStorage.removeItem(lsNotificationSeen);
  }
};

const addSeenNotification = (notificationId: string) => {
  if (isNotificationSeen(notificationId)) return;
  const seenNotifications = localStorage.getItem(lsNotificationSeen);
  localStorage.setItem(
    lsNotificationSeen,
    seenNotifications
      ? seenNotifications + "/" + notificationId
      : notificationId
  );
};

export default NotificationToast;
