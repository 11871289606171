import PagedData from "app/models/PagedData";
import { apiFetchWithReviver } from "AppConfig";
import { PagedModel } from "hateoas-hal-types";
import useSWRInfinite from "swr/infinite";
import { laggy } from "./laggy";

export const usePagedData = <InputType, ReturnType extends Record<string, any>>(
  buildQuery: (criteria: InputType, pageIndex: number) => string,
  criteria: InputType,
  getLimit: (criteria: InputType) => number | undefined,
  collectionName: string
): PagedData<ReturnType> => {
  const limit = getLimit(criteria);
  const { data, error, size, setSize, isValidating } = useSWRInfinite<
    PagedModel<ReturnType>
  >(
    (pageIndex, previousPageData) => {
      if (previousPageData && !previousPageData._embedded) {
        return null;
      }
      return buildQuery(criteria, pageIndex);
    },
    apiFetchWithReviver,
    { /*revalidateFirstPage: false, */ use: [laggy] }
  );
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === "undefined");
  const totalElements = data && data[0]?.page.totalElements;
  const isEmpty = totalElements === 0;
  const isServerResponseBlank =
    !isLoadingMore && totalElements === 0;
  const hasReachedEnd =
    isEmpty ||
    isServerResponseBlank ||
    !limit ||
    (data?.[0].page && data?.[0].page.number + 1 === data?.[0].page.totalPages);
  const isRefreshing = isValidating && data && data.length === size;

  return {
    data: data
      ?.filter((page) => page._embedded)
      .flatMap((page) => page._embedded?.[collectionName]),
    size: size,
    setSize,
    isLoadingInitialData,
    isLoadingMore,
    isEmpty,
    hasReachedEnd,
    isRefreshing,
    totalElements,
  };
};
