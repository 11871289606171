import { ReactComponent as FaPlus } from "app/icons/v2/fa-plus-large.svg";
import React from "react";
import { Card } from "react-bootstrap";
import "./AddNewCard.css";

const AddNewCard: React.FC<{
  handleClick: () => void;
  children?: React.ReactNode;
}> = ({ handleClick, children }) => {
  return (
    <Card onClick={handleClick} className={`h-100 AddNewCard`}>
      <Card.Body>
        <div className={`AddNewCard-ImageSkeleton`}>
          <FaPlus className={`AddNewCard-Image`} />
        </div>
        <Card.Title className={`AddNewCard-Text`}>{children}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default AddNewCard;
