import React from "react";
import "./ErrorBoundary.css";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundary">
          <h1>Something went wrong :-(</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
