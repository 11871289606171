import Office from "app/models/Office";
import moment from "moment";
import { IntlShape } from "react-intl";

export const DEFAULT_WORKDAY_START_TIME = "09:00:00";
export const DEFAULT_WORKDAY_END_TIME = "19:00:00";

export const DAYS_OF_WEEK = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export function isDateInPast(date: Date) {
  return date < new Date();
}

export function convertDateToHrsString(date?: Date) {
  if (!date) {
    return null;
  }
  const dateString = date.toTimeString();
  return dateString.substring(0, dateString.indexOf(" "));
}

export const convertDateToOfficeLocal = (
  date: Date,
  currentOffice?: Office
) => {
  return moment(date)
    .utcOffset(moment.tz(currentOffice?.timezone || "UTC").utcOffset(), true)
    .toDate();
};

export const convertFromToArray = (
  from: string | undefined,
  to: string | undefined,
  intl: IntlShape
): string[] => {
  if (!from || !to) return [];

  const startDate = moment(new Date(from));
  const endDate = moment(new Date(to));

  const dates: string[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    dates.push(dateToStringYMD(currentDate.toDate(), intl));
    currentDate = currentDate.add(1, "day");
  }

  return dates;
};

export const dateToStringYMD = (date: Date, intl: IntlShape) => {
  const locale = intl.locale;

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  } as const;

  const signToSplit = locale === "de" || locale === "bg" ? "." : "/";

  const dateString = new Intl.DateTimeFormat(locale, options)
    .format(date)
    .split(signToSplit);

  return locale === "en-US"
    ? `${dateString[2].slice(0, 4)}/${dateString[0]}/${dateString[1]}`
    : `${dateString[2].slice(0, 4)}/${dateString[1]}/${dateString[0]}`;
};

export const adjustDST = (date: Date) => {
  const momentDate = moment(date);
  const isNowDST = moment().isDST();
  const isDateDST = momentDate.isDST();

  return (
    isNowDST && !isDateDST
      ? momentDate.add(1, "hour")
      : !isNowDST && isDateDST
      ? momentDate.subtract(1, "hour")
      : momentDate
  ).toDate();
};
