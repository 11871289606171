import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useReports } from "app/api/ReportApi";
import { convertFilters, ReportFilters } from "app/models/ReportFilters";
import ReportModel from "app/models/ReportModel";
import User from "app/models/User";
import { makeCsv } from "app/utils/makeCsv";
import moment from "moment";
import { useCallback, useMemo } from "react";
import {
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import "./TeamPreviewWeeklyTable.css";

const TeamPreviewWeeklyTable: React.FC<{
  users?: Array<User>;
  page: number;
  setPage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  rowsPerPage: number;
  setRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  total: number;
}> = ({ users, page, setPage, rowsPerPage, setRowsPerPage, total }) => {
  const NOT_AVAILABLE = "-";
  const { watch } = useFormContext<ReportFilters>();
  const csvFilter = convertFilters(watch());
  const { data } = useReports(csvFilter);
  const intl = useIntl();

  const startTime = watch("period.from");

  const headers = useMemo(
    () =>
      Array.from({ length: moment(startTime).isoWeeksInYear() }).map(
        (_, index) => index + 1
      ),
    [startTime]
  );

  const isRecordValid = (
    record: ReportModel,
    userEmail: string,
    weekNumber: number
  ) =>
    record.Email === userEmail &&
    record.Checked === "Yes" &&
    moment(record.Date).week() === weekNumber;

  const isPrevRecordDiff = useCallback(
    (currentIndex: number) => {
      if (currentIndex === 0) {
        return true;
      }

      const currentRecord = data?.[currentIndex];
      const prevRecord = data?.[currentIndex - 1];

      return (
        !moment(currentRecord.Date).isSame(prevRecord.Date) ||
        currentRecord.Email !== prevRecord.Email ||
        prevRecord.Checked === "No" ||
        prevRecord.Cancelled === "Yes"
      );
    },
    [data]
  );

  const pageBody = useMemo(
    () =>
      users
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        ?.map((user) => [
          user.fullName,
          ...headers.map(
            (weekNumber) =>
              data &&
              (moment()
                .year(startTime.getFullYear())
                .week(weekNumber)
                .endOf("week")
                .isSameOrAfter(user.createdAt)
                ? data?.filter(
                    (record, recordIndex) =>
                      isRecordValid(record, user.email, weekNumber) &&
                      isPrevRecordDiff(recordIndex)
                  ).length
                : NOT_AVAILABLE)
          ),
        ]),
    [data, headers, isPrevRecordDiff, page, rowsPerPage, startTime, users]
  );

  const getTeamBody = () =>
    users?.map((user) => [
      user.fullName,
      ...headers.map(
        (weekNumber) =>
          data &&
          (moment()
            .year(startTime.getFullYear())
            .week(weekNumber)
            .endOf("week")
            .isSameOrAfter(user.createdAt)
            ? data?.filter(
                (record, recordIndex) =>
                  record.Email === user.email &&
                  record.Checked === "Yes" &&
                  moment(record.Date).week() === weekNumber &&
                  (recordIndex === 0 ||
                    !moment(record.Date).isSame(data?.[recordIndex - 1].Date) ||
                    record.Email !== data?.[recordIndex - 1].Email)
              ).length
            : NOT_AVAILABLE)
      ),
    ]) || [];

  return (
    <>
      {users && users.length > 0 ? (
        <TableContainer style={{ height: "100%" }}>
          <Table className="TeamWeeklyTable">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {headers.map((weekNumber) => (
                  <TableCell key={weekNumber}>
                    <OverlayTrigger
                      placement="top"
                      overlay={(props) => {
                        const weekStart = moment()
                          .year(startTime.getFullYear())
                          .isoWeek(weekNumber)
                          .weekday(1)
                          .toDate();
                        return (
                          <Tooltip id={weekNumber.toString()} {...props}>
                            {intl.formatDateTimeRange(
                              weekStart,
                              moment(weekStart).add(6, "days").toDate()
                            )}
                          </Tooltip>
                        );
                      }}
                    >
                      <span>{weekNumber}</span>
                    </OverlayTrigger>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pageBody?.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row?.map((column, colIndex) => (
                    <TableCell
                      key={colIndex}
                      className={
                        typeof column === "number" && column !== 0
                          ? "Values-Indicator"
                          : undefined
                      }
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                {pageBody && (
                  <TableCell colSpan={15}>
                    <DropdownButton
                      title={intl.formatMessage({
                        id: "reportFilters.button.exportCSV",
                        defaultMessage: "Export to CSV",
                      })}
                    >
                      <Dropdown.Item
                        onClick={() =>
                          makeCsv(
                            ["", ...headers],
                            pageBody,
                            `${intl.formatMessage({
                              id: "reports.utilizationByTeam.weeklyTeamReport",
                              defaultMessage: "Weekly team report",
                            })} - ${new Date().toLocaleDateString()}`
                          )
                        }
                      >
                        <FormattedMessage
                          id="reports.utilizationByTeam.thisPage"
                          defaultMessage="This page"
                        />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          makeCsv(
                            ["", ...headers],
                            getTeamBody(),
                            `${intl.formatMessage({
                              id: "reports.utilizationByTeam.weeklyTeamReport",
                              defaultMessage: "Weekly team report",
                            })} - ${new Date().toLocaleDateString()}`
                          )
                        }
                      >
                        <FormattedMessage
                          id="reports.utilizationByTeam.theWholeTeam"
                          defaultMessage="The whole team"
                        />
                      </Dropdown.Item>
                    </DropdownButton>
                  </TableCell>
                )}
                <TablePagination
                  sx={{ "& > * > p ": { margin: "unset" } }}
                  count={total}
                  page={page}
                  onPageChange={setPage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={setRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <p style={{ margin: "auto" }}>
          <FormattedMessage
            id="report.teamTable.noUsersToDisplay"
            defaultMessage="There are no users to display"
          />
        </p>
      )}
    </>
  );
};

export default TeamPreviewWeeklyTable;
