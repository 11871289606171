import { Buffer } from "buffer";
const mime = require("mime");

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const fileToBase64 = async (file: File) => {
  const buffer = await file.arrayBuffer();
  const byteArr = new Int8Array(buffer);
  return Buffer.from(byteArr).toString("base64");
};

export const blobUrlToFile = (blobUrl: string): Promise<File> =>
  new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        const file = new File([blob], `file.${mime.getExtension(blob.type)}`, {
          type: blob.type,
        });
        resolve(file);
      });
    });
  });
