import { usePictureSizeLimit } from "app/api/GlobalConfigApi";
import { ReactComponent as FaUpload } from "app/icons/v2/fa-upload.svg";
import CreatePicture from "app/models/CreatePicture";
import UpdatePicture from "app/models/UpdatePicture";
import { useEffect, useState } from "react";
import { Col, Image, NavLink, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import ImageUploading, { ErrorsType } from "react-images-uploading";
import { FormattedMessage, useIntl } from "react-intl";
import { blobUrlToFile, formatBytes } from "./FileUtil";
import "./ImageUploader.css";
import {
  acceptedImgTypes,
  appendPictureHeader,
  imgFileToPictureModel,
} from "./ImageUtil";

const ImageUploader: React.FC<{
  picture?: CreatePicture | UpdatePicture;
  imageUrl?: string;
  isImgRemovable: boolean;
  onChange: (...event: any[]) => void;
}> = ({ picture, onChange, imageUrl, isImgRemovable }) => {
  const { data: pictureSizeLimit } = usePictureSizeLimit();
  const intl = useIntl();

  const { setValue: setFormData } = useFormContext<{
    picture: CreatePicture | UpdatePicture;
  }>();
  const [pictureLoading, setPictureLoading] = useState(false);

  useEffect(() => {
    const loadImageFile = async () => {
      if (imageUrl) {
        setPictureLoading(true);
        try {
          const file = await blobUrlToFile(imageUrl);
          file.size !== 0 &&
            setFormData("picture", await imgFileToPictureModel(file));
        } catch (e) {
          console.log(e);
        }
        setPictureLoading(false);
      }
    };
    loadImageFile();
  }, [imageUrl, setFormData]);

  const ImgErrors: React.FC<{ errors: ErrorsType }> = ({ errors }) => {
    return (
      <>
        {
          <p className="OfficeForm-Error">
            {errors!.acceptType
              ? intl.formatMessage(
                  {
                    id: "pictureInput.error.fileType",
                    defaultMessage:
                      "File type should be one of the following: {acceptedImgTypes}",
                  },
                  {
                    acceptedImgTypes: acceptedImgTypes.toString(),
                  }
                )
              : errors!.maxFileSize
              ? intl.formatMessage(
                  {
                    id: "pictureInput.error.fileSize",
                    defaultMessage:
                      "File size should not exceed {pictureSizeLimit}",
                  },
                  {
                    pictureSizeLimit: formatBytes(pictureSizeLimit!),
                  }
                )
              : ""}
          </p>
        }
      </>
    );
  };

  return (
    <ImageUploading
      value={picture ? [{ dataURL: appendPictureHeader(picture) }] : []}
      maxNumber={1}
      maxFileSize={pictureSizeLimit}
      acceptType={acceptedImgTypes}
      onChange={async (uploadedImages) => {
        if (!uploadedImages.length) {
          onChange(null);
          return;
        }
        uploadedImages[0].file &&
          onChange(await imgFileToPictureModel(uploadedImages[0].file));
      }}
    >
      {({
        imageList,
        onImageUpload,
        onImageUpdate,
        onImageRemove,
        errors,
        dragProps,
      }) => (
        <>
          {!pictureLoading && !picture && (
            <Row className="h-100">
              <Col
                {...dragProps}
                className="ms-2 me-2 d-flex flex-column UploadArea-Border justify-content-center"
              >
                <FaUpload
                  onClick={onImageUpload}
                  className="mx-auto pt-2"
                  width="5rem"
                  height="5rem"
                />
                <div className="mx-auto pb-3 text-center">
                  <NavLink
                    onClick={onImageUpload}
                    className="pr-1 d-inline fw-bold"
                  >
                    <FormattedMessage
                      id="ImageUploader.link.upload"
                      defaultMessage="Browse files"
                    />
                  </NavLink>
                  &nbsp;
                  <FormattedMessage
                    id="ImageUploader.text.drop"
                    defaultMessage="or drag and drop here"
                  />
                  {errors && <ImgErrors errors={errors} />}
                </div>
              </Col>
            </Row>
          )}
          {imageList.map((image, index) => (
            <Row key={`Office-Image-${index}`}>
              <Col className="d-flex flex-column">
                <Image
                  src={image.dataURL}
                  alt="Office-Image"
                  className="UploadArea-Image  img-thumbnail"
                />
                <div className="d-flex mt-3">
                  <NavLink
                    onClick={() => onImageUpdate(index)}
                    className="m-auto fw-bold"
                  >
                    <FormattedMessage
                      id="ImageUploader.link.change"
                      defaultMessage="Change"
                    />
                  </NavLink>
                  {isImgRemovable && (
                    <NavLink
                      onClick={() => onImageRemove(index)}
                      className="m-auto fw-bold"
                    >
                      <FormattedMessage
                        id="ImageUploader.link.remove"
                        defaultMessage="Remove"
                      />
                    </NavLink>
                  )}
                </div>
                {errors && <ImgErrors errors={errors} />}
              </Col>
            </Row>
          ))}
        </>
      )}
    </ImageUploading>
  );
};

export default ImageUploader;
