import BaseCollecionLinks from "app/models/BaseCollectionLinks";
import CreateResource from "app/models/CreateResource";
import RefResourceType from "app/models/RefResourceType";
import ReservationPolicy from "app/models/ReservationPolicy";
import Resource from "app/models/Resource";
import ResourceSearchCriteria from "app/models/ResourceSearchCriteria";
import ResourceType from "app/models/ResourceType";
import UpdateResource from "app/models/UpdateResource";
import fetchClient, { stringifyUrl } from "app/utils/fetch-client";
import { apiFetch } from "AppConfig";
import { CollectionModel, EntityModel } from "hateoas-hal-types";
import { default as useSWR, default as useSwr } from "swr";
import { reloadOffices } from "./OfficeApi";

const basePath = "/api/v1/resources";

export function reloadResources(
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) {
  return mutate(new RegExp(basePath));
}

export function useOfficeResources(filter: ResourceSearchCriteria) {
  const { data, error } = useSwr<CollectionModel<Resource>>(
    filter.officeId ? stringifyUrl(basePath, filter) : null
  );
  const resources = data ? data._embedded?.resources || [] : undefined;
  const extractedLinks = data?._links as BaseCollecionLinks;

  return {
    data: resources,
    links: extractedLinks,
    error,
  };
}

export function getFloorResources({
  floorId,
  resourceType,
  showDeleted,
}: {
  floorId: string;
  resourceType?: ResourceType;
  showDeleted: boolean;
}): Promise<CollectionModel<Resource>> {
  return fetchClient.get(
    stringifyUrl(basePath, {
      available: true,
      floorId,
      resourceType,
      showDeleted,
      view: "basic",
    })
  );
}

export function useFloorResources({
  floorId,
  userId,
  extendedView,
}: {
  floorId?: string;
  userId?: string;
  extendedView?: boolean;
}) {
  const { data, error } = useSwr<CollectionModel<Resource>>(
    floorId
      ? stringifyUrl(basePath, {
          available: true,
          floorId,
          reserveForId: userId,
          view: extendedView ? "extended" : "basic",
        })
      : null,
    apiFetch
  );

  const resources = data?._embedded?.resources;
  const extractedLinks = data?._links as BaseCollecionLinks;

  return {
    data: resources,
    links: extractedLinks,
    isLoading: !data && !error,
    error,
  };
}

export function useResourcesCount() {
  return useSwr<number>(`${basePath}/count`);
}

export function useResourceTypes() {
  const { data, error } = useSWR<CollectionModel<RefResourceType>>(
    `${basePath}/types`
  );
  const resourceTypes = data?._embedded?.resourceTypes;
  const extractedLinks = data?._links as BaseCollecionLinks;
  return { data: resourceTypes, links: extractedLinks, error };
}

export const fetchResourceById = (
  id: string
): Promise<EntityModel<Resource>> => {
  return fetchClient.get(`${basePath}/${id}`);
};

export const createResource = (
  data: CreateResource,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> =>
  fetchClient
    .post(basePath, {
      body: JSON.stringify(data),
    })
    .then(() => reloadResources(mutate))
    .then(() => reloadOffices(mutate));

export function useResourceById(resourceId?: string) {
  return useSwr<EntityModel<Resource>>(
    resourceId ? `${basePath}/${resourceId}` : null
  );
}

export const updateResource = (
  url: string,
  data: UpdateResource | CreateResource,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
): Promise<any[]> => {
  return fetchClient
    .patch(url, {
      body: JSON.stringify(data),
    })
    .then(() => reloadResources(mutate))
    .then(() => reloadOffices(mutate));
};

export const deleteResource = (
  url: string,
  mutate: (matcher: RegExp, ...args: any[]) => Promise<any[]>
) =>
  fetchClient
    .delete(url)
    .then(() => reloadResources(mutate))
    .then(() => reloadOffices(mutate));

export const updateResourcePolicy = (
  url: string,
  data: Partial<ReservationPolicy>
): Promise<any[]> => {
  return fetchClient.patch(url, {
    body: JSON.stringify(data),
  });
};
