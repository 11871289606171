import AuthProvider from "app/models/AuthProvider";

const LOGIN_OPT = "login";

export const getStoredLoginOption = (
  providers: AuthProvider[]
): AuthProvider | undefined => {
  const login = JSON.parse(localStorage.getItem(LOGIN_OPT) || "null");

  if (providers.every((provider) => provider.id !== login)) {
    localStorage.removeItem(LOGIN_OPT);
  }

  return providers.find((provider) => provider.id === login);
};

export const setStoredLoginOption = (selectedProvider: AuthProvider) =>
  localStorage.setItem(LOGIN_OPT, JSON.stringify(selectedProvider.id));
