import { HalLink } from "hateoas-hal-types";
import React from "react";
import useCurrentUser from "./api/UserApi";

export enum Logical {
  ANY,
  ALL,
}

export const useRequireAccess = (
  path: string | string[],
  logical = Logical.ANY
) => {
  const { data: user } = useCurrentUser();

  const links = user?._links as any;

  const pages: HalLink[] = Array.isArray(links?.pages)
    ? links?.pages
    : links?.pages
    ? [links?.pages]
    : [];

  const pathArray = Array.isArray(path) ? (path as string[]) : [path];

  const permitted =
    logical === Logical.ANY
      ? pathArray.find((pathEl) =>
          pages?.find((link) => pathEl.startsWith(link.href))
        )
      : pathArray.every((pathEl) =>
          pages?.find((link) => pathEl.startsWith(link.href))
        );

  return permitted;
};

const RequiresAccess: React.FC<{
  path: string | string[];
  logical?: Logical;
  children: React.ReactNode;
}> = ({ path, logical = Logical.ANY, children }) => {
  const permitted = useRequireAccess(path, logical);
  return permitted ? <>{children}</> : null;
};

export default RequiresAccess;
