import { useNotifications } from "app/api/NotificationApi";
import Notification from "app/models/Notification";
import NotificationFilter, {
  convertToSearchCriteria,
} from "app/models/NotificationFilter";
import PaneAndContent from "app/PaneAndContent";
import { handleOverlap } from "app/PoweredByProxiad";
import { EntityModel } from "hateoas-hal-types";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import AddNotificationCard from "./AddNotificationCard";
import NotificationFilters from "./NotificationFilters";
import NotificationInfoCard from "./NotificationInfoCard";

const Notifications = () => {
  const methods = useForm<NotificationFilter>({
    mode: "onChange",
  });

  const { watch } = methods;
  const criteria = convertToSearchCriteria(watch());
  const { data: notifications } = useNotifications(criteria);

  useEffect(() => {
    handleOverlap();
  }, [notifications]);

  return (
    <FormProvider {...methods}>
      <PaneAndContent>
        <PaneAndContent.Pane>
          <NotificationFilters />
        </PaneAndContent.Pane>
        <PaneAndContent.Content>
          <Col>
            <Row
              xs={1}
              sm={1}
              md={2}
              lg={3}
              xl={4}
              className="NoOverlapProxiad"
            >
              <AddNotificationCard />

              {notifications &&
                notifications.map(
                  (notification: EntityModel<Notification>): JSX.Element => (
                    <NotificationInfoCard
                      notification={notification}
                      key={notification.id}
                    />
                  )
                )}
            </Row>
          </Col>
        </PaneAndContent.Content>
      </PaneAndContent>
    </FormProvider>
  );
};

export default Notifications;
