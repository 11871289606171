import { IntlShape } from "react-intl";

enum ResourceType {
  DESK = "DESK",
  CONF_ROOM = "CONF_ROOM",
  PARK_PLACE = "PARK_PLACE",
}

export const formatResourceType = (type: ResourceType, intl: IntlShape) => {
  if (type === ResourceType.DESK)
    return intl.formatMessage({
      id: "resourceType.desk",
      defaultMessage: "Desk",
    });
  if (type === ResourceType.CONF_ROOM)
    return intl.formatMessage({
      id: "resourceType.confRoom",
      defaultMessage: "Conference room",
    });
  if (type === ResourceType.PARK_PLACE)
    return intl.formatMessage({
      id: "resourceType.parkPlace",
      defaultMessage: "Parking place",
    });
};

export default ResourceType;
