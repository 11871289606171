import HeaderAndContent from "app/HeaderAndContent";
import { Breadcrumb } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Audits from "./Audits";

const AuditManagement = () => {
  return (
    <HeaderAndContent>
      <HeaderAndContent.Header>
        <Breadcrumb>
          <Breadcrumb.Item>
            <FormattedMessage
              id="breadcrumb.reports"
              defaultMessage="Reports"
            />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            <FormattedMessage id="breadcrumb.audit" defaultMessage="Audit" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </HeaderAndContent.Header>
      <HeaderAndContent.Content>
        <Audits />
      </HeaderAndContent.Content>
    </HeaderAndContent>
  );
};

export default AuditManagement;
