import { ReactComponent as FaTimes } from "app/icons/v2/fa-times.svg";
import XmasThemeProvider from "components/XmasThemeContext";
import React from "react";
import { toast, ToastBar, Toaster } from "react-hot-toast";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";
import useLocale, {
  getCurrentLocale,
  LocaleProvider,
} from "./i18n/LocaleProvider";

export const apiFetchBase = async (url: string, init?: RequestInit) => {
  const headers = new Headers();
  headers.append("Accept", "application/json");
  headers.append("Accept-Language", getCurrentLocale());
  const response = await fetch(url, {
    credentials: "same-origin",
    headers,
    ...init,
  });

  if (!response.ok) {
    const error: any = new Error("An error occurred while fetching the data.");
    error.status = response.status;

    const contentType = response.headers.get("Content-Type");
    if (contentType != null && contentType.indexOf("json") > -1) {
      error.info = await response.json();
    }

    throw error;
  }

  return response;
};

export const apiFetch = async (url: string, init?: RequestInit) => {
  return (await apiFetchBase(url, init)).json();
};

export const apiFetchWithReviver = async (url: string, init?: RequestInit) => {
  return JSON.parse(
    await (await apiFetchBase(url, init)).text(),
    (key, value) => {
      // If the value is a string and if it roughly looks like it could be a
      // JSON-style date string go ahead and try to parse it as a Date object.
      if (
        "string" === typeof value &&
        /^\d{4}-[01]\d-[0-3]\dT[012]\d(?::[0-6]\d){2}$/.test(value)
      ) {
        var date = new Date(value + "Z");
        // If the date is valid then go ahead and return the date object.
        // eslint-disable-next-line no-self-compare
        if (+date === +date) {
          return date;
        }
      }
      // If a date was not returned, return the value that was passed in.
      return value;
    }
  );
};

const LocalizedConfig: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { locale, messages } = useLocale();

  return (
    <IntlProvider {...{ defaultLocale: "en-GB", locale, messages }}>
      <SWRConfig
        value={{
          fetcher: apiFetch,
          // revalidateOnFocus: false,
        }}
      >
        {children}
      </SWRConfig>
    </IntlProvider>
  );
};

const AppConfig: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <LocaleProvider>
      <LocalizedConfig>
        <Toaster
          containerClassName="Toast-Container"
          position="top-right"
          gutter={8}
          toastOptions={{
            duration: 5000,
            success: {
              duration: 3000,
            },
            error: {
              duration: 5000,
            },
            custom: {
              duration: 5000,
            },
          }}
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && t.id !== "notification" && (
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        padding: "0",
                      }}
                      onClick={() => toast.dismiss(t.id)}
                    >
                      <FaTimes style={{ width: "1.25rem", height: "auto" }} />
                    </button>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
        <XmasThemeProvider>{children}</XmasThemeProvider>
      </LocalizedConfig>
    </LocaleProvider>
  );
};

export default AppConfig;
